import React from 'react';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import axios from 'axios';

import Card from '../ReusableComponents/Card/Card';

// import arrowForward from '../../../../images/arrow-forward.png';
// import locationIcon from '../../../../images/icon-location.svg';

class BoxPropertyAssociate extends React.Component {
  state = {};

  addNewBoxToProperty = (property) => {
    const token = Cookies.get('boxActivationToken');
    const url = 'https://components.homeit.io/box-configuration/activation/process';
    axios({
      url,
      method: 'POST',
      data: { token, property },
    })
      .then(() => {
        Cookies.remove('boxActivationToken');
        window.location = '/';
      })
      .catch((err) => {
        this.props.history.push({
          pathname: '/box/new/activation/error',
          state: {
            redirectUrl: '/box/new/new-property',
            errorMessage: String(err),
          },
        });
      });
  };

  render() {
    const { t } = this.props;
    const { properties } = this.props;
    return (
      <div className="container container--padding" style={{ maxWidth: '677px', marginTop: '50px' }}>
        <h1 className="center title mb-3">{t('box.new.associatePropertyTitle')}</h1>
        <div className="container">
          <div className="row">
            <div className="twelve-columns">
              <div className="properties-wrapper wrapper--cards-list">
                {properties.length >= 1
                  ? (
                    <>
                      {properties.map((property) => (
                        <div className="mb-4">

                          <Card key={property.id} className="property wrapper--card-container">
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                              <div className="wrapper--flexible" style={{ padding: '10px', width: '85%' }}>
                                <div className="text--guest-name text--property-name" style={{ fontWeight: '700' }}>{property.name}</div>
                                <div className="text--guest-tel">
                                  {property.address
                                    ? (
                                      <>
                                        { property.address.street || property.address.streetAddress }
                                        {', '}
                                        { property.address.city ? property.address.city : property.address.area }
                                        {', '}
                                        { property.address.country }
                                      </>
                                    )

                                    : null}
                                </div>
                              </div>
                              <div className="wrapper--buttons" style={{ marginRight: '5%' }}>
                                <div className="wrapper--btn-two">
                                  <div
                                    className="btn btn-secondary"
                                    onClick={() => this.addNewBoxToProperty(property.id)}
                                    style={{
                                      marginTop: '16px', fontSize: '0.8rem', lineHeight: '32px', width: '120px',
                                    }}
                                  >
                                    Choose
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    </>
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties.properties,
  user: state.user.user,
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps,
  mapDispatchToProps)(BoxPropertyAssociate));
