import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import styles from '../Main.module.scss';

const times = (new Array(24).fill(0)).map((val, i) => {
  const label = moment().hour(i).minute(0).format('HH:mm');
  const value = (i * 60);
  return <option value={value}>{label}</option>;
});

export const HourInputs = (props) => {
  const {
    checkInHourChangeHandler,
    checkOutHourChangeHandler,
    checkInHour,
    checkOutHour,
  } = props;
  return (
    <>
      <div className="col-6">
        <label className="mb-2 mt-3" htmlFor="checkin-hour">
          {/* TODO: this needs translation */}
          Hora de Check-in
          {' '}
          <span className={styles.required}>*</span>
        </label>
        <select
          id="checkin-hour"
          aria-label="Default Check-in Time"
          className="form-control"
          value={checkInHour}
          onChange={(e) => checkInHourChangeHandler(e.target.value)}
        >
          {times}
        </select>
      </div>
      <div className="col-6">
        <label className="mb-2 mt-3" htmlFor="checkout-hour">
          {/* TODO: this needs translation */}
          Hora de Check-out
          {' '}
          <span className={styles.required}>*</span>
        </label>
        <select
          id="checkout-hour"
          aria-label="Default Check-out Time"
          className="form-control"
          value={checkOutHour}
          onChange={(e) => checkOutHourChangeHandler(e.target.value)}
        >
          {times}
        </select>
      </div>
    </>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HourInputs)),
);
