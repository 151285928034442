import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <div>
      <div id="loading_access" className="w3-modal">
        <div className="w3-modal-content w3-card-4 w3-animate-zoom" style={{ maxWidth: '600px' }}>
          <div className="w3-center">
            <br />
            <div id="loader" className="loader" />
            <h5>{t('boxActivation.start.connectionWait')}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
