import React from 'react';
import { withTranslation } from 'react-i18next';

import style from './NavbarDesktop.module.scss';

import { ReactComponent as EmailHelp } from '../../assets/images/filled/mail.svg';

export const HelpResponsive = ({ t }) => (
  <div className={style.helpMenu}>
    <div className={`${style.helpContainer}`}>
      <div className="row">
        <div className="col">
          <h1 className={style.helpTitle}>{t('help.help')}</h1>
          <h6 className={style.helpSubtitle}>{t('help.anyQuestions')}</h6>
        </div>
      </div>
      <div className={style.card}>
        <div className="row">
          <EmailHelp style={{ height: '30px' }} className="col" alt="email help" />
          <div className="col-9" style={{ paddingLeft: '0px' }}>
            <p style={{
              color: 'black', margin: '2px', fontSize: '16px', fontWeight: '600', paddingBottom: '4px',
            }}
            >
              support@homeit.io
            </p>
            <p style={{ color: '#9DA1A9', fontSize: '13px' }}>{t('help.workingHours')}</p>
          </div>
        </div>
        {/* <div className="row">
          <EmailHelp style={{ height: '30px' }} className="col" alt="support help" />
          <div className="col-9" style={{ paddingLeft: '0px' }}>
            <a href='https://homeit.freshdesk.com/en/support/home'>Support Portal</a>
          </div>
        </div>
        <div className="row">
          <EmailHelp style={{ height: '30px' }} className="col" alt="status help" />
          <div className="col-9" style={{ paddingLeft: '0px' }}>
            <a href='https://statuspage.freshping.io/2204-HomeitSystemStatus'>Service Status</a>
          </div>
        </div> */}
        {/* <div className="row" style={{ marginTop: '16px', marginBottom: '56px' }}>
                <PhoneHelp style={{ height: '30px' }}  className="col" alt="phone help" />
                <div className="col-9" style={{ fontWeight: '600', fontSize: '24px', paddingLeft: '0px' }}>
                    <p className="black" style={{ lineHeight: '35px', fontSize: '16px' }}>+351 234 567 891</p>
                </div>
            </div> */}
      </div>
      {/* <h5 className={`${style.bold}`} style={{ fontSize: '21px', marginTop: '24px' }}>{t('help.checkKnowledgeBase')}</h5>
      <h6 style={{ color: '#9DA1A9', lineHeight: '18px', fontSize: '13px' }}>{t('help.faqPage')}</h6> */}
    </div>
    {/* <div className="row">
      <div style={{ width: '180px', margin: 'auto' }}>
        <button
          className="btn btn-primary btn-light"
        >
          {t('help.goToHomeitSupport')}
        </button>
      </div>
    </div> */}
  </div>
);

export default withTranslation()(HelpResponsive);
