import React from 'react';

import style from './NoItemsMessage.module.scss';

const NoItemsMessage = (props) => {
  const {
    heading, subheading, children, height,
  } = props;
  return (
    <div className={style.MessageFullPageContainer} style={{ height }}>
      <div className={style.MessageAbsoluteContainer}>
        <div style={{
          width: '78px', margin: 'auto', position: 'relative', marginBottom: '20px',
        }}
        >
          {children}
        </div>
        <div className="text-center text-grey-dark">{heading}</div>
        <div className="text-center text-grey-dark">{subheading}</div>
      </div>
    </div>
  );
};

export default NoItemsMessage;
