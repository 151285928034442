import styled from 'styled-components';

import { colors } from '../../variables';
import { CardContainer } from '../ReusableComponents/Card/styles';

export const HostCard = styled(CardContainer)`
    padding: 12px 8px 12px 16px;
    margin-bottom: 8px; 


    @media only screen and (min-width: 900px) {
        margin-bottom: 14px; 
    }
`;

export const KeysHeader = styled.div`
    display: grid;
    margin-bottom: 32px;
    grid-template-columns: auto 40px;

    p {
        font-size: 15px;
        color: ${colors.$white};
    }

     .btnCreate {
        background-color: ${colors.$secondary};
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        float: right;
        max-width: 40px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 7px 8px -2px rgba(255,0,95,0.38);
        cursor: pointer;
        
        svg {
            width: 24px;
            height: 24px;
        }
    }
`;

export const ModalContainer = styled.div`
    margin: auto;
    width: 250px;
    padding: 20px 0px;
    minHeight: 290px;
    position: relative;

    > div:last-child{
        margin: auto;
        width: 120px;

        > div {
            line-height: 32px;
            width: 120px;
        }
    }
`;

export const CardList = styled.ul`
    margin-left: 20px;
`;

export const CardInfoContainer = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;

    .host-header {
        padding: 0;
        text-decoration: none;
        color:  ${colors.$primaryDark};

        @media only screen and (min-width: 900px) { 
            display: flex;
            max-width: 90%;
            width: 100%;        
            justify-content: space-between;
            align-items: center;
        }

        h5 {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 4px;
        }

        .acesses {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            margin: 0;
        }

        .info {
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
            margin-top: 18px;
            color: ${colors.$info};
            display: flex;
            align-items: center;

            @media only screen and (min-width: 900px) { 
                margin-top: 0;
            }

            &.warning {
                color: ${colors.$warning};
            }

            svg {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                path {
                    fill: ${colors.$info};
                }

                &.warning path {
                    fill: ${colors.$warning};
                }
            }
        }
    }
    .host-body {
        padding: 0;    
        display: flex;
        align-items: center;

        .edit-host {
            width: 34px;
            height: 34px;
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                width: 28px;
                height: 28px;
                path {
                    fill: ${colors.$secondary};
                }
            }
        }

        .resend {
            width: 34px;
            height: 34px;
            cursor: pointer;
            margin-left: 10px;
            display: flex;
            border-radius: 50%;
            float: right;
            max-width: 40px;
            justify-content: center;
            align-items: center;

            path {
                fill: ${colors.$secondary};
            }
        }
    }
`;

export const HostSettingsContainer = styled.div`
    margin-top: 16px;
`;

export const HostSettingsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 48px;

    @media only screen and (min-width: 900px) {
        margin-bottom: 32px;
    }

    h1 {
        margin: 0;
        font-size: 21px;
        letter-spacing: 0;
        line-height: 26px;
        @media only screen and (min-width: 900px) {
            font-size: 28px;
            line-height: 35px;
        }
    }
    > button {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;

            path {
                fill: ${colors.$secondary};
            }
        }
        span {
            color: ${colors.$secondary};
            font-size: 13px;
            letter-spacing: 0;
            line-height: 16px;

            @media only screen and (min-width: 900px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
`;

export const CardSettings = styled.form`
    background: ${colors.$white};
    margin-bottom: 24px;
    padding: 24px;
    min-height: 101px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    @media only screen and (min-width: 900px) {
        padding: 32px;
        margin-bottom: 40px;
    }
`;

export const Required = styled.span`
    color: ${colors.$secondary};
    margin-left: 4px;
`;

export const FieldGroup = styled.div`
    margin-bottom: 24px;
`;

export const PropertiesTitle = styled.h2`
    margin-top: 24px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 24px;

    @media only screen and (min-width: 900px) {
        margin-top: 32px;
        margin-bottom: 32px;
    }
`;
export const PropertiesSection = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        p {
            margin: 0 0 0 8px;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
        }
    }
`;

export const ButtonSection = styled.div`
   display: flex; 
   justify-content: center;
   align-items: center;
   margin-top: 16px;

    button {
        min-width: 139px;
        &:last-child {
            margin-left: 17px;
        }

        @media only screen and (min-width: 900px) {
            min-width: 211px;
        }

    }
`;
