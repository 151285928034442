import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconIntercomSuccess } from '../../../assets/images/box-activation/icon_intercom-success.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="activation-section" id="intercom-success">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <div className="wrapper-icon"><IconIntercomSuccess alt="intercom well connected" style={{ width: '144px' }} /></div>
            <h1>{t('boxActivation.intercom.successTitle')}</h1>
            <h4>{t('boxActivation.intercom.successSubtitle')}</h4>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
            <div className="col col-md-3 col-lg-2 text-center">
              <Link to='/box/activation/lock/choose' className="btn btn-block btn-outline-primary">{t('boxActivation.buttonNext')}</Link>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
