import { createSlice } from '@reduxjs/toolkit';
import type { Dispatch } from 'react';

import API from '../utils/api';

const initialState = {
  pending: false,
  success: false,
  activities: [],
  skipValue: 0,
  total: 0,
  search: '',
};

const addTimestampToActivity = (activity: any) => {
  if (!activity.timestamp) activity.timestamp = activity.dateCreated;
  return activity;
};

const activities = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    // GET: (state, action) => { state.activities.push(...action.payload); },
    skipValue: (state, action) => { state.skipValue = action.payload; },
    total: (state, action) => { state.total = action.payload; },
    GET: (state, action) => {
      const newActivites = action.payload
        .map((activity: any) => addTimestampToActivity(activity));
      let allActivities = [...state.activities];
      const allActivitiesIds = allActivities.map((activity: any) => activity.id);
      allActivities = allActivities
        .concat(newActivites.filter((activity: any) => !allActivitiesIds.includes(activity.id)))
        .sort((a: any, b: any) => b.timestamp - a.timestamp);
      state.activities = [...allActivities];
    },
    filter: (state, action) => { state.search = action.payload; },
  },
});

const getAllActivities = (dispatch: Dispatch<any>, skipValue = 0) => API
  .get('keyevents', {
    limit: 30,
    skip: skipValue,
    order: 'desc',
    sortBy: 'dateCreated',
  })
  .then(({
    docs,
    skip: s,
    limit: l,
    total,
  }) => {
    dispatch(activities.actions.GET(docs));
    dispatch(activities.actions.total(total));
    dispatch(activities.actions.skipValue(s + l));
    return docs;
  });

export const getActivities = (skipValue = 0) => (dispatch: Dispatch<any>) => {
  dispatch(activities.actions.apiPending(true));
  return getAllActivities(dispatch, skipValue)
    .then(() => {
      dispatch(activities.actions.apiPending(false));
      dispatch(activities.actions.apiSuccess(true));
    });
};

export const filterActivities = (filter: any) => (dispatch: Dispatch<any>) => {
  dispatch(activities.actions.filter(filter));
};

export const filterActivitiesbyFilters = (filter: any,
  skipValue = 0) => (dispatch: Dispatch<any>) => API
  .get('keyevents', {
    limit: 30,
    skip: skipValue,
    order: 'desc',
    sortBy: 'dateCreated',
    door: filter.doors,
    key: filter.keys,
    createdBefore: new Date(filter.createdBefore),
    createdAfter: new Date(filter.createdAfter),
  })
  .then(({
    docs,
    skip,
    limit,
    total,
  }) => {
    dispatch(activities.actions.GET(docs));
    dispatch(activities.actions.total(total));
    dispatch(activities.actions.skipValue(skip + limit));
    return docs;
  });

export const getActivitiesByProperty = (state: any, id: any) => {
  const doorIds = state.doors.doors
    .filter((door: any) => door.property === id)
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((door: any) => door.id);
  return state.activities.activities
    .filter((activity: any) => doorIds.includes(activity.door));
};

export { activities };
