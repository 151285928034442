import React from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import LockedImage from '../../assets/images/box-activation/Locked.png';
import KeypadImage from '../../assets/images/box-activation/keypad.png';
import ArrowForwardImage from '../../assets/images/box-activation/arrow-forward.png';

import { Keypad, Lock, Door } from './box-activation/box-api';
import { getBox, getConnection } from './box-activation/boxConnection';
import WaitingModal from './WaitingModal';

const popupModal = withReactContent(Swal);

class ReplaceDevices extends React.Component {
  state = {
    extBox: [],
    doors: [],
    keypads: [],
    locks: [],
    doorsData: [],
    isWaitButtonDisplayed: false,
  };

  componentDidMount() {
    this.setState({ isWaitButtonDisplayed: true });
    const token = window.localStorage.getItem('redirectToken');
    axios.post('https://components.homeit.io/box-configuration/redirect/process', {
      token,
    })
      .then((res) => this.setState({ extBox: res.data.docs[0] }))
      .then(() => this.getDoorInfo());
  }

  getDoorInfo = () => {
    getBox()
      .then((box) => {
        const Doors = box.door.info().then((doors) => this.setState({ doors }));
        const Locks = box.lock.info()
          .then((locks) => {
            const locksArr = [];
            locks.forEach((lock) => {
              for (let i = 0; i < lock.int_id.length; i += 1) {
                locksArr.push(new Lock(getConnection().ws(), lock.int_id[i], lock.mac));
              }
            });
            this.setState({ locks: locksArr });
          });
        const Keypads = box.keypad.info()
          .then((keypads) => {
            const keypadsArr = [];
            keypads.forEach((keypad) => {
              for (let i = 0; i < keypad.int_id.length; i += 1) {
                keypadsArr.push(new Keypad(getConnection().ws(), keypad.int_id[i], keypad.mac));
              }
            });
            this.setState({ keypads: keypadsArr });
          });

        Promise.all([Doors, Locks, Keypads]).then(() => this.organiseObjs());
      });
  };

  // eslint-disable-next-line complexity
  organiseObjs = () => {
    const {
      locks, doors, keypads, extBox,
    } = this.state;
    const dataArr = [];
    let lock;
    let keypad;
    let door;
    let extDoor;

    // Run a for loop through all of the doors received from the box
    for (let i = 0; i < doors.length; i += 1) {
      // Run a inner for loop through all of the locks received from the box,
      // then compare to the door to see if they belong to the current door in the loop
      for (let j = 0; j < locks.length; j += 1) {
        if (locks[j].doorId && doors[i].int_id === locks[j].doorId) {
          lock = locks[j];
        }
      }

      // Run a inner for loop through all of the keypads received from the box,
      // then compare to the door to see if they belong to the current door in the loop
      for (let k = 0; k < keypads.length; k += 1) {
        if (keypads[k].doorId && doors[i].int_id === keypads[k].doorId) {
          keypad = keypads[k];
        }
      }

      // Run a inner for loop through all of the doors received from the service,
      // then compare to the door to see if they belong to the current door in the loop
      for (let l = 0; l < extBox.doors.length; l += 1) {
        if (extBox.doors[l].id && doors[i].ext_id === extBox.doors[l].id) {
          extDoor = extBox.doors[l];
        }
      }

      door = doors[i];
      if (keypad || lock) {
        dataArr.push({
          door, keypad, lock, extDoor,
        });
      }
      keypad = '';
      door = '';
      lock = '';
    }
    this.setState({ doorsData: dataArr });
    this.setState({ isWaitButtonDisplayed: false });
  };

  render() {
    const { t } = this.props;
    const { doorsData } = this.state;
    return (
      <>
        <div style={{ backgroundColor: '#32beaa', width: '100%', height: '100px' }}>
          <div className="row wrapper--header" style={{ margin: 'auto', maxWidth: '600px', lineHeight: '100px' }}>
            <div className="wrapper--icon" />
            <div className="wrapper--title">
              <h1 className="title replace-devices__title">{t('deviceConfiguration')}</h1>
            </div>
          </div>
        </div>
        <div className="container container--padding" style={{ maxWidth: '600px', marginTop: '50px' }}>
          <div className="container">
            <div className="row">
              <div className="twelve-columns">
                <div className="properties-wrapper wrapper--cards-list">
                  {doorsData.length >= 1
                    ? (
                      <>
                        {doorsData.map((door) => (
                          <div key={door.door.ext_id} className="row property wrapper--card-container">
                            <div className="wrapper--first-row" style={{ width: '100%' }}>
                              <div className="wrapper--flexible border-top" style={{ margin: '10px', width: '100%', paddingRight: '0px' }}>
                                <div
                                  className="text--guest-tel"
                                  style={{
                                    fontWeight: '700', fontSize: '18px', color: '#000', marginTop: '0.8em',
                                  }}
                                >
                                  {door.extDoor.name}
                                </div>

                                {/* LOCK CARD */}
                                {door.lock
                              && (
                              <div className="replace-devices__grid">
                                <img src={LockedImage} style={{ filter: 'brightness(0)', height: '100%', padding: '20% 0%' }} alt='' />
                                <div style={{ marginLeft: '1em', lineHeight: '40px' }}>Lock</div>
                                <div
                                  className="wrapper--btn-two"
                                  onClick={() => {
                                    popupModal.fire({
                                      html:
                                      `${t('deviceConfigurationMessage')
                                      }<br /> ${
                                        t('deviceConfigurationConfirm')}`,
                                      confirmButtonText: t('yes'),
                                      cancelButtonText: t('no'),
                                      confirmButtonColor: '#32beaa',
                                      showCancelButton: true,
                                    })
                                      .then((result) => {
                                        if (result.value) {
                                          window.localStorage.setItem('replaceDevice', JSON.stringify({ doorName: door.extDoor.name }));
                                          const replaceDevice = JSON.parse(window.localStorage.getItem('replaceDevice'));
                                          this.setState({ isWaitButtonDisplayed: true });
                                          const doorObj = new Door(getConnection().ws(), door.door.int_id, door.door.ext_id);
                                          door.lock.delete()
                                            .then(() => doorObj.save(replaceDevice.doorName))
                                            .then(() => {
                                              this.setState({ isWaitButtonDisplayed: false });
                                              window.localStorage.setItem('currentDoor', door.door.int_id);
                                              this.props.history.push({ pathname: '/box/activation/lock/nuki/pairing' });
                                            });
                                        }
                                      });
                                  }}
                                >
                                  <a className="link--edit-property">
                                    <img src={ArrowForwardImage} className="btn--edit btn-choose" />
                                  </a>
                                  <div
                                    style={{ marginTop: '.5em', fontSize: '0.8rem', textAlign: 'center' }}
                                  >
                                    {t('replace')}
                                  </div>
                                </div>
                              </div>
                              )}

                                {/* KEYPAD CARD */}
                                {door.keypad
                              && (
                              <div className="replace-devices__grid">
                                <img src={KeypadImage} style={{ filter: 'brightness(0)', height: '100%', padding: '20% 0%' }} />
                                <div style={{ marginLeft: '1em', lineHeight: '40px' }}>{t('keypad')}</div>
                                <div
                                  className="wrapper--btn-two"
                                  onClick={() => {
                                    popupModal.fire({
                                      html:
                                        `${t('deviceConfigurationMessage')
                                        }<br /> ${
                                          t('deviceConfigurationConfirm')}`,
                                      confirmButtonText: t('yes'),
                                      cancelButtonText: t('no'),
                                      confirmButtonColor: '#32beaa',
                                      showCancelButton: true,
                                    })
                                      .then((result) => {
                                        if (result.value) {
                                          window.localStorage.setItem('replaceDevice', JSON.stringify({ doorName: door.extDoor.name }));
                                          const replaceDevice = JSON.parse(window.localStorage.getItem('replaceDevice'));
                                          this.setState({ isWaitButtonDisplayed: true });
                                          const doorObj = new Door(getConnection().ws(), door.door.int_id, door.door.ext_id);
                                          door.keypad.delete()
                                            .then(() => doorObj.save(replaceDevice.doorName))
                                            .then(() => {
                                              this.setState({ isWaitButtonDisplayed: false });
                                              window.localStorage.setItem('currentDoor', door.door.int_id);
                                              this.props.history.push({ pathname: '/box/activation/keypad' });
                                            });
                                        }
                                      });
                                  }}
                                >
                                  <a className="link--edit-property">
                                    <img src={ArrowForwardImage} className="btn--edit btn-choose" />
                                  </a>
                                  <div
                                    style={{ marginTop: '.5em', fontSize: '0.8rem', textAlign: 'center' }}
                                  >
                                    {t('replace')}
                                  </div>
                                </div>
                              </div>
                              )}

                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isWaitButtonDisplayed && <WaitingModal />}
        <p style={{ height: '100px' }} />
      </>
    );
  }
}

export default withTranslation()(ReplaceDevices);
