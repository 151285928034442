import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import Key from '../../Keys/Key/Key';
import Activity from '../../Activities/Activity';
import Door from './Door/Door';
import PropertyMiniDesktop from '../PropertyMini/PropertyMiniDesktop';
import PropertySynchronisations from './PropertySynchronisations';
import { getDoorsByProperty } from '../../../modules/doors';
import { getKeysByProperty } from '../../../modules/keys';
import { getActivitiesByProperty } from '../../../modules/activities';
import { getBoxById } from '../../../modules/boxes';
import { getKeypadById } from '../../../modules/keypads';
import { getLockById } from '../../../modules/locks';
import { getPropertyById } from '../../../modules/properties';

import { ReactComponent as KeysIcon } from '../../../assets/images/filled/keys.svg';
import { ReactComponent as LowBatteryIcon } from '../../../assets/images/solid/battery-low.svg';
import { ReactComponent as BoxOfflineIcon } from '../../../assets/images/solid/offenergy.svg';
import { ReactComponent as ActivityLogIcon } from '../../../assets/images/filled/activitylog.svg';

import styles from './Property.module.scss';

export class Property extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id, 10),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  invalidId() {
    const { history } = this.props;
    return history.push('/property');
  }

  renderKeys = () => {
    const {
      getKeys,
      getDoors,
      t,
    } = this.props;

    return (
      <div className={`${styles.desktopCardContainer} col-xs-12 col-sm-12 col-lg-6`}>
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            <h6>{t('activeKeys')}</h6>
            {getKeys().length > 0
              ? (
                <Link
                  id="key-create-button"
                  style={{ color: '#FF005F' }}
                  to={{
                    pathname: '/keys',
                    // state: { property: [id] },
                    state: { doors: getDoors().map((door) => door.id) },
                  }}
                >
                  {t('more')}
                </Link>
              )
              : null}
          </div>
          {getKeys(['current']).length > 0
            ? getKeys(['current']).map((key, index) => {
              if (index < 3) {
                if (window.innerWidth >= '900') {
                  return (
                    <Key
                      key={key.id}
                      {...key}
                      isFullKeyDisplayed
                      propertyView
                    />
                  );
                }
                return (
                  <Key
                    key={key.id}
                    {...key}
                    isFullKeyDisplayed
                  />
                );
              }
              return undefined;
            })
            : (
              <div className="mt-4 row" id="no-keys-created">
                <KeysIcon className={`${styles.icon} col-2`} style={{ padding: '0px' }} />
                <p className="col-9" style={{ color: '#9DA1A9' }}>{t('property.keyCreated')}</p>
              </div>
            )}
        </div>
      </div>
    );
  };

  renderActivities = () => {
    const {
      getActivities,
      getDoors,
      canGetAllActivities,
      t,
    } = this.props;

    let activities = getActivities();

    if (!canGetAllActivities()) {
      activities = activities.filter((activity) => moment().diff(activity.dateCreated, 'hours') <= 48);
      // if (activities.length === 0) {
      //   return (
      //     <NoItemsMessage
      //       heading="Permission Activities"
      //       subheading="No activities in the last 48h click below to update"
      //       id="no-activities"
      //     >
      //       <ActivitiesIcon style={{ width: '78px', height: '78px' }} />
      //     </NoItemsMessage>
      //   );
      // }
    }

    return (
      <div className={`${styles.desktopCardContainer} col-xs-12 col-sm-12 col-lg-6`}>
        <div className={`${styles.activityItem} col-md-12`}>
          <div className="d-flex justify-content-between">
            <h6>{t('activities')}</h6>
            { activities.length > 0
              ? (
                <Link
                  id="activities-link"
                  style={{ color: '#FF005F' }}
                  to={{
                    pathname: '/activities',
                    // state: { property: [id] },
                    state: {
                      doors: getDoors().map((door) => door.id),
                      propertyId: this.props.getProperty(this.state.id).id,
                    },
                  }}
                >
                  {t('more')}
                </Link>
              )
              : null}
          </div>
          {activities.length > 0
            ? activities.map((activity, index) => {
              if (window.innerWidth >= '900') {
                if (index < 6) {
                  return (
                    <Activity
                      key={activity.id}
                      activityKey={activity.key}
                      column
                      {...activity}
                    />
                  );
                }
              } else if (index < 1) {
                return (
                  <Activity
                    key={activity.id}
                    activityKey={activity.key}
                    column
                    {...activity}
                  />
                );
              }
              return undefined;
            })
            : (
              <div className="mt-4 row">
                <ActivityLogIcon className={`${styles.icon} col-2`} style={{ padding: '0px' }} />
                <p className="col-9" style={{ color: '#9DA1A9' }}>{t('property.activityList')}</p>
              </div>
            )}
        </div>
      </div>
    );
  };

  render() {
    const { id } = this.state;
    const {
      getKeys,
      // getActivities,
      getDoors,
      getBox,
      getLock,
      getKeypad,
      getProperty,
      t,
    } = this.props;

    return (
      <>
        {getProperty(id) ? (
          <div className="container">
            <h1 className={`${styles.titlePaddingTop}`}>{t('properties')}</h1>
            <div id="listContainer" className={styles.card}>
              {/* This component is being used to render the top part of the Property object */}
              <PropertyMiniDesktop {...this.state} />
              <div className={styles.doorContainer}>
                <h6>{t('property.doors')}</h6>
                <div className="row">
                  {getDoors().length > 0
                    ? getDoors().map((door) => (
                      <div className="col-md-4" key={door.id}>
                        <Door
                          key={door.id}
                          content={door.name && door.name.length > 20 ? `${door.name.slice(0, 20)}...` : door.name}
                          // isSwipeDisabled={!(door.box && (getBox(door.box) || {}).isOnline)}
                          {...door}
                        />
                        {/* <div className="col" id="box-offline-message">
                          <BoxOfflineIcon className={styles.boxOfflineIcon} />
                          <span className={styles.lowBatteryText}>{t('property.boxOffline')}</span>
                          <a data-tip data-for="react-tooltip">  <u>?</u></a>
                          <ReactTooltip id='react-tooltip' clickable border place="top" type="light" effect="solid" delayHide={100}>
                            <span>For more information, please visit our </span>
                            <a href='https://homeit.freshdesk.com/en/support/solutions/articles/42000016610-my-homeit-box-is-offline' target='_blank'>Support Portal</a>
                          </ReactTooltip>
                        </div> */}
                        {door.box && !(getBox(door.box) || {}).isOnline && (
                          <div className="col" id="box-offline-message">
                            <BoxOfflineIcon className={styles.boxOfflineIcon} />
                            <span className={styles.lowBatteryText}>{t('property.boxOffline')}</span>
                          </div>
                        )}
                        {(door.locks || []).map((lockId: any) => {
                          const lock = getLock(lockId) || {};
                          if (lock.type === 'ble' && lock.lowBattery === true) {
                            return (
                            <div key={lockId} className="col" id="low-battery-lock-message">
                              <LowBatteryIcon className={styles.lowBatteryIcon} />
                              <span className={styles.lowBatteryText}>{t('property.lowBattery')}</span>
                            </div>
                            );
                          }
                          if (lock.type === 'ble' && lock.isConnected === false) {
                            return (
                            <div key={lockId} className="col" id="low-battery-lock-message">
                              <LowBatteryIcon className={styles.lowBatteryIcon} />
                              <span className={styles.lowBatteryText}>{t('property.lockOffline')}</span>
                            </div>
                            );
                          }
                          return undefined;
                        })}
                        {(door.keypads || []).map((keypadId: any) => {
                          const keypad = getKeypad(keypadId) || {};
                          if (keypad.type === 'ble' && keypad.lowBattery === true) {
                            return (
                              <div key={keypadId} className="col" id="low-battery-keypad-message">
                                <LowBatteryIcon className={styles.lowBatteryIcon} />
                                <span className={styles.lowBatteryText}>{t('property.lowBattery')}</span>
                              </div>
                            );
                          }
                          if (keypad.type === 'ble' && keypad.isConnected === false) {
                            return (
                              <div key={keypadId} className="col" id="low-battery-keypad-message">
                                <LowBatteryIcon className={styles.lowBatteryIcon} />
                                <span className={styles.lowBatteryText}>{t('property.keypadOffline')}</span>
                              </div>
                            );
                          }
                          return undefined;
                        })}
                      </div>
                    ))
                    // TODO: replace "No Doors" with something available from the dictionary
                    : <div style={{ paddingLeft: '16px' }}>No Doors</div>}
                </div>
              </div>

              <div className={styles.detailsContainer}>
                {/* KEYS SECTION */}
                <this.renderKeys />
                {/* ACTIVITIES SECTION */}
                <this.renderActivities />
              </div>

              <PropertySynchronisations
                propId={id}
                keys={getKeys()}
              />
            </div>
          </div>
        ) : (
          this.invalidId()
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  getDoors: () => getDoorsByProperty(state, parseInt(props.match.params.id, 10)),
  getKeys: (durationTypeArray) => getKeysByProperty(
    state,
    parseInt(props.match.params.id, 10),
    durationTypeArray,
  ),
  getActivities: () => getActivitiesByProperty(state, parseInt(props.match.params.id, 10)),
  getBox: (id) => getBoxById(state, id),
  getKeypad: (id) => getKeypadById(state, id),
  getLock: (id) => getLockById(state, id),
  canGetAllActivities: () => state.billing.customer.services.includes('service-keyevents-unlimited'),
  getProperty: (id) => getPropertyById(state, id),
});

export default withRouter(withTranslation()(connect(mapStateToProps)(Property)));
