import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import Booking from '../../Bookings/Booking';

import { ReactComponent as BookingsIcon } from '../../../assets/images/filled/bookings.svg';

import styles from './Property.module.scss';

class PropertySynchronisations extends React.Component {
  render() {
    const {
      keys,
      t,
    } = this.props;

    const futureKeys = keys
      .filter((key) => key.durationType === 'future' && (key.tags || []).includes('integration'));

    return (
      <div className={`${styles.bookingsCardContainer} mb-5`}>
        <div className="col">
          <div className="d-flex justify-content-between">
            <h6>{t('bookings')}</h6>
            {futureKeys.length ? <a style={{ color: '#FF005F' }}>{t('more')}</a> : null}
          </div>
          {futureKeys.length
            ? (
              <div className="row">
                {/* Display future keys */}
                {futureKeys.map((booking, index) => index < 3
                    && (
                      <div className="col-xs-12 col-md-4" key={booking.id}>
                        <Link
                          to={`/keys?keyId=${booking.id}`}
                          key={booking.id}
                          style={{ textDecoration: 'none', color: '#0A1428' }}
                        >
                          <Booking {...booking} key={booking.id} />
                        </Link>
                      </div>
                    ))}
              </div>
            )
            : (
              <>
                {/* Show the message discussing that the bookings will appear in place of the synchronisations */}
                <div className="mt-4 row">
                  <BookingsIcon className={`${styles.icon} col-2`} />
                  <div className="col-9" style={{ color: '#9DA1A9' }}>
                    <p>{t('booking.noRecords1')}</p>
                    <p>{t('booking.noRecords2')}</p>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  synchronisations: state.synchronisations.listings,
  user: state.user.user,
  userId: state.user.user.id,
});

const mapDispatchToProps = () => ({
});

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PropertySynchronisations)),
);
