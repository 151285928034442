import styled from 'styled-components';
import { colors } from '../../../variables';

// eslint-disable-next-line import/prefer-default-export
export const AllActivitiesDisplayed = styled.h2`
    text-align: center;
    padding-top: 24px;
    margin-bottom: 0px;
    color: ${colors.$greyLight};
`;
