import React from 'react';

import { ReactComponent as Lock } from '../../../../../assets/images/solid/lock-alt-open-green.svg';
import './DoorUnlockLoader.scss';

const DoorUnlockLoader = () => (
  <div className="loading-wrapper rotate">
    <div className="circle green">
      <Lock />
    </div>
    <div className="gap-patch" />
  </div>
);

export default DoorUnlockLoader;
