import React from 'react';
import { withTranslation } from 'react-i18next';

import Card from '../ReusableComponents/Card/Card';
import Button from '../ReusableComponents/Button';
import error from '../../assets/images/illustration/failure.svg';

const ConnectionError = (props) => {
  const { t, location, history } = props;
  return (
    <div className="activation-section" id="listContainer" style={{ maxWidth: '677px', margin: 'auto' }}>
      <h1 className="mb-3">{t('box.new.activationError')}</h1>
      <Card>
        <div className="wrapper-icon text-center description-txt"><img src={error} alt="connection error" style={{ width: '80px' }} /></div>
        <div className="description-txt">
          <h2 className="text-center">Ups</h2>
          {location.state && location.state.errorMessage
            ? <h4 className="text-center">{location.state.errorMessage}</h4>
            : <h4 className="text-center">{t('box.new.errorPairingSubtitle')}</h4>}
        </div>
        <div className="mt-4 mb-4" style={{ width: '120px', margin: 'auto' }}>
          <Button className="btn btn-secondary" style={{ lineHeight: '32px', width: '120px' }} onClick={() => history.goBack()} value={t('box.new.tryAgain')} />
          {/* this.props.location.state && this.props.location.state.redirectUrl
            ? <Link className="btn btn-secondary" style={{ lineHeight: '32px', width: '120px' }} to={this.props.location.state.redirectUrl}>{t('box.new.tryAgain')}</Link>
          : <Link className="btn btn-secondary" style={{ lineHeight: '32px', width: '120px' }} to="/box/activation/start">{t('box.new.tryAgain')}</Link> */}
        </div>
        {/* <br />
        <br />
        {this.props.location.state && this.props.location.state.errorLink
          ? <a style={{ display: 'block', textAlign: 'right' }} target="_blank" rel="noopener noreferrer" href={this.props.location.state.errorLink} className="btn btn-link">{t('boxActivation.door.skipSmartlock')}</a>
          : null} */}
      </Card>
    </div>
  );
};

export default withTranslation()(ConnectionError);
