import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal/Modal';

import { ReactComponent as GreenCheck } from '../../../assets/images/solid/ok-circle-green.svg';

import styles from './PermissionModal.module.scss';

const pricingListItems = [
  'unlimitedKeys',
  'icalIntegration',
  'activityRecordUnlimited',
  'masterKeys',
  'unlimitedUsers',
  'apiAccess',
  'expiredKeyRecovery',
];

export default ({ click }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Modal click={click} height="auto" paddingBottom="24px">
      <>
        <div className={styles.permissionContainer}>
          <h2 className="col-12">{t('pricing.upgradeTitle')}</h2>
          <p className={`${styles.permissionSubtitle} col-12`}>
            {t('pricing.upgradeAddonTitle1')}
          </p>
          {pricingListItems.map((element) => (
            <div className="col-12" key={element} style={{ marginTop: '6px' }}>
              <div style={{ display: 'inline-block' }}>
                <GreenCheck className={styles.permissionGreenCheck} />
              </div>
              <p style={{ display: 'inline' }}>{t(`pricing.${element}`)}</p>
              <p style={{ marginLeft: '36px', fontWeight: '25' }}>{t(`pricing.${element}Description`)}</p>
            </div>
          ))}
        </div>
        <div className={styles.permissionButtonContainer}>
          <button
            className={`${styles.permissionButton} btn btn-secondary`}
            type="button"
            id="billing-button"
            onClick={() => history.push('/billing')}
          >
            {t('updatePlan')}
          </button>
        </div>
      </>
    </Modal>
  );
};
