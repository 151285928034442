import React from 'react';

import { CardContainer } from './styles';

const Card = ({ children, style }) => (
  <CardContainer style={style}>
    {children}
  </CardContainer>
);

export default Card;
