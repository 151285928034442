import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import style from './CreateEditKey.module.scss';

import PropertySelect from './PropertySelect';
// import { isUserPermitted } from '../../../utils/auth';
import PermissionModal from '../../ReusableComponents/PermissionModal/PermissionModal';

class PropertyListing extends React.Component {
  state = {
    // properties: [Date.now().toString()],
    selectInputs: [{ id: Date.now(), property: null }],
    showPermissionsModal: false,
  };

  componentDidMount() {
    if (this.props.selectedDoors.length >= 1) this.renderPropertiesOnEdit();
  }

  renderPropertiesOnEdit = () => {
    const properties = this.props.selectedDoors.map((doorId) => this.props.getDoorById(doorId).property);
    const uniqueProperties = [...new Set(properties)];

    // TODO: this is a messy solution for loading the default check-in/out times when creating a key
    // from the property view
    if (uniqueProperties.length === 1) {
      const propertyInfo = this.props.getPropertyById(uniqueProperties[0]);
      // this.props.propertyIdHandler && this.props.propertyIdHandler(newProperty);
      this.props.setDefaultTime(propertyInfo[0].defaultCheckIn, propertyInfo[0].defaultCheckOut);
    }

    return this.setState({
      selectInputs: uniqueProperties.map((property) => ({
        id: Date.now() + property,
        property,
      })),
    });
  };

  addProperty = () => {
    const { canCreateMasterKeys } = this.props;

    if (canCreateMasterKeys()) {
      this.setState({
        selectInputs: [...this.state.selectInputs, { id: Date.now() }],
      });
    } else {
      this.setState({ showPermissionsModal: true });
    }
  };

  deleteSelectHandler = (selectInput) => {
    this.setState({
      selectInputs: this.state.selectInputs.filter((selectInputObj) => selectInputObj.id !== selectInput.id),
    });
    this.deleteDoorsByProperty(selectInput.property);
  };

  deleteDoorsByProperty = (propertyId) => {
    const arrayDoors = this.props.getDoorsByPropertyId(propertyId).map((door) => door.id);
    return this.props.doorSelectHandler(false, arrayDoors);
  };

  propertySelectHandler = (newProperty, prevProperty, selectInput) => {
    this.setState({
      selectInputs: this.state.selectInputs.map((select) => (select.id === selectInput.id
        ? {
          ...select,
          property: newProperty,
        }
        : select)),
    });
    this.deleteDoorsByProperty(prevProperty);
    const propertyInfo = this.props.getPropertyById(newProperty);
    if (this.props.propertyIdHandler) this.props.propertyIdHandler(newProperty);
    this.props.setDefaultTime(propertyInfo[0].defaultCheckIn, propertyInfo[0].defaultCheckOut);
  };

  render() {
    if (this.props.resetSelectInputs) {
      this.setState({ selectInputs: [{ id: Date.now(), property: null }] },
        () => this.props.resetSelectInputsHandler(false));
    }

    const { keyType, t } = this.props;
    return (
      <>
        <label>{t('key.propertyAccess')}</label>
        <div className="form-group">
          {this.state.selectInputs.map((selectInput) => (
            <PropertySelect
              key={selectInput.id}
              propertyId={selectInput.property}
              // doors={this.props.doors}
              deleteSelect={this.state.selectInputs.findIndex((select) => select.id === selectInput.id) > 0 && (() => this.deleteSelectHandler(selectInput))}
              selectedDoors={this.props.selectedDoors}
              selectedProperties={this.state.selectInputs.map((i) => i.property)}
              doorSelectHandler={this.props.doorSelectHandler}
              propertySelectHandler={(newProperty, prevProperty) => this.propertySelectHandler(newProperty, prevProperty, selectInput)}
              deleteColumn={this.props.deleteColumn}
            />
          ))}
        </div>
        {
          // users may add properties to keys of any type
          // the permission modal will be shown if the user's account does not have the required add-on
          // when an existing key is loaded, the key's type is not available right away
          // so the user is unable to add properties if the button's visibility depends on the key's type
        }
        <button type="button" className="btn btn-primary btn-lg btn-block text-secondary" id={style.addPropertyButton} onClick={() => this.addProperty()}>
          +
          {' '}
          {t('key.addProperty')}
        </button>
        {this.state.showPermissionsModal && (
          <PermissionModal click={() => this.setState({ showPermissionsModal: false })} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties.properties,
  doors: state.doors.doors,
  user: state.user.user,
  getDoorById: (id) => state.doors.doors.find((door) => door.id === id),
  getDoorsByPropertyId: (propertyId) => state.doors.doors.filter((door) => door.property === parseInt(propertyId, 0)),
  getPropertyById: (propertyId) => state.properties.properties.filter((property) => property.id === parseInt(propertyId, 0)),
  canCreateMasterKeys: () => state.billing.customer.services.includes('service-keys-multiple-properties'),
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PropertyListing));
