import { createSlice } from '@reduxjs/toolkit';

import API from '../utils/api';

const initialState = {
  pending: false,
  success: false,
  boxes: [],
};

const boxes = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    apiPending: (state) => { state.pending = !state.pending; },
    apiSuccess: (state) => { state.success = !state.success; },
    GET: (state, action) => { state.boxes.push(...action.payload); },
  },
});

const getAll = async (dispatch, skip = 0) => {
  try {
    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = await API.get('boxes', { limit: 100, skip });
    dispatch(boxes.actions.GET(docs));
    if (next) return await getAll(dispatch, s + l);
    return docs;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getBoxes = () => async (dispatch) => {
  try {
    dispatch(boxes.actions.apiPending());
    const res = await getAll(dispatch);
    dispatch(boxes.actions.apiPending());
    dispatch(boxes.actions.apiSuccess());
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getBoxById = (state, id) => state.boxes.boxes.find((box) => box.id === id);

export { boxes };
