import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import styles from '../Property/Property.module.scss';

import PropertyMiniDesktop from './PropertyMiniDesktop';

import { ReactComponent as DoorsIcon } from '../../../assets/images/filled/doors.svg';
import { ReactComponent as KeysIcon } from '../../../assets/images/filled/keys.svg';
import { ReactComponent as BookingsIcon } from '../../../assets/images/filled/bookings.svg';
import { ReactComponent as RightIcon } from '../../../assets/images/lined/chevron-right.svg';
import { ReactComponent as BoxOfflineIcon } from '../../../assets/images/solid/offenergy.svg';
import { ReactComponent as LowBatteryIcon } from '../../../assets/images/solid/battery-low.svg';

import { getDoorsByProperty } from '../../../modules/doors';
import { getKeysByProperty } from '../../../modules/keys';
// import { getActivitiesByProperty } from '../../../ducks/activities';
import { getBoxById } from '../../../modules/boxes';
import { getKeypadById } from '../../../modules/keypads';
import { getLockById } from '../../../modules/locks';
import { getPropertyById } from '../../../modules/properties';

export const PropertyMini = ({
  id,
  t,
  history,
  getKeys,
  // getActivities,
  getDoors,
  getBox,
  getLock,
  getKeypad,
  getProperty,
}) => {
  const { address, name } = getProperty();
  return (
    <>
      <div
        className={`${styles.cardMini}`}
        id="property-link"
        onClick={() => history.push(`/property/${id}`)}
      >
        <div className="property-header col-10" style={{ paddingRight: '0px' }}>
          <h5 style={{ fontWeight: '700' }} id="property-name">{ name }</h5>
          {address
            && (
              <div className="row" style={{ margin: '0px', fontSize: '13px' }}>
                <div id="street-address">
                  {`${[
                    (address.street || address.streetAddress || ''),
                    (address.area || address.city || ''),
                    (address.country || ''),
                  ].filter((val) => val != null && val.search(/^$|^\s+$/) === -1).join(', ')}`}
                </div>
              </div>
            )}
          <div className="row" style={{ padding: '0px' }}>
            <div className="col" style={{ paddingRight: '0px' }}>
              <div className="row" style={{ margin: '0px', marginTop: '15px' }}>
                <div style={{ marginRight: '20px' }}>
                  <DoorsIcon className={styles.icon} />
                  <p id="door-amount" className={styles.iconText}>{getDoors().length}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <KeysIcon className={styles.icon} />
                  <p id="key-amount" className={styles.iconText}>{getKeys(['current', 'permanent']).length}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <BookingsIcon className={styles.icon} />
                  <p id="future-key-amount" className={styles.iconText}>{getKeys(['future']).length}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <Link
            style={{ height: '100%', marginRight: '0px', marginLeft: '0px' }}
            to={{ pathname: `/property/${id}`, params: { id } }}
          >
            <RightIcon style={{
              height: '100%', padding: '8px', position: 'absolute', marginRight: '0px', left: '0',
            }}
            />
          </Link>
        </div>
        <div className="col">
          {!(getDoors()
            .map((door) => (getBox(door.box) || {}).isOnline)
            .reduce((acc, val) => acc && val, true))
          && (
            <div className="" id="box-offline-message" style={{ marginBottom: '6px' }}>
              <BoxOfflineIcon className={styles.boxOfflineIcon} />
              <span className={styles.lowBatteryText}>{t('property.boxOffline')}</span>
            </div>
          )}
          {(getDoors() || []).map((door) => (
            <React.Fragment key={door.id}>
              {(door.locks || []).map((lockId: any) => {
                const lock = getLock(lockId) || {};
                if (lock.type === 'ble' && lock.lowBattery === true) {
                  return (
                    <div key={lockId} className="d-flex justify-content-end">
                      <LowBatteryIcon className={styles.lowBatteryIcon} />
                      <span className={styles.lowBatteryText}>{t('property.lowBattery')}</span>
                    </div>
                  );
                }
                if (lock.type === 'ble' && lock.isConnected === false) {
                  return (
                    <div key={lockId} className="d-flex justify-content-end">
                      <LowBatteryIcon className={styles.lowBatteryIcon} />
                      <span className={styles.lowBatteryText}>{t('property.lockOffline')}</span>
                    </div>
                  );
                }
                return undefined;
              })}
              {(door.keypads || []).map((keypadId: any) => {
                const keypad = getKeypad(keypadId) || {};
                if (keypad.type === 'ble' && keypad.lowBattery === true) {
                  return (
                    <div key={keypadId} className="d-flex justify-content-end">
                      <LowBatteryIcon className={styles.lowBatteryIcon} />
                      <span className={styles.lowBatteryText}>{t('property.lowBattery')}</span>
                    </div>
                  );
                }
                if (keypad.type === 'ble' && keypad.isConnected === false) {
                  return (
                    <div key={keypadId} className="d-flex justify-content-end">
                      <LowBatteryIcon className={styles.lowBatteryIcon} />
                      <span className={styles.lowBatteryText}>{t('property.keypadOffline')}</span>
                    </div>
                  );
                }
                return undefined;
              })}
            </React.Fragment>
          ))}
        </div>
      </div>

      {getKeys() && (
        <div
          className={styles.propertyMiniDesktop}
        >
          <PropertyMiniDesktop
            id={id}
            // keys={this.state.keys}
            // isBoxOffline={this.state.isBoxOffline}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  getPropertyById: (id) => state.properties.properties.find((property) => property.id === id),
  getDoors: () => getDoorsByProperty(state, props.id),
  getKeys: (durationTypeArray) => getKeysByProperty(state, props.id, durationTypeArray),
  // getActivities: () => getActivitiesByProperty(state, props.id),
  getBox: (id) => getBoxById(state, id),
  getKeypad: (id) => getKeypadById(state, id),
  getLock: (id) => getLockById(state, id),
  getProperty: () => getPropertyById(state, props.id),
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(withRouter(connect(mapStateToProps,
  mapDispatchToProps)(PropertyMini)));
