import React from 'react';
import { connect } from 'react-redux';
import LandingPage from './LandingPage/LandingPage';

const Plans = ({ boxes }) => (
  <div className="container" style={{ paddingTop: '32px' }}>
    {boxes.success
      ? <LandingPage />
      : <h1>Loading...</h1>}
  </div>
);

const mapStateToProps = (state) => ({
  user: state.user.user,
  boxes: state.boxes,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
