import React, { Suspense } from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Button from '../ReusableComponents/Button';

import BoxActivationStart from './BoxActivationStart';
import IntercomStart from './Intercom/IntercomStart';
import IntercomTest from './Intercom/IntercomTest';
import IntercomConnectionError from './Intercom/IntercomConnectionError';
import IntercomResult from './Intercom/IntercomResult';
import IntercomSuccess from './Intercom/IntercomSuccess';
import IntercomSave from './Doors/StreetDoorSave';

import NukiPairing from './Nuki/NukiPairing';
import NukiPairingWait from './Nuki/NukiPairingWait';
import NukiPairingCalibrating from './Nuki/NukiCalibrating';
import NukiTest from './Nuki/NukiTest';
import NukiTestSuccess from './Nuki/NukiTestSuccess';

import SmartlockKeypadPairing from './SmartlockKeypad/SmartlockKeypadPairing';
import SmartlockKeypadPairingWait from './SmartlockKeypad/SmartlockKeypadPairingWait';
import SmartlockKeypadTest from './SmartlockKeypad/SmartlockKeypadTest';
import SmartlockKeypadTestSuccess from './SmartlockKeypad/SmartlockKeypadTestSuccess';

import ElectricStrikeTest from './ElectricStrike/ElectricStrikeTest';
import ElectricStrikeResult from './ElectricStrike/ElectricStrikeResult';
import ElectricStrikeSuccess from './ElectricStrike/ElectricStrikeSuccess';

import MotorisedLockTest from './MotorisedLock/MotorisedLockTest';
import MotorisedLockResult from './MotorisedLock/MotorisedLockResult';
import MotorisedLockSuccess from './MotorisedLock/MotorisedLockSuccess';

import KeypadContent from './Keypad/KeypadContent';
import KeypadSetup from './Keypad/KeypadSetup';
import KeypadTest from './Keypad/KeypadTest';
import WiredKeypadTest from './Keypad/WiredKeypadTest';
import WiredKeypadConnectionError from './Keypad/WiredKeypadConnectionError';
import KeypadSuccess from './Keypad/KeypadSuccess';

import BoxActivationCode from './BoxActivationCode';

import DoorSave from './Doors/FlatDoorSave';

import CodeSuccess from './CodeSuccess';

import ConnectionError from './ConnectionError';
import ChooseLock from './ChooseLock';
import ReplaceDevices from './ReplaceDevices';

import styles from './BoxActivation.module.scss';

import { ReactComponent as Logo } from '../../assets/images/filled/logo.svg';

export default () => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || 'en';

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  return (
    <Suspense fallback="">
      {/* NAVBAR */}
      <div className={styles.desktopNavbar} id="listContainer" style={{ paddingTop: '0px' }}>
        <div className="row" style={{ height: '66px' }}>
          <div className="col-4">
            <span id="home-link" onClick={() => window.open('https://homeit.io')}>
              <Logo className={styles.logo} />
            </span>
          </div>
          {' '}
          <div
            className="ml-auto"
            style={{
              marginRight: '4%',
            }}
            onClick={() => window.open('https://homeit.freshdesk.com/en/support/tickets/new')}
          >
            <Button className="btn btn-secondary" value={t('boxActivation.help')} />
          </div>
          <div
              // className="ml-auto"
            style={{
              marginRight: '4%',
            }}
          >
            <select
              className={`form-control ${styles.languageSelect}`}
              defaultValue={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="en">EN</option>
              <option value="pt">PT</option>
              <option value="es">ES</option>
              <option value="fr">FR</option>
              <option value="it">IT</option>
              <option value="de">DE</option>
            </select>
          </div>
        </div>
      </div>

      {/* PROPERTY CARD */}
      <div className="container">
        <div id="listContainer" className={`${styles.card} activation`}>
          <BrowserRouter>
            <Switch>
              {/* START */}
              <Route exact path="/box/activation/start" component={BoxActivationStart} />
              {/* INTERCOM */}
              <Route exact path="/box/activation/intercom/start" component={IntercomStart} />
              <Route exact path="/box/activation/intercom/test" component={IntercomTest} />
              <Route exact path="/box/activation/intercom/result" component={IntercomResult} />
              <Route exact path="/box/activation/intercom/success" component={IntercomSuccess} />
              <Route exact path="/box/activation/intercom/error" component={IntercomConnectionError} />
              {/* KEYPAD */}
              <Route exact path="/box/activation/keypad" component={KeypadContent} />
              <Route exact path="/box/activation/keypad/setup" component={KeypadSetup} />
              <Route exact path="/box/activation/keypad/test" component={KeypadTest} />
              <Route exact path="/box/activation/keypad/wired/test" component={WiredKeypadTest} />
              <Route exact path="/box/activation/keypad/wired/error" component={WiredKeypadConnectionError} />
              <Route exact path="/box/activation/keypad/success" component={KeypadSuccess} />
              {/* CHOOSE LOCK */}
              <Route exact path="/box/activation/lock/choose" component={ChooseLock} />
              {/* NUKI LOCK */}
              <Route exact path="/box/activation/lock/nuki/pairing" component={NukiPairing} />
              <Route exact path="/box/activation/lock/nuki/pairing/wait" component={NukiPairingWait} />
              <Route exact path="/box/activation/lock/nuki/pairing/calibrating" component={NukiPairingCalibrating} />
              <Route exact path="/box/activation/lock/nuki/test" component={NukiTest} />
              <Route exact path="/box/activation/lock/nuki/success" component={NukiTestSuccess} />
              {/* CYLINDER KEYPAD LOCK */}
              <Route exact path="/box/activation/lock/smartlock-keypad/pairing" component={SmartlockKeypadPairing} />
              <Route exact path="/box/activation/lock/smartlock-keypad/pairing/wait" component={SmartlockKeypadPairingWait} />
              <Route exact path="/box/activation/lock/smartlock-keypad/test" component={SmartlockKeypadTest} />
              <Route exact path="/box/activation/lock/smartlock-keypad/success" component={SmartlockKeypadTestSuccess} />
              {/* CYLINDER LOCK */}
              <Route exact path="/box/activation/lock/smartlock/pairing" component={NukiPairing} />
              <Route exact path="/box/activation/lock/smartlock/pairing/wait" component={NukiPairingWait} />
              <Route exact path="/box/activation/lock/smartlock/test" component={NukiTest} />
              <Route exact path="/box/activation/lock/smartlock/success" component={NukiTestSuccess} />
              {/* ELECTRIC STRIKE */}
              <Route exact path="/box/activation/lock/electric-strike/test" component={ElectricStrikeTest} />
              <Route exact path="/box/activation/lock/electric-strike/result" component={ElectricStrikeResult} />
              <Route exact path="/box/activation/lock/electric-strike/success" component={ElectricStrikeSuccess} />
              {/* MOTORISED LOCK */}
              <Route exact path="/box/activation/lock/motorised/test" component={MotorisedLockTest} />
              <Route exact path="/box/activation/lock/motorised/result" component={MotorisedLockResult} />
              <Route exact path="/box/activation/lock/motorised/success" component={MotorisedLockSuccess} />
              {/* REPLACEMENT */}
              <Route exact path="/box/activation/device/replacement" component={ReplaceDevices} />
              {/* SAVE DOORS */}
              <Route exact path="/box/activation/door/street/save" component={IntercomSave} />
              <Route exact path="/box/activation/door/flat/save" component={DoorSave} />
              {/* END */}
              <Route exact path="/box/activation/code" component={BoxActivationCode} />
              <Route exact path="/box/activation/code/success" component={CodeSuccess} />
              {/* ERROR */}
              <Route exact path="/box/activation/error" component={ConnectionError} />

              <Redirect from='/box/activation' to='/box/activation/start' />
            </Switch>
          </BrowserRouter>
        </div>
        <div style={{ marginBottom: '24px' }} />
      </div>
    </Suspense>
  );
};
