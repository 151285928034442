import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import axios from 'axios';

import styles from './LandingPage.module.scss';

import Card from '../../ReusableComponents/Card/Card';

import { ReactComponent as GreenCheck } from '../../../assets/images/solid/ok-circle-green.svg';
import { ReactComponent as GreyCheck } from '../../../assets/images/solid/ok-circle-grey.svg';

import { AlertConfirm } from '../../../utils/alerts';

const services = [
  { service: 'service-keys-unlimited', text: 'pricing.unlimitedKeys' },
  { service: 'service-integrations-ical', text: 'pricing.icalIntegration' },
  { service: 'service-keyevents-unlimited', text: 'pricing.activityRecordUnlimited' },
  { service: 'service-keys-multiple-properties', text: 'pricing.masterKeys' },
  { service: 'service-organization-add-users', text: 'pricing.unlimitedUsers' },
  { service: 'service-homeit-api', text: 'pricing.apiAccess' },
  { service: 'service-keys-recover-expired', text: 'pricing.expiredKeyRecovery' },
];

class LandingPage extends React.Component {
  sendEmail = async () => {
    const {
      user,
      t,
    } = this.props;
    try {
      const res = await axios.post('/send-subscription-email', {
        email: (user.email || {}).address,
        name: (user.name || {}).first ? `${user.name.first} ${user.name.last}` : user.name,
        phone: `${(user.phone || {}).country} ${(user.phone || {}).number}`,
      });
      if (res) {
        AlertConfirm.fire({
          title: t('pricing.modal.thanks'),
          text: `${t('pricing.modal.message')} ${user.email.address}`,
          icon: 'success',
        });
      }
    } catch (err) {
      await AlertConfirm.fire({
        text: 'An error ocurred while sending this email. Please contact support.',
        icon: 'error',
      });
    }
  };

  render() {
    const {
      hasService,
      t,
    } = this.props;

    const enabledServices = services.filter(({ service }) => hasService(service));
    const disabledServices = services.filter(({ service }) => !hasService(service));

    return (
      <div style={{ maxWidth: '677px', margin: 'auto' }}>
        <div style={{ marginBottom: '32px' }}>
          <Card>
            <div className="row">
              <h2 className="col-12">{t('pricing.upgradeTitle')}</h2>
              <p className={`${styles.permissionSubtitle} col-12`}>
                {t('pricing.upgradeAddonTitle1')}
              </p>
              <p className={`${styles.permissionNote} col-12`}>
                <a
                  href="https://homeit.freshdesk.com/en/support/solutions/articles/42000071216-price-table-add-ons-premium"
                  className={styles.permissionNoteLink}
                >
                  {t('pricing.upgradeAddonTitle3')}
                </a>
              </p>
              <h4 className="col-12" style={{ fontSize: '16px' }}>{t('yourSubscriptions')}</h4>
              {enabledServices.length > 0
                ? enabledServices.map((element) => (
                  <div key={element.text} className="col-12" style={{ marginTop: '14px' }}>
                    <div style={{ display: 'inline' }}>
                      <GreenCheck />
                    </div>
                    <p style={{ display: 'inline' }}>{t(element.text)}</p>
                  </div>
                ))
                : (
                  <div className="col-12" style={{ marginTop: '14px' }}>
                    <p>{t('pricing.noAddons')}</p>
                  </div>
                )}
              {(disabledServices.length > 0)
                && <h4 className="col-12" style={{ fontSize: '16px', marginTop: '32px' }}>{t('moreSubscriptions')}</h4>}
              {disabledServices.map((element) => (
                <div key={element.text} className="col-12" style={{ marginTop: '14px' }}>
                  <div style={{ display: 'inline' }}>
                    <GreyCheck style={{ width: '35px' }} />
                  </div>
                  <p style={{ display: 'inline' }}>{t(element.text)}</p>
                </div>
              ))}
            </div>
          </Card>
        </div>
        <div style={{ marginBottom: '32px' }}>
          <Card>
            <h2 className="col-12" style={{ marginBottom: '32px' }}>{t('pricing.getInTouch')}</h2>
            <div className="col-12" style={{ maxWidth: '190px' }}>
              <button
                className="btn btn-secondary btn-block"
                type="button"
                onClick={() => this.sendEmail()}
              >
                {t('pricing.sendRequest')}
              </button>
            </div>
            {/*
            <h2 className="col-12" style={{ marginBottom: '32px' }}>{t('contactSales')}</h2>
            <div style={{ maxWidth: '677px', margin: 'auto' }}>
              <div className={styles.grid}>
                <EmailHelp alt="email help" />
                <div>
                  <p className={styles.gridText}>sales@homeit.io</p>
                </div>
              </div>
              <div className={styles.grid}>
                <PhoneHelp alt="phone help" />
                <div>
                  <p className={styles.gridText}>+351 234 567 891</p>
                </div>
              </div>
            </div>
          */}
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  hasService: (service) => state.billing.customer.services.includes(service),
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
