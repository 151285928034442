import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NukiHold } from '../../../assets/images/box-activation/icons-nuki-hold.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="activation-section" id="door-pairing">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.door.pairingTitle')}</h1>
            <div className="alert alert-warning">
              {t('boxActivation.door.paringAlert')}
              <br />
              {t('boxActivation.door.paringAlert1')}
              <strong>{t('boxActivation.door.paringAlertStrong')}</strong>
              {t('boxActivation.door.paringAlert2')}
            </div>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <div className="wrapper-icon text-center"><NukiHold alt="Press and hold the button on your Smart Lock for 5 seconds" style={{ width: '160px' }} /></div>
              <p className="formDescriptionTxt">{t('boxActivation.door.lockDescription')}</p>
            </div>
          </div>
          <div className="row justify-content-md-center text-center">
            <div className="col col-md-6 col-lg-4 formName">
              <Link to='/box/activation/lock/nuki/pairing/wait' className="btn btn-primary">{t('boxActivation.door.buttonStart')}</Link>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              <Link to='/box/activation/lock/choose' className="btn btn-link">{t('boxActivation.activationBack')}</Link>
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
