import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import style from './Main.module.scss';

import SyncCard from './SyncCard';
import PermissionModal from '../ReusableComponents/PermissionModal/PermissionModal';

const pmsList = [
  'Avantio',
];

export default () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    synchronisations,
    // doors: { doors },
    billing: { customer: { services } },
  } = useSelector((state) => state);

  const [isUserPermissionModalDisplayed, setIsUserPermissionModalDisplayed] = useState(false);
  // const [apiKeys, setApiKeys] = useState([]);

  const canUseIntegrations = () => services.includes('service-integrations-ical');

  // -----

  const listingsLength = (source) => synchronisations[source].listings.length;
  const hasCredentials = (source) => Object.keys(synchronisations[source].credentials).length > 0;

  const icalStatus = () => (listingsLength('ical') > 0
    ? t('synchronisation.calendarsSynced', { count: listingsLength('ical') })
    : t('synchronisation.syncIcal'));

  const pmsStatus = (source) => (hasCredentials(source) && listingsLength(source) > 0
    ? `${listingsLength(source)}  ${t('synchronisation.propertiesSynced')}`
    : t('synchronisation.integratePms'));

  // const addApiKey = () => setApiKeys([...apiKeys, { id: Date.now(), key: null }]);
  // const deleteApiKey = (id) => setApiKeys(apiKeys.filter((apiKey) => apiKey.id !== id));

  const gotoSource = (source) => {
    history.push({ pathname: `/synchronisations/${source}` });
  };

  return (
    <div id="listContainer" className="container">
      {isUserPermissionModalDisplayed
        && <PermissionModal click={() => setIsUserPermissionModalDisplayed(false)} />}
      <div className="row" id={style.keysHeader}>
        <div className="col">
          <h1>{t('synchronisations')}</h1>
          <p className="headerDescription">
            {t('synchronisation.syncMessage')}
          </p>
          <div style={{ paddingBottom: '30px' }} />
        </div>
      </div>
      <div className="row">
        <div key={0} className="col-xs-12 col-md-4">
          <SyncCard
            key={0}
            id={0}
            name='iCal'
            description={icalStatus()}
            click={() => {
              if (listingsLength('ical') > 0) return gotoSource('ical');
              return canUseIntegrations() ? gotoSource('ical') : setIsUserPermissionModalDisplayed(true);
            }}
            calendarQuantity={listingsLength('ical')}
          />
        </div>
        {pmsList.map((name, i) => (
          <div key={i} className="col-xs-12 col-md-4 mb-4">
            <SyncCard
              key={i}
              id={i}
              name={name}
              description={pmsStatus(name.toLowerCase())}
              click={() => {
                if (listingsLength(name.toLowerCase()) > 0) return gotoSource(name);
                return canUseIntegrations() ? gotoSource(name)
                  : setIsUserPermissionModalDisplayed(true);
              }}
              calendarQuantity={listingsLength(name.toLowerCase())}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
