import React from 'react';

import style from './Modal.module.scss';
import { ReactComponent as CloseButton } from '../../../assets/images/lined/close.svg';

const Modal = ({
  children, click, height, paddingBottom,
}) => (
  <div className={style.modalBackground}>
    <div className={style.modalCard} id="modal-container" style={{ height, paddingBottom }}>
      <CloseButton className={`close ${style.modalCloseButton}`} onClick={click} />
      {children}
    </div>
  </div>
);

export default Modal;
