import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pending: false,
  success: false,
  stage: 'welcome',
  // updateKeySuccess: false,
  data: {},
};

export const guestInvitation = createSlice({
  name: 'guestInvitation',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    setStage: (state, action) => { state.stage = action.payload || 'welcome'; },
    setData: (state, action) => { state.data = action.payload; },
  },
});

export default guestInvitation;
