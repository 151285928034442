import { createSlice } from '@reduxjs/toolkit';

import API from '../utils/api';

const initialState = {
  pending: false,
  success: false,
  properties: [],
};

const properties = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    GET: (state, action) => { state.properties.push(...action.payload); },
    PUT: (state, action) => {
      state.properties = state.properties.map((property) => {
        if (property.id === action.payload.id) property = { ...action.payload };
        return property;
      });
    },
    POST: (state, action) => { state.properties.push(action.payload); },
    filter: (state, action) => { state.search = action.payload; },
  },
});

export const filterProperties = (filter) => (dispatch) => {
  dispatch(properties.actions.filter(filter));
};

const getAllProperties = async (dispatch, skipValue = 0) => {
  try {
    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = await API.get('properties', { limit: 100, skip: skipValue });
    dispatch(properties.actions.GET(docs));
    if (next) return await getAllProperties(dispatch, s + l);
    return docs;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getProperties = () => async (dispatch) => {
  dispatch(properties.actions.apiPending());
  try {
    const props = await getAllProperties(dispatch);
    dispatch(properties.actions.apiPending(false));
    dispatch(properties.actions.apiSuccess(true));
    return props;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const createProperty = (data) => async (dispatch) => {
  try {
    const res = await API.create('properties', {}, data);
    dispatch(properties.actions.POST(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const editProperty = (data, id) => async (dispatch) => {
  try {
    const res = await API.update(`properties/${id}`, {}, data);
    dispatch(properties.actions.PUT(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const getPropertyById = (state, id) => state.properties.properties
  .find((property) => property.id === id);

export { properties };
