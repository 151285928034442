import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import * as luxon from 'luxon';

import API from '../utils/api';

const initialState = {
  pending: false,
  success: false,
  keys: [],
};

const addCustomInfoToKey = (key) => {
  // visibility flag for filtering
  // key = { ...key, isVisible: true };
  // KEY TIME TYPE
  if (key.isExpiring === false) {
    key.durationType = 'permanent';
  } else if (key.checkIn && moment().isBetween(key.checkIn, key.checkOut)) {
    key.durationType = 'current';
  } else if (key.checkIn && moment().isBefore(key.checkIn)) {
    key.durationType = 'future';
  } else if (key.checkIn && moment().isAfter(key.checkOut)) {
    key.durationType = 'expired';
  }
  return key;
};

const keys = createSlice({
  name: 'keys',
  initialState,
  reducers: {
    apiPending: (state) => { state.pending = !state.pending; },
    apiSuccess: (state) => { state.success = !state.success; },
    GET: (state, action) => {
      const newKeys = action.payload.map((key) => addCustomInfoToKey(key));
      state.keys = [...state.keys, ...newKeys];
    },
    DELETE: (state, action) => {
      state.keys = state.keys.filter((key) => key.id !== action.payload);
    },
    PUT: (state, action) => {
      state.keys = state.keys.map((key) => {
        if (key.id === action.payload.id) key = { ...addCustomInfoToKey(action.payload) };
        return key;
      });
    },
    POST: (state, action) => { state.keys = [...state.keys, addCustomInfoToKey(action.payload)]; },
    filter: (state, action) => { state.search = action.payload; },
  },
});

const fetchKeys = async (options: any = { isExpiring: true }, acc = []) => {
  try {
    const res = await API.get('keys', {
      ...options,
      limit: 100,
      order: 'desc',
      sortBy: 'dateCreated',
    });

    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = res;

    acc = [...acc, ...docs];

    if (next) return await fetchKeys({ ...options, skip: s + l }, acc);
    return acc;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getAllKeys = async (dispatch) => {
  try {
    const nonPermanentKeys = await fetchKeys({
      checkOutAfter: luxon.DateTime.utc().minus({ days: 2 }).toISO(),
      isExpiring: true,
    });
    const permanentKeys = await fetchKeys({
      isExpiring: false,
    });
    const newKeys = [...nonPermanentKeys, ...permanentKeys];
    dispatch(keys.actions.GET(newKeys));
    return newKeys;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getKeys = () => async (dispatch) => {
  try {
    dispatch(keys.actions.apiPending());
    const res = await getAllKeys(dispatch);
    dispatch(keys.actions.apiPending());
    dispatch(keys.actions.apiSuccess());
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const createKey = (data) => async (dispatch) => {
  try {
    const res = await API.create('keys', {}, data);
    dispatch(keys.actions.POST(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const editKey = (data, id) => async (dispatch) => {
  try {
    const res = await API.update(`keys/${id}`, {}, data);
    dispatch(keys.actions.PUT(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const deleteKey = (id) => async (dispatch) => {
  try {
    const res = await API.remove(`keys/${id}`);
    dispatch(keys.actions.DELETE(res.docs[0].id));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const removeNewTag = (id) => async (dispatch) => {
  try {
    const key = await API.get(`keys/${id}`);
    const res = await API.update(`keys/${id}`, {}, { tags: key.docs[0].tags.filter((tag) => tag !== 'new') });
    dispatch(keys.actions.PUT(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

export const filterKeys = (filter) => (dispatch) => {
  dispatch(keys.actions.filter(filter));
};

export const getKeysByProperty = (state, id, durationTypeArray) => {
  const doors = state.doors.doors
    .filter((door) => door.property === id)
    .map((door) => door.id);
  const list = state.keys.keys
    .filter((key) => key.doors.some((door) => doors.includes(door)));
  return durationTypeArray
    ? list.filter((key) => durationTypeArray.includes(key.durationType))
    : list;
};

export const getKeysByDurationType = (state, durationTypeArray) => {
  const { keys: { keys: list } } = state;
  return durationTypeArray
    ? list.filter((key) => durationTypeArray.includes(key.durationType))
    : list;
};

export const getKeyById = (state, id) => state.keys.keys
  .find((key) => key.id === id);

export { keys };
