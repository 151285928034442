import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { KeysHeader, ModalContainer, CardList } from './styles';
import Host from './Host';
import Card from '../ReusableComponents/Card/Card';
import Modal from '../ReusableComponents/Modal/Modal';
import { ReactComponent as AddIcon } from '../../assets/images/lined/plus-white.svg';

import { validateInvitationToken, processInvitationToken } from '../../modules/hostInvitation';
import { AlertLinkOptions } from '../../utils/alerts';

export const HostList = ({
  invitationTokenData,
  cohosts,
  users,
  user,
  allproperties,
  t,
  validateToken,
  processToken,
  history,
  customer,
}) => {
  const [isPremium] = useState(!!customer.services.find((i) => i === 'service-organization-add-users'));
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const validateUsers = [...users];
  validateUsers.splice(users.indexOf(users.find((item) => item.id === user.id)), 1);
  const prevAllUsers = [
    {
      ...user,
      currentUser: true,
      properties: allproperties.filter((pprty) => pprty.owners.includes(user.id)),
    },
    ...validateUsers.map((usr) => (
      { ...usr, properties: allproperties.filter((pprty) => pprty.owners.includes(usr.id)) }
    )),
    ...cohosts.map((item) => ({ ...item, isInvitation: true })),
  ];

  const [allUsers] = useState(prevAllUsers);

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (token) {
      validateToken(token);
      setShowInvitationModal(true);
    }
  }, []); // run only once

  const processHostHandler = async () => {
    await processToken(invitationTokenData.token);
    window.location = '/';
  };

  const alertNotPremium = () => {
    AlertLinkOptions.fire({
      icon: 'warning',
      text: t('users.premiumWarning'),
      cancelButtonText: t('cancel'),
      confirmButtonText: t('updatePlan'),
    }).then(async (result) => {
      if (result.value) {
        history.push({ pathname: '/billing' });
      }
    });
  };

  return (
    <div id="listContainer" className="container">
      {showInvitationModal && (
        <Modal
          click={() => setShowInvitationModal(!showInvitationModal)}
        >
          <ModalContainer>
            <div>
              <h2>{t('users.invitationModal.title')}</h2>
              <h6>{t('users.invitationModal.subtitle')}</h6>
              {invitationTokenData
                && invitationTokenData.properties.map((property) => <p>{property.name}</p>)}
              <br />
              <p>{t('users.invitationModal.explanation')}</p>
            </div>
            <div>
              <div
                role="button"
                className="btn btn-secondary mt-4"
                onClick={() => processHostHandler()}
                onKeyDown={() => processHostHandler()}
              >
                {t('users.invitationModal.ok')}
              </div>
            </div>
          </ModalContainer>
        </Modal>
      )}
      <KeysHeader>
        <div>
          <h1>
            {t('users.manageUsers')}
          </h1>
          <p>
            {users.length}
            {' '}
            {t('users.users')}
          </p>
        </div>

        {(user.organizationRole !== 'member' || !user.organizationRole) && (
          <>
            { isPremium ? (
              <Link to="/manage/host/item" className="btnCreate">
                <AddIcon />
              </Link>
            ) : (
              <div role="button" className="btnCreate" onClick={() => alertNotPremium()}>
                <AddIcon />
              </div>
            )}
          </>
        )}
      </KeysHeader>
      {allUsers.length > 0 ? (
        <>
          {allUsers.map((host) => (
            <div key={host.id}>
              <Host
                {...host}
                isPremium={isPremium}
                key={host.id}
                isMember={user.organizationRole ? user.organizationRole === 'member' : false}
                enabledProperties={host.properties}
              />
            </div>
          ))}
        </>
      ) : (
        <Card>
          <div>
            <h2>{t('users.howItWorks')}</h2>

            <CardList>
              <li>
                <p>{t('users.step1')}</p>
              </li>
              <li>
                <p>{t('users.step2')}</p>
              </li>
              <li>
                <p>{t('users.step3')}</p>
              </li>
              <li>
                <p>{t('users.step4')}</p>
              </li>
            </CardList>
          </div>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  invitationTokenData: state.hostInvitation.invitationTokenData,
  cohosts: state.hostInvitation.cohosts,
  test: state.hostInvitation.test,
  user: state.user.user,
  users: state.user.users,
  allproperties: state.properties.properties,
  customer: state.billing.customer,
});

const mapDispatchToProps = (dispatch) => ({
  validateToken: (token) => dispatch(validateInvitationToken(token)),
  processToken: (token) => dispatch(processInvitationToken(token)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HostList),
);
