import React from 'react';
import style from './Spinner.module.scss';

const Spinner = ({ width }) => (
  <div style={{ width: width || '48px', margin: 'auto', position: 'relative' }}>
    <div className={style.ldsSpinner}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
