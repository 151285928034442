import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { searchMatch } from '../../../utils/global';
import Key from '../Key/Key';
import NoItemsMessage from '../../ReusableComponents/NoItemsMessage/NoItemsMessage';
// import { getKeysByDurationType } from '../../../ducks/keys';

import style from '../Main.module.scss';

import { ReactComponent as KeysIcon } from '../../../assets/images/illustration/key.svg';

// eslint-disable-next-line complexity
export const KeysList = ({
  t, location, keys, search,
}) => {
  const [doorsFilter] = useState(location.state
    && location.state.doors
    && [...location.state.doors]);

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const keyId = params.get('keyId');
      // TODO: should wait for the keys to load so that the scroll and animation work if param is passed
      if (document.getElementById(keyId)) {
        document.getElementById(keyId).scrollIntoView({ block: 'center' });
        document.getElementById(keyId);
        document.getElementById(keyId).classList.add('keyHighlight');
      }
    }
  }, []); // run only once

  const renderKeys = (durationType = false) => {
    // this applies a door filter, in order to filter by property
    keys = doorsFilter
      ? keys
        .filter((key) => key.doors
          .map((doorId) => doorsFilter
            .includes(doorId))
          .reduce((acc, val) => acc || val, false))
      : keys;

    const keysToDisplay = [...keys.filter((k) => (durationType
      ? (k.durationType === durationType) : true))];

    return keysToDisplay.map((item) => ({
      ...item,
      isVisible: searchMatch(search, [
        item.name,
        item.numericCode,
        item.propertyNames,
        item.doorNames,
      ]),
    }));
  };

  return (
    <>
      {/* <Filter
                    searchFilter={(text) =>  {
                        if(text === '') {
                            this.setState({ filter: { ...this.state.filter, textSearch: '' } })
                        } else {
                            this.setState({ filter: { ...this.state.filter, textSearch: text } })
                        }
                    }}
                /> */}
      {(renderKeys().length >= 1 && renderKeys().some((i) => i.isVisible === true)) ? (
        <div className={`${style.keyList} ${!renderKeys('current').some((i) => i.isVisible === true) ? style.withMargin : ''}`}>
          {renderKeys('current').map((key) => <Key {...key} keyStatus="current" isFullKeyDisplayed key={key.id} />)}

          {(renderKeys('future').length >= 1 && renderKeys('future').some((item) => item.isVisible === true)) && (
          <>
            <div className="mt-4">{t('key.future')}</div>
            {renderKeys('future').map((key) => <Key {...key} keyStatus="future" isFullKeyDisplayed key={key.id} />)}
          </>
          )}

          {(renderKeys('permanent').length >= 1 && renderKeys('permanent').some((item) => item.isVisible === true)) && (
          <>
            <div className="mt-4">{t('key.permanent')}</div>
            {renderKeys('permanent').map((key) => <Key {...key} keyStatus="permanent" isFullKeyDisplayed key={key.id} />)}
          </>
          )}

          {(renderKeys('expired').length >= 1 && renderKeys('expired').some((item) => item.isVisible === true)) && (
          <>
            <div className="mt-4">{t('key.expired')}</div>
            {renderKeys('expired').map((key) => <Key {...key} keyStatus="expired" isFullKeyDisplayed key={key.id} />)}
          </>
          )}
        </div>
      ) : (
        <NoItemsMessage
          heading={t('key.noKeys1')}
          subheading={t('key.noKeys2')}
        >
          <KeysIcon style={{ width: '78px', height: '78px' }} />
        </NoItemsMessage>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  search: state.keys.search,
});

export default withRouter(
  withTranslation()(connect(mapStateToProps)(KeysList)),
);
