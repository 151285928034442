import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconConnectionError } from '../../../assets/images/box-activation/icon-connection-error.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="activation-section" id="intercom-error">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <div className="wrapper-icon text-center description-txt"><IconConnectionError alt="connection error" style={{ width: '190px' }} /></div>
            <div className="description-txt">
              <h1>{t('boxActivation.intercom.errorTitle')}</h1>
              <h4>{t('boxActivation.intercom.errorSubtitle')}</h4>
            </div>
            <div className="alert alert-danger formName" role="alert">
              {t('boxActivation.intercom.errorAlert1')}
              <br />
              <a className="alert-link" href="https://homeit.io/install/intercom-compatibility/" target="blank">{t('boxActivation.intercom.errorAlertLink')}</a>
              {t('boxActivation.intercom.errorAlert2')}
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center text-center description-txt">
          <div className="col col-md-6 col-lg-4 formName">
            <Link className="btn btn-primary" to='/box/activation/start'>{t('boxActivation.intercom.buttonTry')}</Link>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-8 col-lg-6">
              <p className="formDescriptionTxt">
                {t('boxActivation.intercom.errorTxt')}
                <a href="https://homeit.freshdesk.com/support/tickets/new">{t('boxActivation.intercom.errorLink')}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
