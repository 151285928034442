import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';

export default () => {
  const [waitingButtonDisabled, setWaitingButtonDisabled] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const testSmartlock = () => {
    setWaitingButtonDisabled(true);

    const doorId = localStorage.getItem('currentDoor');
    const doorMac = localStorage.getItem('smartlockMac');

    getBox()
      .then((box) => box.lock.get(doorId, doorMac))
      .then((lock) => new Promise((resolve, reject) => {
        if (!lock) throw new Error('Lock does not exist.');
        setTimeout(() => resolve(lock), 10000);
        lock.open((res) => {
          if (res && res.progress === 100) resolve(lock);
        }, reject).catch(reject);
      }))
      .then(() => history.push({ pathname: '/box/activation/lock/smartlock-keypad/success' }))
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/lock/smartlock-keypad/test',
            errorMessage: String(err),
          },
        });
      });
  };

  return (
    <div className="activation-section" id="door-pairing-test">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.intercom.testTitle')}</h1>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center text-center" id="selector">
            <div className="col col-md-8 col-lg-6">
              <button className="btn btn-circle" type="button" onClick={testSmartlock}>
                {waitingButtonDisabled
                  ? <div className="btn-circle-txt" id="circularBtn-door-wait">{t('boxActivation.intercom.waitButton')}</div>
                  : <div className="btn-circle-txt" id="circularBtn-door">{t('boxActivation.intercom.testButton')}</div>}
              </button>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              <Link to='/box/activation/lock/smartlock-keypad/pairing' className="btn btn-link">{t('boxActivation.activationBack')}</Link>
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
