import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';
import WaitingModal from '../WaitingModal';

import { ReactComponent as IconComplete } from '../../../assets/images/box-activation/complete.svg';

export default () => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const replaceDevice = JSON.parse(window.localStorage.getItem('replaceDevice'));

  useEffect(() => {
    if (replaceDevice) {
      setModalOpen(true);
      getBox().then((b) => b.door.get(parseInt(window.localStorage.getItem('currentDoor'), 10)))
        .then((door) => door.save(replaceDevice.doorName))
        .then(() => {
          window.localStorage.removeItem('replaceDevice');
        })
        .then(() => setModalOpen(false))
        .catch((err) => {
          history.push({
            pathname: '/box/activation/error',
            state: {
              redirectUrl: '/doors',
              errorMessage: String(err),
            },
          });
        });
    }
  }, []); // run only once

  return (
    <div className="activation-section" id="intercom-success">
      {modalOpen ? <WaitingModal /> : null}
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <div className="wrapper-icon"><IconComplete alt="motorised well connected" style={{ width: '144px' }} /></div>
            <h1>{t('boxActivation.intercom.successTitle')}</h1>
            {replaceDevice
              ? <h4>{t('deviceConfigurationComplete')}</h4>
              : <h4>{t('boxActivation.motorisedLock.successSubtitle')}</h4>}
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
            <div className="col col-md-4 col-lg-3 text-center">
              {replaceDevice
                ? <a href='/doors' className="btn btn-block btn-outline-primary">{t('exitConfiguration')}</a>
                : <Link to='/box/activation/keypad' className="btn btn-block btn-outline-primary">{t('boxActivation.buttonNext')}</Link>}
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
