import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';

export default () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [waitButtonDisplayed, setWaitButtonDisplayed] = useState(false);
  const replaceDevice = JSON.parse(window.localStorage.getItem('replaceDevice'));

  const testMotorisedLock = () => {
    const activeRelay = '00:00:00:00:00:03';

    getBox()
      .then((box) => (replaceDevice
        ? box.door.get(parseInt(window.localStorage.getItem('currentDoor'), 10))
          .then((door) => door.lock.add(activeRelay))
        : box.door.add()
          .then((door) => {
            localStorage.setItem('currentDoor', door.idInt);
            return door.lock.add(activeRelay);
          })))
      .then((lock) => {
        lock.config(1);
        return lock;
      })
      .then((lock) => new Promise((resolve, reject) => {
        if (!lock) throw new Error('Lock does not exist.');
        lock.open((res) => {
          if (res && res.progress === 100) resolve(lock);
        }, reject).catch(reject);
      }))
      .then(() => {
        history.push({ pathname: '/box/activation/lock/motorised/result' });
      })
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/lock/motorised/test',
            errorMessage: String(err),
          },
        });
      });
  };

  return (
    <div className="activation-section" id="intercom-test">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.motorisedLock.testTitle')}</h1>
            <p className="description-txt">{t('boxActivation.motorisedLock.testDescription')}</p>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center text-center" id="selector">
            <div className="col col-md-8 col-lg-6">
              <button type="button" className="btn btn-circle" onClick={() => testMotorisedLock()}>
                {waitButtonDisplayed
                  ? (
                    <div className="btn-circle-txt" id="circularBtn-intercom-wait">
                      {t('boxActivation.intercom.waitButton')}
                    </div>
                  )
                  : (
                    <div className="btn-circle-txt" id="circularBtn-intercom" onClick={() => setWaitButtonDisplayed(true)}>
                      {t('boxActivation.intercom.testButton')}
                    </div>
                  )}
              </button>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              <Link to='/box/activation/lock/choose' className="btn btn-link">{t('boxActivation.activationBack')}</Link>
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
