import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import lodash from 'lodash';

import Card from '../ReusableComponents/Card/Card';

class BoxPropertyStart extends React.Component {
  state = {
    activationCode: '',
  };

  componentDidMount() {
    this.userHasPropertiesHandler();
    this.setState({ activationCode: Cookies.get('boxActivationToken') });
  }

  userHasPropertiesHandler = () => {
    const { activationCode } = this.state;
    const { properties } = this.props;
    if (!properties.length || lodash.some(properties, ['isDemo', true])) {
      this.props.history.push(`/box/new/new-property?boxActivationToken=${activationCode}`);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="container container--padding" style={{ maxWidth: '677px' }} id="listContainer">
        <h1>{t('box.new.propertyTitle')}</h1>
        <div className="mb-3">{t('box.new.propertySubtitle')}</div>
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
            <Card>
              <div className="six columns activation-card activation-card-responsive">
                <h6 className="activation-card-title text-center">{t('box.new.propertyExisting')}</h6>
                <Link to="/box/new/associate-property">
                  <input
                    className="btn-secondary btn btn--apply btn-card mt-5"
                    type="submit"
                    style={{
                      marginTop: '20px', marginBottom: '0px', color: '#fff', width: '120px', margin: 'auto', display: 'block', textDecoration: 'none',
                    }}
                    value={t('box.new.add')}
                  />
                </Link>
              </div>
            </Card>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
            <Card>
              <div className="six columns activation-card activation-card-right activation-card-responsive">
                <h6 className="activation-card-title text-center">{t('box.new.propertyNew')}</h6>
                <Link to="/box/new/new-property">
                  <input
                    className="btn-secondary btn btn--apply btn-card mt-5"
                    type="submit"
                    style={{
                      marginTop: '20px', marginBottom: '0px', color: '#fff', width: '120px', margin: 'auto', display: 'block', textDecoration: 'none',
                    }}
                    value={t('box.new.create')}
                  />
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties.properties,
});

const mapDispatchToProps = () => ({
});

export default withRouter(withTranslation()(connect(mapStateToProps,
  mapDispatchToProps)(BoxPropertyStart)));
