import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';

export default () => {
  const [keypadCode, setKeypadCode] = useState();
  const history = useHistory();
  const { t } = useTranslation();

  const startKeypadTest = () => {
    const keys = [];
    const min = 10000;
    const max = 99999;
    const code = Math.floor(Math.random() * (max - min) + min);
    const doorId = parseInt(localStorage.getItem('currentDoor') || '1', 10);
    const keypadMac = localStorage.getItem('keypadMac');

    getBox()
      .then((box) => box.keypad.get(doorId, keypadMac))
      .then((keypad) => new Promise((resolve, reject) => keypad.startTest((evt) => {
        keys.push(evt.key);
        if (keys.slice(Math.max(keys.length - 5, 0)).join('') === `${code}`) resolve(keypad);
      })
        .catch(reject)
        .then(() => setKeypadCode(`*${code}`))))
      .then((keypad) => keypad.stopTest())
      .then(() => history.push('success'))
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/keypad/test',
            errorMessage: String(err),
          },
        });
      });
  };

  useEffect(() => startKeypadTest(), []); // run only once

  return (
    <div className="activation-section" id="door-keypad-test">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.door.padTestTitle')}</h1>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <span><strong>{t('boxActivation.door.padTestCodeTxt')}</strong></span>
              {!keypadCode
                ? <div className="alert alert-homeit text-center formName" role="alert" id="keypad-code">{t('boxActivation.door.generatingCode')}</div>
                : <div className="alert alert-homeit text-center formName" role="alert" id="keypad-code">{keypadCode}</div>}
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <p className="formDescriptionTxt">
                {t('boxActivation.door.padTestDescription')}
              </p>
              <p className="formDescriptionTxt">
                {t('boxActivation.door.padTestError') }
                <a href="https://homeit.freshdesk.com/support/tickets/new">
                  {' '}
                  {t('boxActivation.door.padTestErrorLink')}
                  {' '}
                </a>
                {t('boxActivation.door.padTestError2')}
              </p>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
