import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import * as luxon from 'luxon';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, withRouter } from 'react-router-dom';

import styles from './Key.module.scss';

import { ReactComponent as ChevronDownIcon } from '../../../assets/images/lined/chevron-down.svg';

import Modal from '../../ReusableComponents/Modal/Modal';
import Button from '../../ReusableComponents/Button';
import PermissionModal from '../../ReusableComponents/PermissionModal/PermissionModal';
import { deleteKey, removeNewTag } from '../../../modules/keys';
import { getDoorById } from '../../../modules/doors';
import { getPropertyById } from '../../../modules/properties';
// import { isUserPermitted } from '../../../utils/auth';
import { AlertConfirm } from '../../../utils/alerts';

export class Key extends React.Component {
  state = {
    codeShowing: false,
    codeCopied: false,
    properties: [],
    isModalOpen: false,
    isFullKeyDisplayed: !!this.props.isFullKeyDisplayed,
    isUserPermissionModalDisplayed: false,
  };

  componentDidMount() {
    const { doors, getDoor } = this.props;
    const properties = [];
    if (doors) {
      doors.forEach((doorId) => {
        const doorInfo = getDoor(doorId);
        if (doorInfo && doorInfo.property) {
          if (!properties.includes(doorInfo.property)) {
            properties.push(doorInfo.property);
          }
        }
      });
      this.setState({ properties });
    }
  }

  deleteKey = async () => {
    const { id, deleteKey: del } = this.props;
    await del(parseInt(id, 0));
  };

  keyIconHandler = (tag) => {
    const { propertyView } = this.props;
    switch (tag) {
      case 'guest':
        return <div className={`${propertyView ? 'col-md-2' : 'col-1'} ${styles.icon}`} />;
      case 'manager':
        return <div className={`${propertyView ? 'col-md-2' : 'col-1'} ${styles.managerIcon}`} />;
      case 'services':
        return <div className={`${propertyView ? 'col-md-2' : 'col-1'} ${styles.serviceIcon}`} />;
      default:
        return <div className={`${propertyView ? 'col-md-2' : 'col-1'} ${styles.icon}`} />;
    }
  };

  canRecoverKey = () => {
    const {
      id,
      history,
      canRecoverExpiredKeys,
    } = this.props;
    if (canRecoverExpiredKeys()) {
      history.push({ pathname: `/recover-key/${id}`, params: { id } });
    } else {
      this.setState({ isUserPermissionModalDisplayed: true });
    }
  };

  toggleShowCode = () => {
    const { codeShowing } = this.state;
    this.setState({
      codeShowing: !codeShowing,
    });
  };

  // TODO: move this to utils

  // eslint-disable-next-line complexity
  render() {
    const {
      id,
      name,
      doors,
      checkIn: checkInString,
      checkOut: checkOutString,
      numericCode,
      smsToken,
      linkToken,
      tags,
      keyStatus,
      // removeNewTag,
      propertyView,
      getProperty,
      getDoor,
      isVisible,
      t,
    } = this.props;

    const {
      properties,
      isFullKeyDisplayed,
      isUserPermissionModalDisplayed,
      isModalOpen,
      codeCopied,
      codeShowing,
    } = this.state;

    // TODO: this timezone calc doesn't cover master keys with multiple properties...
    const { timezone } = getProperty(properties[0]) || {};
    const checkIn = moment(luxon.DateTime.fromISO(checkInString, { zone: timezone }).toJSDate());
    const checkOut = moment(luxon.DateTime.fromISO(checkOutString, { zone: timezone }).toJSDate());
    const totalDuration = moment.duration(checkOut.diff(checkIn)).asMinutes();
    const currentDuration = moment.duration(moment().diff(checkIn)).asMinutes();
    const progress = (currentDuration * 100) / totalDuration;

    return (
      <div
        className={`${styles.keyContainer}`}
        style={{ display: isVisible === false ? 'none' : 'block' }}
      >
        <div className={styles.key} id={id}>
          {isModalOpen
              && (
              <Modal
                click={() => this.setState({ isModalOpen: !isModalOpen })}
              >
                <div style={{ margin: 'auto', width: '250px', padding: '20px 0px' }}>
                  <h2>{t('key.smsTokenTitle')}</h2>
                  <p style={{ fontSize: '14px' }}>{t('key.smsTokenInfo')}</p>
                  <div className="mt-2 mb-4">
                    <p style={{ fontSize: '14px' }}>+351 927 819 042 (Europe)</p>
                    <p style={{ fontSize: '14px' }}>+1 (256) 530-8295 (USA/Canada)</p>
                  </div>
                  {doors.map((doorId) => (
                    <div key={doorId} className="mt-2">
                      <div className="text-left" style={{ fontSize: '14px' }}>{(getDoor(doorId) || {}).name}</div>
                      <div className="row">
                        <div className="text-left col-9" style={{ fontSize: '20px', lineHeight: '45px' }}>{`${smsToken}#${doorId}`}</div>
                        <CopyToClipboard
                          className="btn btn-secondary text-uppercase col"
                          text={`${smsToken}#${doorId}`}
                          // onCopy={() => {
                          //   this.setState({ doorCopied: true });
                          //   setTimeout(() => this.setState({ doorCopied: false }), 500);
                          // }}
                        >
                          <Button value={t('copy')} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))}
                </div>
              </Modal>
              )}
          <div className={styles.cardContainerKeys} style={{ minHeight: isFullKeyDisplayed ? '90px' : '30px' }}>
            {/* {tags && tags.includes('new')
                && (
                <div className="col-12" style={{ height: '0px' }}>
                  <div className={styles.newKeyMessage}>
                    {t('new')}
                  </div>
                </div>
                )} */}

            {tags
              ? this.keyIconHandler(tags.find((element) => element === 'guest' || 'services' || 'manager' || 'integration'))
              : <div className={`${propertyView ? 'col-md-2' : 'col-1'} ${styles.icon}`} />}

            <div className={styles.cardSubContainerKeys}>
              { isFullKeyDisplayed === true
                ? (
                  <div className={styles.keyInfoContainer}>
                    <h3 className={styles.guestName}>{name && (name)}</h3>
                    {properties.length === 1
                      ? (
                        <div className={styles.property}>
                          {(getProperty(properties[0]) || {}).name}
                        </div>
                      )
                      : (
                        <div className={styles.property}>
                          {' '}
                          {properties.length}
                          {' '}
                          {t('properties')}
                        </div>
                      )}
                    {properties.length === 1
                      ? (
                        <div className={styles.doors}>
                          {doors && doors.map((doorId, index) => {
                            if (index < 2) {
                              return (
                                <p key={doorId}>
                                  {' '}
                                  {(getDoor(doorId) || {}).name}
                                  {' '}
                                </p>
                              );
                            }
                            if (index === 2) return <p key={doorId} style={{ fontSize: '16px' }}>...</p>;
                            return undefined;
                          })}
                        </div>
                      )
                      : null}
                  </div>
                )
                : (
                  <div className="col">
                    {/* <h3 className={styles.guestName}>{name}</h3> */}
                  </div>
                )}
              <div className={styles.iconChevronDown}>
                { isFullKeyDisplayed === false
                  ? (
                    <ChevronDownIcon
                      className={`${styles.iconChevronDown}`}
                      onClick={() => this.setState({ isFullKeyDisplayed: true })}
                    />
                  )
                  : keyStatus === 'expired'
                    ? (
                      <div className={styles.iconsContent}>
                        <div
                          className="col-4"
                        />
                        <div
                          className={styles.iconDelete}
                          style={{ marginRight: propertyView && '0px' }}
                          onClick={this.deleteKey}
                          onKeyDown={this.deleteKey}
                        />
                        <div
                          onClick={this.canRecoverKey}
                          onKeyDown={this.canRecoverKey}
                          className={styles.iconRecover}
                        />
                      </div>
                    )
                    : (
                      <div className={styles.iconsContent}>
                        <div
                          className={styles.iconSms}
                          style={{ marginRight: propertyView && '0px' }}
                          onClick={() => this.setState({ isModalOpen: true })}
                          onKeyDown={() => this.setState({ isModalOpen: true })}
                        />
                        <Link
                          to={{ pathname: `/edit-key/${id}`, params: { id } }}
                          className={styles.iconEdit}
                        />
                        <CopyToClipboard
                          text={`${process.env.REACT_APP_PUBLIC_URL}/invitation?token=${linkToken}`}
                          onCopy={() => {
                            AlertConfirm
                              .fire({ html: `${t('key.guestInvitationCopied')}<br/><span style="font-size:10px">${process.env.REACT_APP_PUBLIC_URL}/invitation?token=${linkToken}`, icon: 'success' });
                          }}
                          onClick={() => removeNewTag(id)}
                        >
                          <div
                            className={styles.iconShare}
                            style={{ marginRight: propertyView && '0px' }}
                          />

                        </CopyToClipboard>
                      </div>
                    )}
                {/* Copy to clipboard onclick */}
                {isFullKeyDisplayed === true
                  ? (
                    <div
                      className={`${styles.passcodeWrapper}`}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div />
                      <div
                        className={`${styles.passCodeContainer}`}
                        style={{
                          lineHeight: '30px',
                          margin: propertyView && 'auto !important',
                          left: propertyView && '0px',
                        }}
                      >
                        <CopyToClipboard
                          text={numericCode}
                          onCopy={() => {
                            this.setState({ codeCopied: true });
                            setTimeout(() => this.setState({ codeCopied: false }), 500);
                          }}
                        >
                          {/* show code or hide it */}
                          {codeShowing
                            ? (
                              <div className={styles.code}>
                                {' '}
                                *
                                {numericCode}
                              </div>
                            )
                            : <div className={styles.code}> •••••• </div>}
                        </CopyToClipboard>
                        {codeCopied ? <span className="text-secondary">{t('copied')}</span> : null}
                      </div>

                      {/* Toggle eye icon */}
                      {codeShowing
                        ? (
                          <div
                            className={`${styles.iconEyeHide}`}
                            style={{
                              left: propertyView && '0px',
                              width: '25px',
                              // marginLeft: '12px',
                            }}
                            onKeyDown={() => this.toggleShowCode()}
                            onClick={() => this.toggleShowCode()}
                          />
                        )
                        : (
                          <div
                            className={`${styles.iconEyeShow}`}
                            style={{
                              left: propertyView && '0px',
                              width: '25px',
                              // marginLeft: '10px',
                            }}
                            onClick={() => this.toggleShowCode()}
                            onKeyDown={() => this.toggleShowCode()}
                          />
                        )}
                    </div>
                  )
                  : null}
              </div>
            </div>
          </div>

          <br />
          { keyStatus !== 'permanent' ? (
            <>
              <div className={styles.progressBar}>
                {progress < 100
                    && <span style={{ width: `${progress}%` }} />}
              </div>
              <div className="row">
                <div className="col">
                  <div className={styles.checkIn}>
                    {`${timezone

                      ? `${checkIn.format('D MMM YYYY[,] HH[:]mm')} ${timezone}`
                      : checkIn.format('D MMM YYYY[,] HH[:]mm z')
                    }`}
                  </div>
                </div>
                <div className="col">
                  <div className={styles.checkOut}>
                    {`${timezone

                      ? `${checkOut.format('D MMM YYYY[,] HH[:]mm')} ${timezone}`
                      : checkOut.format('D MMM YYYY[,] HH[:]mm z')
                    }`}
                  </div>
                </div>
              </div>
            </>
          )
            : null}
          {isUserPermissionModalDisplayed && (
            <PermissionModal
              click={() => this.setState({ isUserPermissionModalDisplayed: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  search: state.keys.search,
  getProperty: (id) => getPropertyById(state, id),
  getDoor: (id) => getDoorById(state, id),
  canRecoverExpiredKeys: () => state.billing.customer.services.includes('service-keys-recover-expired'),
});

const mapDispatchToProps = (dispatch) => ({
  deleteKey: (id) => dispatch(deleteKey(id)),
  removeNewTag: (id) => dispatch(removeNewTag(id)),
});

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Key)));
