import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

import HostApp from './HostApp';
import GuestInvitation from './GuestInvitation/GuestInvitation';
import Activation from './Activation';

export const App = ({ t }: any) => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route path="/invitation" component={GuestInvitation} />
      <Route path="/box/new" component={HostApp} />
      <Route path="/box" component={Activation} />
      <Route path="/" component={HostApp} />
    </Switch>
    <CookieConsent
      location="bottom"
      buttonText={t('cookies.accept')}
      style={{ background: '#0A1428' }}
      buttonStyle={{ background: '#FF005F', color: '#FFFFFF', fontSize: '13px' }}
      cookieName="cookieConsent"
    >
      <p>{t('cookies.title')}</p>
      <div style={{ height: '5px' }} />
      <p style={{ fontSize: '10px' }}>
        {t('cookies.subtitle')}
      </p>
    </CookieConsent>
  </BrowserRouter>
);

export default withTranslation()(App);
