import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';
import { Keypad } from '../box-activation/box-api';

import { ReactComponent as IconPadPair } from '../../../assets/images/box-activation/icon-pad-pair.svg';

export default () => {
  const history = useHistory();
  const { t } = useTranslation();

  const pairKeypad = () => {
    const doorId = parseInt(window.localStorage.getItem('currentDoor'), 10);

    getBox()
      .then((b) => b.door.get(doorId))
      .then((door) => {
        if (!door) throw new Error('Door ID is incorrect or does not exist.');
        return new Promise((resolve, reject) => {
          door.keypad.addBLEPairing((res) => {
            if (res instanceof Keypad) resolve(res);
          }, reject).catch(reject);
        });
      })
      .then((keypad) => {
        localStorage.setItem('keypadMac', keypad.mac);
      })
      .then(() => history.push({ pathname: '/box/activation/keypad/test' }))
      .catch(
        (err) => {
          history.push({
            pathname: '/box/activation/error',
            state: {
              redirectUrl: '/box/activation/keypad/setup',
              errorMessage: String(err),
            },
          });
        },
      );
  };

  useEffect(() => pairKeypad(), []); // run only once

  return (
    <div className="activation-section" id="door-keypad-setup">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.door.padSetupTitle')}</h1>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <div className="wrapper-icon text-center"><IconPadPair alt="Click on the 1º key of the keypad to pair." style={{ width: '170px' }} /></div>
              <p className="formDescriptionTxt">{t('boxActivation.door.padDescription')}</p>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <Link style={{ display: 'block', textAlign: 'left' }} to='/box/activation/keypad' className="btn btn-link col col-md-6 col-lg-4">{t('boxActivation.activationBack')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
