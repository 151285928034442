import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollLock from 'react-scrolllock';

import style from './Navbar.module.scss';

import { ReactComponent as Exit } from '../../assets/images/solid/exit.svg';
import { ReactComponent as Help } from '../../assets/images/solid/help.svg';
import { ReactComponent as People } from '../../assets/images/solid/people.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/solid/settings.svg';

import { redirectToLogin } from '../../utils/auth';
import Button from '../ReusableComponents/Button';

export class Navbar extends React.Component {
  state = {
    opened: false,
  };

  //---------------------------------------------------------

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeNav();
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  logout = () => redirectToLogin();

  //---------------------------------------------------------

  openNav() {
    this.setState({ opened: true });
  }

  closeNav() {
    this.setState({ opened: false });
  }

  render() {
    const { user, t } = this.props;
    const { opened } = this.state;
    return (
      <div ref={this.setWrapperRef}>
        <ScrollLock isActive={opened} />
        <div style={{ width: '100vw', paddingTop: '0px' }} id="listContainer">
          <nav className={`container ${style.responsiveNavbar}`} style={{ marginBottom: '-1px' }}>
            <div className={`${style.background} ${opened && style.show}`} id="close-nav" onClick={() => this.closeNav()} />
            <div className={`${style.sidenav} ${opened && style.show}`}>
              <div id="close-button" className={style.closebtn} onClick={() => this.closeNav()} />
              <div style={{
                display: 'flex', flexDirection: 'column', height: '100%',
              }}
              >
                <div className={style.topSection}>
                  <h2>
                    {t('hi')}
                    ,
                  </h2>

                  {user.name
                  && (
                  <h2 id="name">
                    {(user.name || {}).first ? `${user.name.first} ${user.name.last}` : user.name}
                  </h2>
                  )}
                  <Link
                    className="text-secondary pl-0 pt-1 pb-1 mb-3"
                    id="settings-first"
                    to="/settings"
                    onClick={() => this.closeNav()}
                  >
                    <SettingsIcon className={style.settingsIcon} />
                    {t('settings')}
                  </Link>
                  <Link id="billing" to="/billing">
                    <Button value={t('updatePlan')} id="billing-button" className={`btn btn-light ${style.upgradeBtn}`} onClick={() => this.closeNav()} />
                  </Link>
                </div>
                <hr className="mt-4 mb-4" />
                <div className={style.centerSection}>
                  <Link id="properties" to="/" onClick={() => this.closeNav()}>{t('properties')}</Link>
                  <Link id="keys" to="/keys" onClick={() => this.closeNav()}>{t('keys')}</Link>
                  <Link id="activities" to="/activities" onClick={() => this.closeNav()}>{t('activities')}</Link>
                  <Link id="synchronisations" to="/synchronisations" onClick={() => this.closeNav()}>{t('synchronisations')}</Link>
                </div>
                <hr className="" />
                <div className={style.bottomSection}>
                  <Link className={style.usersIcon} id="settings-second" to="/manage/host" onClick={() => this.closeNav()}>
                    <People />
                    {t('users.manageUsers')}
                  </Link>
                  <Link className={style.helpIcon} id="help" to="/help" onClick={() => this.closeNav()}>
                    <Help />
                    {t('help.help')}
                  </Link>
                  <div className={style.logoutIcon} id="logout" onClick={() => this.logout()}>
                    <Exit
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '3px',
                      }}
                    />
                    {t('logout')}
                  </div>
                </div>
              </div>
            </div>
            <div id={style.navBarIcon} className="open-nav" onClick={() => this.openNav()} />
          </nav>
        </div>
        <ScrollLock isActive={opened} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Navbar));
