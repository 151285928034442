import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AddButton from '../../assets/images/lined/plus-red.svg';
import EditButton from '../../assets/images/lined/new-red.svg';

import style from './Main.module.scss';

const SyncCard = ({
  id, name, description, link, linkParams, padding, click, calendarQuantity,
}) => (
  <div key={id} className={style.card} style={{ padding }}>
    <div className={style.header}>
      <div className="row">
        <div id="name" className={`${style.bold} ${click ? 'col' : 'col'}`}>
          {name}
        </div>
        {click
          ? (
            <>
              <div
                id="ical-integration"
                onClick={click}
                className="col"
                style={{ textAlign: 'right', cursor: 'pointer' }}
              >
                {calendarQuantity > 0
                  ? <img style={{ height: '20px' }} src={EditButton} alt="edit button" />
                  : <img style={{ height: '20px' }} src={AddButton} alt="add button" />}
              </div>
            </>
          )
          : (
            <>
              <Link to={linkParams ? { pathname: `${link}/${linkParams}`, params: { linkParams } } : link} className="col" style={{ textAlign: 'right' }}>
                <img style={{ height: '20px' }} src={AddButton} alt="add button" />
              </Link>
            </>
          )}
      </div>
      <div style={{ paddingLeft: '0px' }} className={`${style.cardDescription} col-9`}>{description}</div>
    </div>
  </div>
);

SyncCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SyncCard;
