import { Box, Configuration } from './box-api';

const tokenParameter = new URLSearchParams(window.location.search).get('token');

let wsToken: string;
let configuration: Configuration;
let box: Box;

// localstorage has delay problem while writing to disk
if (tokenParameter) {
  wsToken = tokenParameter;
  localStorage.setItem('redirectToken', tokenParameter);
} else {
  wsToken = localStorage.getItem('redirectToken') || '';
}

function getConnection() {
  if (!configuration) {
    configuration = new Configuration('wss://components.homeit.io/box-configuration-ws-proxy/ws',
      wsToken, (err) => alert(`There is a problem with your TOKEN. Please contact support. Details: ${err}`));
  }
  return configuration;
}

function getBox() {
  if (!box) {
    box = getConnection().box();
  }
  return box;
}

export { getConnection, getBox };
