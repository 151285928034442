import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const CardContainer = styled.div`
    border-radius: 20px;
    padding: 24px;
    display: block;
    background: white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
`;
