import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import moment from 'moment';

import Door from '../Properties/Property/Door/Door';

import styles from './GuestDashboard.module.scss';

export default () => {
  const { t } = useTranslation();
  const {
    data: {
      properties,
      doors,
      key = {},
    },
  } = useSelector((state) => state.guestInvitation);

  const isBeforeCheckin = moment().isBefore(key.checkIn);
  const isAfterCheckout = moment().isAfter(key.checkOut);

  useEffect(() => cookies.set('skipGuestTutorial', true, { expires: 5647 }), []); // run only once

  return (
    <>
      {properties && properties.map(({ id: propertyId, name, description }) => (
        <div id="listContainer" className={`${styles.card}`}>
          <div
            className="col-12"
            style={{
              padding: '0px', textDecoration: 'none', color: '#0A1428',
            }}
          >
            <h5 id="property-name" style={{ fontWeight: 700 }}>{name}</h5>
            <div
            id="description-input"
            style={{ marginBottom: '15px', fontSize: '13px', marginTop: '15px' }}
          >
              {description}
            </div>
            <div className="row" style={{ margin: '0px', fontSize: '13px' }}>
              <div id="check-in">
                <strong>Check-in: </strong>
                {moment(key.checkIn).format('LLL')}
              </div>
            </div>
            {key.isExpiring && (
              <div className="row" style={{ margin: '0px', fontSize: '13px' }}>
                <div id="check-in">
                  <strong>Check-out: </strong>
                  {moment(key.checkOut).format('LLL')}
                </div>
              </div>
            )}

            {/* INTRO */}
            {isBeforeCheckin && (
              <div className={`${styles.card}`} style={{ backgroundColor: '#FFB733' }}>
                <p>{t('guestInvitation.beforeCheckin1')}</p>
                <p>{t('guestInvitation.beforeCheckin2')}</p>
              </div>
            )}
            {isAfterCheckout && (
              <div className={`${styles.card}`} style={{ backgroundColor: '#FFB733' }}>
                <p>{t('guestInvitation.keyExpiredTitle')}</p>
                <p>{t('guestInvitation.keyExpiredSubtitle')}</p>
              </div>
            )}

            {/* DOORS */}
            {!isAfterCheckout && (
              <div className={styles.doorContainer}>
                <h6>{t('guestInvitation.openDoors')}</h6>
                {doors && doors.map((door) => {
                  if (door.property === propertyId) {
                    return (
                      <>
                        <Door
                          guestInvitation
                          key={door.id}
                          content={door.name}
                          isSwipeDisabled={isBeforeCheckin}
                          {...door}
                        />
                      </>
                    );
                  }
                  return undefined;
                })}
              </div>
            )}

            {/* KEYPAD CODE */}
            {!isAfterCheckout && (
              <div className={styles.doorContainer}>
                <>
                  <h6>{t('guestInvitation.keypadCode')}</h6>
                  <div
                    className="row"
                    style={{ margin: '0px' }}
                    id="keypad-code"
                  >
                    <span style={{
                      fontWeight: 50,
                      fontSize: '32px',
                      marginTop: '20px',
                      marginLeft: '5px',
                    }}
                    >
                      {/* Some devices (including the box) use the `*` key to reset the code buffer.
                      Also, some keypads only process the code after pressing the `#` button.
                      Because of this, we include these symbols in the code shown to the user. */}
                      {`*${key.numericCode}#`}
                    </span>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
