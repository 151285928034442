import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';

import {
  createCohostInvitation,
  updateCohostInvitation,
  editCohost,
  deleteInvitation,
  deleteCohost,
} from '../../modules/hostInvitation';
import Button from '../ReusableComponents/Button';
import PermissionModal from '../ReusableComponents/PermissionModal/PermissionModal';
import { AlertConfirm, AlertLinkOptions } from '../../utils/alerts';
import { ReactComponent as DeleteIcon } from '../../assets/images/solid/bin.svg';
import {
  HostSettingsContainer,
  HostSettingsHeader,
  CardSettings,
  Required,
  FieldGroup,
  PropertiesTitle,
  PropertiesSection,
  ButtonSection,
} from './styles';

// eslint-disable-next-line complexity
export const CreateEditHost = ({
  t,
  properties,
  canAddCohost,
  history,
  addCohost,
  updateCohost,
  editCohostUser,
  deleteInvitationUser,
  deleteCohostUser,
}) => {
  const { state } = useLocation();
  const [token] = useState(0);
  const [enabledProperties, setEnabledProperties] = useState(state
    ? (state.enabledProperties.map((prp) => prp.id || prp)) : []);
  const [email, setEmail] = useState(state ? (state.email.address || state.email) : '');
  const [name, setName] = useState(state ? state.name : '');
  const [role] = useState(state ? (state.organizationRole || 'admin') : 'member');
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []); // run only once

  const propertySelectHandler = (addProperty, propertyId) => {
    if (addProperty) {
      setEnabledProperties([
        ...enabledProperties,
        propertyId,
      ]);
    } else {
      setEnabledProperties(enabledProperties.filter((property) => property !== propertyId));
    }
  };

  const allPropertySelectHandler = (addAllProperties) => {
    if (addAllProperties) {
      setEnabledProperties(properties.map((property) => property.id));
    } else {
      setEnabledProperties([]);
    }
  };

  const cohostCreateEdit = async () => {
    if (state) {
      if (state.isInvitation) {
        const tokenInvitation = state.token;
        try {
          await updateCohost({
            name,
            enabledProperties,
            role,
            tokenInvitation,
          });
          return history.push({ pathname: '/manage/host' });
        } catch (err) {
          // console.log('Error save cohost --> ', err);
          return AlertConfirm.fire({
            icon: 'error',
            text: t('users.errorEditCohost'),
          });
        }
      } else {
        try {
          await editCohostUser({
            id: state.id,
            enabledProperties,
            role,
          });
          // force reaload users and proprieties on /manage/host
          return document.location.href = '/manage/host';
          // history.push({ pathname: '/manage/host' });
        } catch (err) {
          // console.log('Error save cohost --> ', err);
          return AlertConfirm.fire({
            icon: 'error',
            text: t('users.errorEditCohost'),
          });
        }
      }
    } else if (!canAddCohost()) {
      setShowPermissionsModal(true);
    } else {
      if (enabledProperties.length === 0) {
        return AlertConfirm.fire({
          icon: 'warning',
          text: t('users.selectOneProperty'),
        });
      }
      try {
        await addCohost({
          name,
          email,
          enabledProperties,
          token,
          role,
        });
        await AlertConfirm.fire({
          icon: 'success',
          text: `${t('users.inviteSent1')} ${name} ${t('users.inviteSent2')}`,
        });
        return history.push({ pathname: '/manage/host' });
      } catch (err) {
        // console.log('Error sending invitation --> ', err);
        return AlertConfirm.fire({
          icon: 'error',
          text: t('users.errorInvitation'),
        });
      }
    }
    return null;
  };

  const cohostDelete = async () => {
    if (state.isInvitation) {
      try {
        await deleteInvitationUser(state.token).then(() => history.push({ pathname: '/manage/host' }));
      } catch (err) {
        // console.log('Error delete user --> ', err);
        return AlertConfirm.fire({
          icon: 'error',
          text: t('users.errorDeleteCohost'),
        });
      }
    } else {
      try {
        await deleteCohostUser(state.id).then(() => window.location = '/manage/host');
      } catch (err) {
        // console.log('Error delete user --> ', err);
        return AlertConfirm.fire({
          icon: 'error',
          text: t('users.errorDeleteCohost'),
        });
      }
    }
    return undefined;
  };

  const alertNotPremium = () => {
    AlertLinkOptions.fire({
      icon: 'warning',
      text: t('users.premiumWarning'),
      cancelButtonText: t('cancel'),
      confirmButtonText: t('updatePlan'),
    }).then(async (result) => {
      if (result.value) {
        history.push({ pathname: '/billing' });
      }
    });
  };

  return (
    <div className="fullPageBackground">
      {showPermissionsModal && <PermissionModal click={() => setShowPermissionsModal(false)} />}
      <HostSettingsContainer className="container medium-container">
        <HostSettingsHeader>
          {state ? (
            <>
              <h1>
                {t('users.editUser')}
              </h1>
              <button
                type="button"
                onClick={() => (state.isPremium ? cohostDelete() : alertNotPremium())}
              >
                <DeleteIcon />
                <span>{t('delete')}</span>
              </button>
            </>
          ) : (
            <h1>
              {t('users.addUser')}
            </h1>
          )}
        </HostSettingsHeader>
        <CardSettings
          onSubmit={(e) => { e.preventDefault(); cohostCreateEdit(); }}
        >
          <div>
            <FieldGroup>
              <label htmlFor="user-name">
                {t('name')}
                {' '}
                {!state && <Required>*</Required>}
              </label>
              <input
                id="user-name"
                type="text"
                className="form-control"
                required
                disabled={state}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                placeholder=""
              />
            </FieldGroup>
            <FieldGroup>
              <label htmlFor="user-email">
                {t('email')}
                {' '}
                {!state && <Required>*</Required>}
              </label>
              <input
                id="user-email"
                type="email"
                disabled={state}
                required
                className="form-control"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
              />
            </FieldGroup>
            <PropertiesTitle>
              {t('properties')}
            </PropertiesTitle>
            <PropertiesSection>
              <li>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => allPropertySelectHandler(e.target.checked)}
                  />
                  <span className="checkbox-custom" />
                </label>
                <p>{t('users.allProperties')}</p>
              </li>
              {properties.map((property) => (
                <li key={property.id}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value={property.id}
                      defaultChecked={enabledProperties.includes(property.id)}
                      checked={enabledProperties.includes(property.id)}
                      onChange={(e) => propertySelectHandler(e.target.checked,
                        parseInt(e.target.value, 2))}
                    />
                    <span className="checkbox-custom" />
                  </label>
                  <p>{property.name}</p>
                </li>
              ))}
            </PropertiesSection>

            <ButtonSection>
              <button
                onClick={() => history.push('/manage/host')}
                className="btn btn-cancel"
                type="button"
              >
                {t('cancel')}
              </button>
              {state ? (
                <>
                  { state.isPremium ? (
                    <Button
                      className="btn btn-secondary"
                      value={t('save')}
                      type="submit"
                    />
                  ) : (
                    <Button
                      className="btn btn-secondary"
                      value={t('save')}
                      onClick={(e) => { e.preventDefault(); alertNotPremium(); }}
                    />
                  )}
                </>
              ) : (
                <Button
                  className="btn btn-secondary"
                  value={t('users.add')}
                  type="submit"
                  disabled={!name || !email || !role || !enabledProperties.length}
                />
              )}
            </ButtonSection>
          </div>
        </CardSettings>
      </HostSettingsContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  properties: state.properties.properties,
  canAddCohost: () => state.billing.customer.services.includes('service-organization-add-users'),
});

const mapDispatchToProps = (dispatch) => ({
  addCohost: (data) => dispatch(createCohostInvitation(data)),
  updateCohost: (data) => dispatch(updateCohostInvitation(data)),
  editCohostUser: (data) => dispatch(editCohost(data)),
  deleteInvitationUser: (userId) => dispatch(deleteInvitation(userId)),
  deleteCohostUser: (userId) => dispatch(deleteCohost(userId)),
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEditHost)),
);
