import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Popup from 'reactjs-popup';

import PropertyList from './List/List';
import { ReactComponent as SearchIcon } from '../../assets/images/lined/search.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/lined/filter.svg';
import { filterProperties } from '../../modules/properties';
import style from './Main.module.scss';
import 'reactjs-popup/dist/index.css';

export const Home = ({
  properties, t, searchProperties, resetSearchProperties,
}) => {
  const defaultStateFilters = {
    location: [],
  };
  const [openFilters, setOpenFilters] = useState(false);
  const [keysToShow, setKeysToShow] = useState([...properties]);
  const [filtersState, setFiltersState] = useState(defaultStateFilters);
  const [filtersUsed, setFiltersUsed] = useState(defaultStateFilters);

  const applyFilters = (changeState) => {
    const filtersStateClone = changeState || filtersState;
    const result = [];

    if (changeState) {
      setFiltersState(changeState);
    }

    filtersStateClone.location.forEach((local) => {
      result.push(...properties.filter((item) => (item.address ? (item.address.city || item.address.area) : '') === local));
    });

    // save filters userd
    setFiltersUsed(changeState || {
      ...filtersState,
    });

    // apply filters to keys
    if (filtersStateClone.location.length > 0) {
      setKeysToShow(result);
    } else {
      setKeysToShow([...properties]);
    }

    // closepopup
    setOpenFilters(false);
  };

  const locationsProperties = () => [...new Set(properties.map((item) => (item.address ? (item.address.city || item.address.area) : '')))].filter((v) => v !== '');

  const locationsHandler = (add, value) => {
    if (add) {
      setFiltersState({
        location: filtersState.location.length > 0 ? [...filtersState.location, value] : [value],
      });
    } else {
      const keysTagsState = filtersState.location.filter((item) => item !== value);
      setFiltersState({
        location: keysTagsState,
      });
    }
  };

  const closeModal = () => {
    document.body.style.overflow = 'initial';
    setOpenFilters(false);
    setFiltersState({
      ...filtersUsed,
    });
  };

  const resetFilters = () => {
    setFiltersState({
      ...defaultStateFilters,
    });
    setFiltersUsed({
      ...defaultStateFilters,
    });
    setKeysToShow([...properties]);
    closeModal();
  };

  const removeFilter = (value) => {
    const arrayTypesCopy = [...filtersState.location];
    arrayTypesCopy.splice(arrayTypesCopy.indexOf(value), 1);
    return applyFilters({
      location: arrayTypesCopy,
    });
  };
  useEffect(() => {
    resetSearchProperties();
    setFiltersState({
      ...filtersUsed,
    });
  }, []); // run only once

  return (
    <div id="listContainerBig" className="container mb-5">
      <div>
        <h1>{t('properties')}</h1>
        <p className="headerDescription" id="properties-length">

          {properties.length}
          {' '}
          {properties.length !== 1 ? t('property.manage') : t('property.singleManage')}
        </p>
        <form className="row d-flex justify-content-between mt-3">
          <div className="form-group has-feedback has-feedback-left col-10 col-md-6 col-sm-11">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              className="form-control form-control-search input-lg"
              placeholder={t('search')}
              onChange={(e) => searchProperties(e.target.value)}
            />
          </div>
          <div className={`${style.btnFilters} col-2 col-md-2 col-sm-1`}>
            <button type="button" className="btn btn-light w-100" onClick={() => { setOpenFilters(true); document.body.style.overflow = 'hidden'; }}>{t('filter.filters')}</button>
            <button type="button" className="btn" onClick={() => { setOpenFilters(true); document.body.style.overflow = 'hidden'; }}>
              <FilterIcon />
            </button>
          </div>
        </form>
      </div>
      {filtersUsed.location.length > 0 && (
        <div className="row">
          <ul className={`col-md-12 ${style.filtersApplied}`}>
            {locationsProperties().map((item) => (
              <>
                {filtersUsed.location.includes(item) && (
                  <li className="btn btn-light" key={item}>
                    {item}
                    <button type="button" onClick={() => removeFilter(item)}>&times;</button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      )}
      <Popup open={openFilters} closeOnDocumentClick modal nested onClose={() => closeModal()}>
        <div>
          <header>
            <h3>{t('filter.filters')}</h3>
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}
              onKeyDown={() => closeModal()}
            >
              &times;
            </button>
          </header>
          <div>
            <ul className={style.filtersHeader}>
              <li className={style.filtersHeaderListActive}>
                {t('filter.location')}
              </li>
            </ul>
            <div className="selection-container">
              {locationsProperties().map((item) => (
                <div key={item} className="mt-4 checkbox-grid">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value={item}
                      checked={filtersState.location.includes(item)}
                      onChange={(e) => locationsHandler(e.target.checked, e.target.value)}
                    />
                    <span className="checkbox-custom" />
                  </label>
                  <p className="checkbox-text">{item}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <button
              type="button"
              className="btn btn-cancel btn-block bw-40 mr-2"
              onClick={() => resetFilters()}
            >
              {t('filter.clean')}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-block bw-40 mt-0"
              onClick={() => applyFilters()}
            >
              {t('filter.filter')}
            </button>
          </div>
        </div>
      </Popup>
      <PropertyList properties={keysToShow} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  properties: state.properties.properties,
});

const mapDispatchToProps = (dispatch) => ({
  resetSearchProperties: () => dispatch(filterProperties('')),
  searchProperties: (filter) => dispatch(filterProperties(filter)),

});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
