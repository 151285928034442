import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal/Modal';

import styles from './KeyLimitModal.module.scss';

export default ({ click }) => {
  const { t } = useTranslation();

  return (
    <Modal click={click} height="auto" paddingBottom="24px">
      <>
        <div className={styles.permissionContainer}>
          <h2 className="col-12">{t('keyLimitModal.title')}</h2>
          <p className={`${styles.permissionSubtitle} col-12`}>
            {t('keyLimitModal.subtitle')}
          </p>
        </div>
        <div className={styles.permissionButtonContainer}>
          <button
            className={`${styles.permissionButton} btn btn-secondary`}
            type="button"
            id="billing-button"
            onClick={() => window.open('https://homeit.io/checkout/?add-to-cart=18686&quantity=1')}
          >
            {t('keyLimitModal.order')}
          </button>
        </div>
      </>
    </Modal>
  );
};
