import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';

import styles from './Door.module.scss';

import { ReactComponent as DoorOpen } from '../../../../assets/images/lined/ok.svg'; // lock-alt-open.svg
// import { ReactComponent as DoorClose } from '../../../../assets/images/lined/lock-alt.svg';
import { ReactComponent as DoorCancel } from '../../../../assets/images/lined/close.svg';

import { ensureAuth, getAuthToken } from '../../../../utils/auth';
import DoorUnlockLoader from './DoorUnlockLoader/DoorUnlockLoader';
import { getDoorsByProperty } from '../../../../modules/doors';
import { getBoxById } from '../../../../modules/boxes';
import { getActivities } from '../../../../modules/activities';

export class Door extends React.Component {
  state = {
    value: '0',
    content: this.props.content,
    disabled: this.props.isSwipeDisabled || false,
    preConfirm: false,
    id: this.props.id,
    isSliderButtonVisible: '',
    isDoorUnlockedMessageDisplayed: false,
    isWaitLoaderDisplayed: false,
  };

  touchHandler = () => {
    const { value } = this.state;
    if (value > 950) {
      this.setState({
        disabled: true,
        preConfirm: true,
        isSliderButtonVisible: styles.doorButtonHide,
      });
    } else {
      this.resetSlider();
    }
  };

  resetSlider = () => {
    const {
      content,
      isSwipeDisabled = false,
    } = this.props;

    if (!isSwipeDisabled) {
      this.setState({
        content,
        disabled: false,
        preConfirm: false,
        isSliderButtonVisible: '',
        value: '0',
        isWaitLoaderDisplayed: false,
      });
    }
  };

  unlock = async () => {
    const {
      guestInvitation,
      content,
      refreshActivities,
      t,
    } = this.props;

    const {
      id: door,
    } = this.state;

    this.setState({
      preConfirm: false,
      isSliderButtonVisible: '',
      content: t('pleaseWait') || 'Please Wait...',
      isWaitLoaderDisplayed: true,
    });

    if (guestInvitation) {
      await axios.post('https://components.homeit.io/invitations/guest/open', {
        token: Cookies.get('guestInvitationToken'),
        door,
      });
    } else {
      await ensureAuth();
      await axios.post('https://components.homeit.io/doorkeeper/open', { door }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });
      await refreshActivities();
    }
    return this.setState({
      content,
      isDoorUnlockedMessageDisplayed: false,
      disabled: false,
      value: '0',
      isWaitLoaderDisplayed: false,
    });
  };

  lock = async () => {
    const {
      guestInvitation,
      content,
      refreshActivities,
      t,
    } = this.props;

    const {
      id: door,
    } = this.state;

    this.setState({
      preConfirm: false,
      isSliderButtonVisible: '',
      content: t('pleaseWait') || 'Please Wait...',
      isWaitLoaderDisplayed: true,
    });

    if (guestInvitation) {
      await axios.post('https://components.homeit.io/invitations/guest/close', {
        token: Cookies.get('guestInvitationToken'),
        door,
      });
    } else {
      await ensureAuth();
      await axios.post('https://components.homeit.io/doorkeeper/close', { door }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });
      await refreshActivities();
    }
    return this.setState({
      content,
      isDoorUnlockedMessageDisplayed: false,
      disabled: false,
      value: '0',
      isWaitLoaderDisplayed: false,
    });
  };

  cancel = async () => this.resetSlider();

  render() {
    const {
      guestInvitation,
      getBox,
      box,
      t,
    } = this.props;

    const {
      value,
      content,
      isDoorUnlockedMessageDisplayed,
      isSliderButtonVisible,
      disabled,
      preConfirm,
      isWaitLoaderDisplayed,
    } = this.state;

    return (
      <div
        className={styles.doorContainer}
        data-content={value < 1 ? content : null}
      >
        {/* TODO: the below way of disabling the open door slider is a bit messy... */}
        <input
          id={isDoorUnlockedMessageDisplayed ? styles.doorUnlockMessage : ''}
          className={value > 0
            ? `${styles.doorUnlock} ${isSliderButtonVisible}`
            : styles.door}
          type="range"
          max="1000"
          min="0"
          step="1"
          disabled={guestInvitation ? disabled : !(box && (getBox(box) || {}).isOnline)}
          value={value}
          onChange={(el) => this.setState({ value: `${el.target.value}` })}
          onMouseUp={(e) => this.touchHandler(e)}
          onTouchEnd={this.touchHandler}
          style={{
            background: `linear-gradient(to right, rgba(216, 241, 236, 0.5) 0%,rgba(216, 241, 236, 0.5) ${value / 10 - 20}%,rgb(223, 224, 227) ${value / 10}%)`,
          }}
        />
        {preConfirm
          && (
          <div
            className="row"
            id="pre-confirm-div"
            style={{
              position: 'absolute',
              zIndex: 10,
              top: '12px',
              width: '100%',
              marginLeft: '0px',
            }}
          >
            {/* the label overlaps the left-most button if there are three buttons */}
            <div style={{ lineHeight: '45px', color: '#00B7A0', paddingLeft: '20px' }}>
              {t('property.unlock')}
              ?
            </div>
            <div style={{ position: 'absolute', right: '8px' }}>
              <DoorOpen id="door-open" onClick={() => this.unlock()} className={styles.doorConfirm} />
              {/* <DoorClose id="door-close" onClick={() => this.lock()} className={styles.doorConfirm} /> */}
              <DoorCancel id="door-cancel" onClick={() => this.cancel()} className={styles.doorCancel} />
            </div>
          </div>
          )}
        {isWaitLoaderDisplayed
          && (
          <div
            className="row"
            id="wait-loader-div"
            style={{
              position: 'absolute',
              zIndex: 10,
              top: '12px',
              width: '100%',
              marginLeft: '0px',
            }}
          >
            <div className={styles.doorWaitMessage}>{content}</div>
            <div style={{
              position: 'absolute', right: '8px', width: '47px', height: '47px',
            }}
            >
              <div className={styles.doorUnlockWait} />
              <DoorUnlockLoader />
            </div>
          </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  getDoors: () => getDoorsByProperty(state, parseInt(props.match.params.id, 10)),
  getBox: (id) => getBoxById(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  refreshActivities: () => dispatch(getActivities()),
});

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Door)));
