import React from 'react';
import { withTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import moment from 'moment';

const DateInputs = ({
  date, dateChangeHandler, t, isFilter,
}) => (
  <div className="form-group">
    <div className="form-row">
      <div className="col">
        <label>{isFilter ? t('filter.from') : t('checkInDate')}</label>
        <input
          type="text"
          className="form-control"
          value={moment(date[0]).format('DD/MM/YYYY')}
          onChange={dateChangeHandler}
          disabled
        />
      </div>
      <div className="col">
        <label>{isFilter ? t('filter.to') : t('checkOutDate')}</label>
        <input
          type="text"
          className="form-control"
          value={moment(date[1]).format('DD/MM/YYYY')}
          onChange={dateChangeHandler}
          disabled
        />
      </div>
    </div>
  </div>
);

const SelectHours = () => {
  const time = [];
  for (let i = 0; i < 24; i += 1) {
    if (i < 10) {
      time.push(<option key={i} value={`0${i}`}>{`0${i}:00`}</option>);
    } else {
      time.push(<option key={i} value={i}>{`${i}:00`}</option>);
    }
  }
  return time;
};

const HourInputs = ({
  checkInHourChangeHandler, checkOutHourChangeHandler, checkInHour, checkOutHour, t,
}) => (
  <div className="form-row mt-4">
    <div className="col-6">
      <label>{t('checkInTime')}</label>
      <select
        className="form-control"
        value={checkInHour}
        onChange={(e) => checkInHourChangeHandler(e.target.value)}
      >
        <SelectHours />
      </select>
    </div>
    <div className="col-6">
      <label>{t('checkOutTime')}</label>
      <select
        className="form-control"
        onChange={(e) => checkOutHourChangeHandler(e.target.value)}
        value={checkOutHour}
      >
        <SelectHours />
      </select>
    </div>
  </div>
);

const CalendarView = ({
  // eslint-disable-next-line max-len
  date, dateChangeHandler, t, props, checkInHourChangeHandler, checkOutHourChangeHandler, checkInHour, checkOutHour, isFilter,
}) => (
  <>
    <DateInputs
      date={date}
      isFilter
      dateChangeHandler={dateChangeHandler}
      t={t}
    />
    <Calendar
      selectRange
      onChange={dateChangeHandler}
      value={date}
      maxDate={isFilter ? new Date() : new Date(2038, 0, 1)}
      minDate={isFilter ? new Date(2016, 0, 1) : new Date()}
    />
    {!isFilter && (
    <HourInputs
      {...props}
      t={t}
      checkInHourChangeHandler={checkInHourChangeHandler}
      checkOutHourChangeHandler={checkOutHourChangeHandler}
      checkInHour={checkInHour}
      checkOutHour={checkOutHour}
    />
    )}
  </>
);

export default withTranslation()(CalendarView);
