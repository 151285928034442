import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';
import WaitingModal from '../WaitingModal';

export default () => {
  const [doorName, setDoorName] = useState('Street Door');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const saveIntercom = () => {
    setModalOpen(true);
    setSaveButtonDisabled(true);
    let currentDoor;
    return Promise.resolve(currentDoor)
      .then((d) => d || getBox().then((b) => b.door.get(parseInt(window.localStorage.getItem('currentDoor'), 10))))
      .then((door) => {
        currentDoor = door;
        return door.save(doorName);
      })

      .then(() => {
        history.push({ pathname: '/box/activation/intercom/success' });
      })
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/door/street/save',
            errorMessage: String(err),
          },
        });
      });
  };

  return (
    <div className="activation-section" id="intercom-name">
      {modalOpen
        ? <WaitingModal />
        : null}
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.intercom.nameTitle')}</h1>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <form>
              <div className="row formName">
                <div className="col col-md-8 col-lg-8">
                  <label htmlFor="doorName"><strong>{t('boxActivation.intercom.formTitle')}</strong></label>
                  <input className="form-control" onChange={(e) => setDoorName(e.target.value)} id="doorName" type="text" placeholder="Street Door" defaultValue="Street Door" />
                  <p className="formDescriptionTxt">{t('boxActivation.intercom.formDescription')}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4 text-left" />
            <div className="col col-md-3 col-lg-2 text-center">
              <button
                className="btn btn-block btn-outline-primary"
                type="button"
                disabled={saveButtonDisabled}
                id="intercom-save"
                onClick={() => saveIntercom()}
              >
                {t('boxActivation.buttonNext')}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
