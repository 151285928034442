import React from 'react';
import { withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Plans from './Plans';

const Billing = (props) => {
  const { t } = props;
  return (
    <div>
      <div id="listContainer" className="container">
        <div className="row">
          <div className="col">
            <h1>{t('updatePlan')}</h1>
          </div>
        </div>
      </div>
      <Plans />
    </div>
  );
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = (/* dispatch */) => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Billing));
