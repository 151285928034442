import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getBox } from './box-activation/boxConnection';

import styles from './activation.scss';

class BoxActivationStart extends React.Component {
  constructor() {
    super();
    this.state = {
      isButtonDisplayed: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    getBox().then((box) => {
      localStorage.setItem('macAddress', box.mac);
      this.setState({ isButtonDisplayed: true });
      // const redirectToken = localStorage.getItem('redirectToken');
      // // eslint-disable-next-line no-undef
      // amplitude.getInstance().logEvent('box-activation start', { 'Box Mac Address': box.mac, 'Redirect Token': redirectToken });
    })
      .catch(() => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/start',
            errorMessage: 'Check the connection of your box to the internet',
          },
        });
      });
  }

  componentWillUnmount() {
    this.setState({ isButtonDisplayed: false });
  }

  render() {
    const { t } = this.props;
    const { isButtonDisplayed } = this.state;
    return (
      <div className="container wrapper--page">
        <div className="row justify-content-md-center text-center">
          <div className="col col-md-6">
            <h1>{t('boxActivation.start.activationTitle')}</h1>
          </div>
        </div>
        <div className="row justify-content-md-center text-center">
          <div className="col col-md-6">
            <h5>{t('boxActivation.start.activationSubtitleAlternative')}</h5>
            {isButtonDisplayed
              ? null
              : (
                <>
                  <div id="loader" className="loader" />
                  <h5>{t('boxActivation.start.connectionWait')}</h5>
                </>
              )}
          </div>
        </div>
        <div className={styles.jumbotron}>
          <div className="row justify-content-md-center" id="selector">
            <div className="col col-md-6 text-center">
              {isButtonDisplayed
                ? <Link className="btn btn-primary" to="/box/activation/intercom/start" id="start-button">{t('boxActivation.start.fitterButton')}</Link>
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BoxActivationStart);
