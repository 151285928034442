import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Button from '../../../ReusableComponents/Button';

import styles from './Main.module.scss';

import {
  getDoorById,
  editDoor,
} from '../../../../modules/doors';

export class EditDoor extends Component {
  state = {
    name: '',
    description: '',
  };

  componentDidMount() {
    const { getDoor } = this.props;
    const door = getDoor();
    this.setState(door);
  }

  doorSave = async (e) => {
    const { id, name, description } = this.state;
    const { editDoor: edit } = this.props;
    e.preventDefault();
    return edit({ name, description }, id)
      .then(() => { window.location = '/'; });
  };

  render() {
    const {
      id,
      name,
      description,
    } = this.state;
    const { t, history } = this.props;
    return (
      <div className="fullPageBackground">
        <div className={`${styles.titlePaddingTop} container medium-container pb-5`}>
          <h1 id="name" className="mb-4">
            {t('edit')}
            {' '}
            {name}
          </h1>
          <form className={`${styles.card}`} onSubmit={this.doorSave}>
            <div>
              <label className="mb-2 mt-1" htmlFor>
                {t('door.name')}
                {' '}
                <span className={styles.required}>*</span>
              </label>
              <input
                id="name-input"
                className="col form-control"
                type="text"
                value={name}
                required
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            {/* description area */}
            <div>
              <label className="mb-2 mt-3" htmlFor>{t('door.guestInstructions')}</label>
              <textarea
                className="form-control"
                placeholder={t('door.writeInstructions')}
                value={description}
                style={{ minHeight: '130px' }}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </div>
            <p className="mt-4 mb-5">
              <span className={styles.required}>*</span>
              {' '}
              {t('door.requiredFields')}
            </p>
            <div className="row mt-4" style={{ maxWidth: '439px', margin: 'auto' }}>
              <div className="col-6">
                <button
                  id="cancel-button"
                  onClick={() => history.push(`/door/${id}`)}
                  className="btn btn-cancel btn-block"
                  type="button"
                >
                  {t('cancel')}
                </button>
              </div>
              <div className="col-6">
                <Button
                  className="btn btn-secondary btn-block"
                  type="submit"
                  value={t('save')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  doors: state.doors.doors,
  getDoor: () => getDoorById(state, parseInt(props.match.params.id, 10)),
});

const mapDispatchToProps = (dispatch) => ({
  editDoor: (data, id) => dispatch(editDoor(data, id)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditDoor));
