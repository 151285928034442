import React from 'react';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { ReactComponent as IconComplete } from '../../assets/images/box-activation/complete.svg';

class CodeSuccess extends React.Component {
  state = {
    activationCode: '',
  };

  componentDidMount() {
    const { location, history } = this.props;
    const { activationCode: code } = this.state;
    const activationCode = Cookies.get('boxActivationToken');
    this.setState({ activationCode }, () => {
      if (!location.state && !code) {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/code',
            errorMessage: 'No token was generated',
          },
        });
      }
    });
  }

  render() {
    const { t, location } = this.props;
    const { activationCode } = this.state;
    const { email } = location.state || {};
    return (
      <div className="activation-section" id="door-success">
        <div className="container wrapper--page">
          <div className="row justify-content-md-center">
            <div className="col col-md-8 col-lg-6">
              <div className="wrapper-icon"><IconComplete alt="smartlock paired successfuly" style={{ width: '144px' }} /></div>
              <h1 className="code-success-title">{t('boxActivation.code.installationConcluded')}</h1>
              {email === undefined
                ? null
                : (
                  <h4 className="code-success-subtitle">
                    {t('boxActivation.code.emailSent')}
                    {' '}
                    <span className="code-success-subtitle-green">{email}</span>
                  </h4>
                )}
              <br />
              <div className="alert alert-warning">
                {email === undefined
                  ? <p style={{ textAlign: 'center' }}>{t('boxActivation.code.tokenNoEmailSaveMessage')}</p>
                  : <p style={{ textAlign: 'center' }}>{t('boxActivation.code.tokenSaveMessage')}</p>}
                <div style={{ padding: '24px' }}>
                  <p style={{ textAlign: 'center', fontSize: '40px' }}>{activationCode}</p>
                </div>
                <p style={{ textAlign: 'center' }}>{t('boxActivation.code.closeWindowMessage')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CodeSuccess);
