import React from 'react';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import Card from '../ReusableComponents/Card/Card';

class BoxPropertyStart extends React.Component {
  state = {
    activationCode: '',
  };

  componentDidMount() {
    // Check if the boxActivationToken is in the query of the url
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const tokenQueryCheck = params.get('token');
    if (tokenQueryCheck) {
      Cookies.set('boxActivationToken', tokenQueryCheck, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN });
      this.props.history.push('/box/new/property');
    }

    // Check if the boxActivationToken is in the cookies
    const tokenCookieCheck = Cookies.get('boxActivationToken');
    if (tokenCookieCheck) this.props.history.push('/box/new/property');
  }

  saveToken = () => {
    Cookies.set('boxActivationToken', this.state.activationCode, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN });
    this.props.history.push('/box/new/property');
  };

  render() {
    const { t } = this.props;
    return (
      <div className="container container--padding  wrapper--page" style={{ maxWidth: '677px' }} id="listContainer">
        <h1 className="center title mb-4">{t('box.new.activateBox')}</h1>
        <Card>
          <div className="row top-20">
            <div className="twelve columns" style={{ width: '94%', margin: 'auto' }}>
              <label style={{ display: 'block', marginBottom: '.5rem', fontWeight: '600' }}>{ t('box.new.codeLabel')}</label>
              <input
                className="form-control"
                type="text"
                placeholder="Activation Code"
                id="activation_code"
                onChange={(e) => this.setState({ activationCode: e.target.value.toUpperCase() })}
              />
              <p style={{ marginTop: '14px' }}>
                {t('box.new.codeTxt')}
                <a href="https://homeit.freshdesk.com/support/tickets/new">{t('box.new.contactUs')}</a>
              </p>
            </div>
          </div>
          <div className="row" style={{ justifyContent: 'flex-end', margin: '0' }}>
            <input
              style={{
                color: '#FFF', marginTop: '50px', width: '120px',
              }}
              className="btn-secondary btn btn--save"
              id="btn-saveToken"
              type="submit"
              value={t('save')}
              onClick={this.saveToken}
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(BoxPropertyStart);
