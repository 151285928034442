import React from 'react';

import { ReactComponent as RedBackgroundIcon } from '../../../assets/images/illustration/loadingBackground.svg';
import style from './Spinner.module.scss';

const FullPageSpinner = () => (
  <div className={style.SpinnerFullPageContainer}>
    <div className={style.SpinnerAbsoluteContainer}>
      <div style={{ width: '78px', margin: 'auto', position: 'relative' }}>
        <RedBackgroundIcon style={{ width: '78px', height: '78px', position: 'absolute' }} />
        <div className={style.ldsSpinner}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className="text-center">Loading...</div>
      <div className="text-center mt-3">Please wait.</div>
    </div>
  </div>
);

export default FullPageSpinner;
