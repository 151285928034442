import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { AllActivitiesDisplayed } from './styles';

import Loader from '../../ReusableComponents/Spinner/Spinner';
import Activity from '../Activity';
import NoItemsMessage from '../../ReusableComponents/NoItemsMessage/NoItemsMessage';
import Card from '../../ReusableComponents/Card/Card';
import PermissionModal from '../../ReusableComponents/PermissionModal/PermissionModal';

import { ReactComponent as ActivitiesIcon } from '../../../assets/images/illustration/activity.svg';

export const ActivitiesList = ({
  location, activities, canGetAllActivities, t, total, isLoading,
  showPermissionsModal, clickShowPermissionsModal,
}) => {
  const renderActivities = () => {
    // no activities at all
    if (!activities) {
      return (
        <NoItemsMessage
          heading={t('noActivities.atAll.heading')}
          subheading={t('noActivities.atAll.subheading')}
          id="no-activities"
        >
          <ActivitiesIcon style={{ width: '78px', height: '78px' }} />
        </NoItemsMessage>
      );
    }

    if (!canGetAllActivities()) {
      const act = activities.filter((activity) => moment().diff(activity.dateCreated, 'hours') <= 48);
      if (act.length === 0) {
        return (
          <NoItemsMessage
            heading={t('noActivities.noAddon.heading')}
            subheading={t('noActivities.noAddon.subheading')}
            id="no-activities"
          >
            <ActivitiesIcon style={{ width: '78px', height: '78px' }} />
          </NoItemsMessage>
        );
      }
    }

    return (
      <Card>
        {activities.map((activity, i) => (
          <Activity
            {...activity}
            activityKey={activity.key}
            key={activity.id}
            lastChild={activities.length === i + 1}
          />
        ))}
        {(isLoading) ? (
          <div
            style={{ marginTop: '20px' }}
          >
            <Loader
              key={0}
              id="loader"
              width="88px"
            />
          </div>
        ) : (
          <AllActivitiesDisplayed>
            {t('noMoreResults')}
          </AllActivitiesDisplayed>
        )}
      </Card>
    );
  };

  return (
    <div style={{ marginTop: '36px' }} id="activities-container">
      {renderActivities()}
      {showPermissionsModal && (
        <PermissionModal
          click={() => clickShowPermissionsModal()}
        />
      )}
    </div>
  );
};

export default withTranslation()(ActivitiesList);
