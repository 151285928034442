import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Popup from 'reactjs-popup';

import List from '../List/index';
import CalendarView from '../../Calendar/Calendar';
import 'reactjs-popup/dist/index.css';
import { filterActivities, getActivities, filterActivitiesbyFilters } from '../../../modules/activities';
import {
  BtnRefresh, MainHeader, FormSearch, BtnFilters, FiltersHeader, FiltersApplied,
} from './styles';
import FullPageSpinner from '../../ReusableComponents/Spinner/FullPageSpinner';
import { ReactComponent as RefreshIcon } from '../../../assets/images/lined/rotate.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/lined/search.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/lined/filter.svg';

// eslint-disable-next-line complexity
export const Activities = ({
  resetSearchActivities, searchActivities, activities, t, total, getAllActivities, skipValue: skip,
  canGetAllActivities, location, keys, properties, doors, findActivitiesbyFilters,
}) => {
  const [spinnerPage, setSpinnerPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(0);
  // filters
  const defaultStateFilters = {
    start: null,
    end: null,
    properties: [],
    keysTypes: [],
    keysTags: [],
  };
  const defaultStartDate = moment().subtract(7, 'days');
  const defaultEndDate = moment().add(2, 'minutes');
  const [activitiesToShow, setActivitiesToShow] = useState([...activities]);
  const [openFilters, setOpenFilters] = useState(false);
  const [activeTab, setActiveTab] = useState('properties');
  const [filtersState, setFiltersState] = useState({
    ...defaultStateFilters,
    properties: [location.state && location.state.propertyId ? location.state.propertyId : ''],
  });
  const [filtersUsed, setFiltersUsed] = useState({
    ...defaultStateFilters,
    properties: [location.state && location.state.propertyId ? location.state.propertyId : ''],
  });

  const handleScroll = () => {
    if (
      Math.ceil(
        window.innerHeight + document.documentElement.scrollTop,
      ) < document.documentElement.offsetHeight
      || isFetching
    ) { return; }
    setIsFetching(true);
  };

  const fetchMoreData = () => {
    const lastActivityLoaded = activitiesToShow[activitiesToShow.length - 1].dateCreated || activitiesToShow[activitiesToShow.length - 1].timestamp;
    setIsLoading(true);

    if (moment().diff(lastActivityLoaded, 'hours') >= 48 && !canGetAllActivities()) {
      setShowPermissionsModal(true);
    } else {
      if (JSON.stringify(defaultStateFilters) === JSON.stringify(filtersUsed)) {
        getAllActivities(skip);
        setActivitiesToShow([...activities]);
      } else {
        const doorsToSearch = [];
        filtersUsed.properties.forEach((item) => {
          doorsToSearch.push(...doors.filter((door) => door.property === item).map((door) => door.id));
        });
        const keysToSearch = [];
        filtersUsed.keysTypes.forEach((item) => {
          keysToSearch.push(...keys.filter((k) => k.durationType === item).map((k) => k.id));
        });
        filtersUsed.keysTags.forEach((item) => {
          keysToSearch.push(...keys.filter((k) => (k.tags ? k.tags[0] : 'guest') === item).map((k) => k.id));
        });
        findActivitiesbyFilters({
          keys: [...new Set(keysToSearch)],
          doors: doorsToSearch,
          createdBefore: filtersUsed.end || moment().add(2, 'minutes'),
          createdAfter: filtersUsed.start || moment('01-01-2018', 'DD-MM-YYYY'),
        }, skip);
        setActivitiesToShow([...activities]);
      }
      setIsFetching(false);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  };

  useEffect(() => {
    if (!isFetching) return;
    if (total > activitiesToShow.length) fetchMoreData();
    else setIsLoading(false);
  }, [isFetching]);

  useEffect(() => {
    setSpinnerPage(true);
    getAllActivities(0);
    setTimeout(() => {
      setSpinnerPage(false);
    }, 500);
  }, [refreshing]);

  /* get keys tags */
  const keysTags = () => {
    const existingKeys = [...new Set(keys.map((item) => (item.tags ? item.tags[0] : 'guest')))];
    const options = [];
    existingKeys.forEach((value) => {
      switch (value) {
        case 'services':
          return options.push({
            label: t('key.services'),
            value: 'services',
          });
        case 'manager':
          return options.push({
            label: t('key.manager'),
            value: 'manager',
          });
        case 'guest':
          return options.push({
            label: t('key.guest'),
            value: 'guest',
          });
        case 'future':
          return options.push({
            label: t('key.future'),
            value: 'future',
          });
        default:
          return null;
      }
    });
    return options;
  };

  /* get keys types */
  const keysTypes = () => {
    const existingKeys = [...new Set(keys.map((item) => item.durationType))];
    const options = [];
    existingKeys.forEach((value) => {
      switch (value) {
        case 'current':
          return options.push({
            label: t('key.current'),
            value: 'current',
          });
        case 'expired':
          return options.push({
            label: t('key.expired'),
            value: 'expired',
          });
        case 'permanent':
          return options.push({
            label: t('key.permanent'),
            value: 'permanent',
          });
        case 'future':
          return options.push({
            label: t('key.future'),
            value: 'future',
          });
        default:
          return null;
      }
    });
    return options;
  };

  /* get keys types */
  const getProperties = () => {
    const options = [];
    properties.forEach((item) => {
      options.push({
        label: item.name,
        value: item.id,
      });
    });
    return options;
  };

  /* onchange properties checkbox */
  const propertiesHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        properties: filtersState.properties.length > 0 ? [...filtersState.properties, value] : [value],
      });
    } else {
      const keysTypesState = filtersState.properties.filter((item) => item !== value);
      setFiltersState({
        ...filtersState,
        properties: keysTypesState,
      });
    }
  };

  /* onchange keytypes checkbox */
  const keysTypesHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        keysTypes: filtersState.keysTypes.length > 0 ? [...filtersState.keysTypes, value] : [value],
      });
    } else {
      const keysTypesState = filtersState.keysTypes.filter((item) => item !== value);
      setFiltersState({
        ...filtersState,
        keysTypes: keysTypesState,
      });
    }
  };

  /* onchange keytags checkbox */
  const keysTagsHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        keysTags: filtersState.keysTags.length > 0 ? [...filtersState.keysTags, value] : [value],
      });
    } else {
      const keysTagsState = filtersState.keysTags.filter((item) => item !== value);
      setFiltersState({
        ...filtersState,
        keysTags: keysTagsState,
      });
    }
  };

  /* apply filters */
  // eslint-disable-next-line complexity
  const applyFilters = async (changeState, locationState) => {
    // filter properties to search
    const filtersStateClone = changeState || filtersState;

    let doorsToSearch = [];
    filtersStateClone.properties.filter((v) => v).forEach((item) => {
      doorsToSearch.push(...doors.filter((door) => door.property === item).map((door) => door.id));
    });
    if (filtersStateClone.properties.filter((v) => v).length > 0 && !doorsToSearch.length) {
      setActivitiesToShow([]);
      setIsFetching(false);
      window.addEventListener('scroll', handleScroll);
      document.body.style.overflow = 'initial';
      setOpenFilters(false);
      if ((moment(defaultStartDate).format('DD-MM-YYYY') === moment(filtersStateClone.start).format('DD-MM-YYYY') && moment(defaultEndDate).format('DD-MM-YYYY') === moment(filtersStateClone.end).format('DD-MM-YYYY')) || (filtersStateClone.start === null)) {
        setFiltersUsed(changeState ? {
          ...changeState,
          start: null,
          end: null,
        } : {
          ...filtersState,
          start: null,
          end: null,
        });
      } else {
        setFiltersUsed(changeState || {
          ...filtersState,
        });
      }
      return;
    }

    const keysToSearch = [];
    if (filtersStateClone.keysTypes.length > 0 && filtersStateClone.keysTags.length > 0) {
      filtersStateClone.keysTypes.forEach((item) => {
        filtersStateClone.keysTags.forEach((tag) => {
          keysToSearch.push(...keys.filter((k) => (k.tags ? k.tags[0] : 'guest') === tag && k.durationType === item).map((k) => k.id));
        });
      });
      if (!keysToSearch.length) {
        setActivitiesToShow([]);
        setIsFetching(false);
        window.addEventListener('scroll', handleScroll);
        document.body.style.overflow = 'initial';
        setOpenFilters(false);
        if ((moment(defaultStartDate).format('DD-MM-YYYY') === moment(filtersStateClone.start).format('DD-MM-YYYY') && moment(defaultEndDate).format('DD-MM-YYYY') === moment(filtersStateClone.end).format('DD-MM-YYYY')) || (filtersStateClone.start === null)) {
          setFiltersUsed(changeState ? {
            ...changeState,
            start: null,
            end: null,
          } : {
            ...filtersState,
            start: null,
            end: null,
          });
        } else {
          setFiltersUsed(changeState || {
            ...filtersState,
          });
        }
        return;
      }
    } else {
      filtersStateClone.keysTypes.forEach((item) => {
        keysToSearch.push(...keys.filter((k) => k.durationType === item).map((k) => k.id));
      });
      filtersStateClone.keysTags.forEach((item) => {
        keysToSearch.push(...keys.filter((k) => (k.tags ? k.tags[0] : 'guest') === item).map((k) => k.id));
      });
    }

    if (locationState) {
      doorsToSearch = locationState;
    }

    let dataToSend;
    // verify if date filter is applied
    if ((moment(defaultStartDate).format('DD-MM-YYYY') === moment(filtersStateClone.start).format('DD-MM-YYYY') && moment(defaultEndDate).format('DD-MM-YYYY') === moment(filtersStateClone.end).format('DD-MM-YYYY')) || (filtersStateClone.start === null)) {
      dataToSend = {
        keys: [...new Set(keysToSearch)],
        doors: doorsToSearch,
        createdBefore: moment().add(2, 'minutes'),
        createdAfter: moment('01-01-2018', 'DD-MM-YYYY'),
      };
    } else {
      dataToSend = {
        keys: [...new Set(keysToSearch)],
        doors: doorsToSearch,
        createdBefore: filtersStateClone.end,
        createdAfter: filtersStateClone.start,
      };
    }
    await findActivitiesbyFilters(dataToSend, 0).then((res) => {
      setActivitiesToShow(res);
      setIsFetching(false);
      window.addEventListener('scroll', handleScroll);
    });

    if (changeState) {
      setFiltersState(changeState);
    }
    // closepopup
    document.body.style.overflow = 'initial';
    setOpenFilters(false);

    // verify if date filter is applied
    // save filters userd
    if ((moment(defaultStartDate).format('DD-MM-YYYY') === moment(filtersStateClone.start).format('DD-MM-YYYY') && moment(defaultEndDate).format('DD-MM-YYYY') === moment(filtersStateClone.end).format('DD-MM-YYYY')) || (filtersStateClone.start === null)) {
      setFiltersUsed(changeState ? {
        ...changeState,
        start: null,
        end: null,
      } : {
        ...filtersState,
        start: null,
        end: null,
      });
    } else {
      setFiltersUsed(changeState || {
        ...filtersState,
      });
    }
  };

  // on mount
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);
    if (location.state && location.state.doors) {
      applyFilters(null, location.state.doors);
    }
    setFiltersState({
      ...filtersUsed,
    });
  }, []); // run only once

  // on update
  useEffect(() => resetSearchActivities(), []); // run only once

  /* clean filters */
  const resetFilters = async () => {
    setFiltersState({
      ...defaultStateFilters,
    });
    setFiltersUsed({
      ...defaultStateFilters,
    });

    await findActivitiesbyFilters({
      keys: [],
      doors: [],
      createdBefore: moment().add(2, 'minutes'),
      createdAfter: moment('01-01-2018', 'DD-MM-YYYY'),
    }, 0).then((res) => {
      setActivitiesToShow(res);
      setIsFetching(false);
      window.addEventListener('scroll', handleScroll);
    });
  };

  /* close modal */
  const closeModal = () => {
    document.body.style.overflow = 'initial';
    setOpenFilters(false);
    setFiltersState({
      ...filtersUsed,
    });
  };
  /* remove shortcuts filters */
  const removeFilter = (type, value) => {
    switch (type) {
      case 'keysTypes':
        const arrayTypesCopy = [...filtersUsed.keysTypes];
        arrayTypesCopy.splice(arrayTypesCopy.indexOf(value), 1);
        return applyFilters({
          ...filtersUsed,
          keysTypes: arrayTypesCopy,
        });
      case 'keysTags':
        const arrayTagsCopy = [...filtersUsed.keysTags];
        arrayTagsCopy.splice(arrayTagsCopy.indexOf(value), 1);
        return applyFilters({
          ...filtersUsed,
          keysTags: arrayTagsCopy,
        });
      case 'properties':
        const arrayPropertiesCopy = [...filtersUsed.properties];
        arrayPropertiesCopy.splice(arrayPropertiesCopy.indexOf(value), 1);
        return applyFilters({
          ...filtersUsed,
          properties: arrayPropertiesCopy,
        });
      case 'date':
        return applyFilters({
          ...filtersUsed,
          start: null,
          end: null,
        });
      default:
        return null;
    }
  };

  const openModalFilters = () => {
    if (filtersUsed.start !== null) {
      setFiltersState({
        ...filtersUsed,
      });
    } else {
      setFiltersState({
        ...filtersUsed,
        start: defaultStartDate,
        end: defaultEndDate,
      });
    }
    setOpenFilters(true);
    document.body.style.overflow = 'hidden';
  };
  return (
    <>
      {!spinnerPage ? (
        <div id="listContainerBig" className="container">
          <MainHeader>
            <div>
              <h1>{t('activities')}</h1>
              <p className="headerDescription">
                {(activities || []).length}
                {' '}
                {(activities || []).length !== 1 ? t('activities').toLowerCase() : t('activity').toLowerCase()}
              </p>
            </div>
            <div>
              <BtnRefresh
                type="button"
                id="refresh-activities"
                onClick={() => setRefreshing(refreshing + 1)}
                onKeyDown={() => setRefreshing(refreshing + 1)}
              >
                <RefreshIcon />
              </BtnRefresh>
            </div>
          </MainHeader>
          <FormSearch className="row">
            <div className="has-feedback has-feedback-left col-10 col-md-6 col-sm-11">
              <SearchIcon className="search-icon" />
              <input
                type="text"
                id="search-activities"
                className="form-control form-control-search input-lg"
                placeholder={t('search')}
                onChange={(e) => searchActivities(e.target.value)}
              />
            </div>
            <BtnFilters className="col-2 col-md-2 col-sm-1">
              <button type="button" className="btn btn-light w-100" onClick={() => openModalFilters()}>{t('filter.filters')}</button>
              <button type="button" className="btn" onClick={() => openModalFilters()}>
                <FilterIcon />
              </button>
            </BtnFilters>
          </FormSearch>
          {(filtersUsed.keysTags.filter((v) => v !== '').length > 0 || filtersUsed.keysTypes.filter((v) => v !== '').length > 0 || filtersUsed.properties.filter((v) => v !== '').length > 0 || filtersUsed.start !== null) && (
            <div className="row">
              <FiltersApplied className="col-md-12 mt-2">
                <>
                  {filtersUsed.start !== null && (
                    <li className="btn btn-light mt-2" key="date-filter">
                      {moment(filtersUsed.start).format('DD-MM-YYYY')}
                      {' '}
                      -
                      {' '}
                      {moment(filtersUsed.end).format('DD-MM-YYYY')}
                      <button type="button" onClick={() => removeFilter('date')}>&times;</button>
                    </li>
                  )}
                </>
                {getProperties().map((item) => (
                  <>
                    {filtersUsed.properties.includes(item.value) && (
                      <li className="btn btn-light mt-2" key={item.label}>
                        {item.label}
                        <button type="button" onClick={() => removeFilter('properties', parseInt(item.value, 10))}>&times;</button>
                      </li>
                    )}
                  </>
                ))}
                {keysTags().map((item) => (
                  <>
                    {filtersUsed.keysTags.includes(item.value) && (
                      <li className="btn btn-light mt-2" key={item.value}>
                        {item.label}
                        <button type="button" onClick={() => removeFilter('keysTags', item.value)}>&times;</button>
                      </li>
                    )}
                  </>
                ))}
                {keysTypes().map((item) => (
                  <>
                    {filtersUsed.keysTypes.includes(item.value) && (
                      <li className="btn btn-light mt-2" key={item.value}>
                        {item.label}
                        <button type="button" onClick={() => removeFilter('keysTypes', item.value)}>&times;</button>
                      </li>
                    )}
                  </>
                ))}
              </FiltersApplied>
            </div>
          )}
          <List
            activities={activitiesToShow}
            location={location}
            isLoading={isLoading}
            total={total}
            canGetAllActivities={canGetAllActivities}
            showPermissionsModal={showPermissionsModal}
            clickShowPermissionsModal={() => setShowPermissionsModal(false)}
          />
          <div
            style={{ marginBottom: '24px' }}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}

      <Popup open={openFilters} closeOnDocumentClick modal nested onClose={() => closeModal()}>
        <div>
          <header>
            <h3>{t('filter.filters')}</h3>
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}
              onKeyDown={() => closeModal()}
            >
              &times;
            </button>
          </header>
          <div>
            <FiltersHeader>
              <li
                className={activeTab === 'properties' ? 'active' : ''}
                onClick={() => setActiveTab('properties')}
                id="properties"
                onKeyDown={() => setActiveTab('properties')}
              >
                {t('filter.properties')}
              </li>
              <li
                className={activeTab === 'date' ? 'active' : ''}
                onClick={() => setActiveTab('date')}
                id="date"
                onKeyDown={() => setActiveTab('date')}
              >
                {t('filter.date')}
              </li>
              <li
                className={activeTab === 'key' ? 'active' : ''}
                onClick={() => setActiveTab('key')}
                onKeyDown={() => setActiveTab('key')}
                id="keys"
              >
                {t('filter.key')}
              </li>
            </FiltersHeader>
            {activeTab === 'properties' && (
            <div className="selection-container">
              {getProperties().map((item) => (
                <div key={item.value} className="mt-4 checkbox-grid">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value={item.value}
                      checked={filtersState.properties.includes(item.value)}
                      onChange={(e) => propertiesHandler(e.target.checked, parseInt(e.target.value, 10))}
                    />
                    <span className="checkbox-custom" />
                  </label>
                  <p className="checkbox-text">{item.label}</p>
                </div>
              ))}
            </div>
            )}
            {activeTab === 'date' && (
            <div className="selection-container">
              <CalendarView
                t={t}
                isFilter
                dateChangeHandler={(date) => setFiltersState({
                  ...filtersState,
                  start: moment(date[0]).set({
                    year: moment(date[0]).get('year'),
                    month: moment(date[0]).get('month'),
                    date: moment(date[0]).get('date'),
                  }),
                  end: moment(date[1]).set({
                    year: moment(date[1]).get('year'),
                    month: moment(date[1]).get('month'),
                    date: moment(date[1]).get('date'),
                  }),
                })}
                date={[new Date(filtersState.start), new Date(filtersState.end)]}
              />
            </div>
            )}
            {activeTab === 'key' && (
              <div className="selection-container">
                {keysTags().length > 0 && (
                  <p style={{ color: '#FF005F', fontSize: '14px' }}>{t('key.keyType')}</p>
                )}
                {keysTags().map((item) => (
                  <div key={item.value} className="mt-4 checkbox-grid">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value={item.value}
                        checked={filtersState.keysTags.includes(item.value)}
                        onChange={(e) => keysTagsHandler(e.target.checked, e.target.value)}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{item.label}</p>
                  </div>
                ))}
                <br />
                {keysTypes().length > 0 && (
                  <p style={{ color: '#FF005F', fontSize: '14px' }}>{t('key.duration')}</p>
                )}
                {keysTypes().map((item) => (
                  <div key={item.value} className="mt-4 checkbox-grid">
                    <label className="checkbox-label">
                      <input
                        value={item.value}
                        type="checkbox"
                        checked={filtersState.keysTypes.includes(item.value)}
                        onChange={(e) => keysTypesHandler(e.target.checked, e.target.value)}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{item.label}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex align-items-center justify-content-center mt-5">
              <button
                type="button"
                className="btn btn-cancel btn-block bw-40 mr-2"
                onClick={() => resetFilters()}
              >
                {t('filter.clean')}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-block bw-40 mt-0"
                onClick={() => applyFilters()}
              >
                {t('filter.filter')}
              </button>
            </div>
          </div>
        </div>
      </Popup>

    </>
  );
};

const mapStateToProps = (state) => ({
  activities: state.activities.activities,
  skipValue: state.activities.skipValue,
  total: state.activities.total,
  keys: state.keys.keys,
  properties: state.properties.properties,
  doors: state.doors.doors,
  canGetAllActivities: () => state.billing.customer.services.includes('service-keyevents-unlimited'),
});

const mapDispatchToProps = (dispatch) => ({
  resetSearchActivities: () => dispatch(filterActivities('')),
  searchActivities: (filter) => dispatch(filterActivities(filter)),
  findActivitiesbyFilters: (filter, skipValue) => dispatch(filterActivitiesbyFilters(filter, skipValue)),
  getAllActivities: (skipValue) => dispatch(getActivities(skipValue)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Activities),
);
