import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { searchMatch } from '../../../utils/global';
import PropertyMini from '../PropertyMini/PropertyMini';
import NoItemsMessage from '../../ReusableComponents/NoItemsMessage/NoItemsMessage';

import { ReactComponent as PropertiesIcon } from '../../../assets/images/illustration/properties.svg';

export const PropertyList = ({
  t,
  properties,
  search,
}) => {
  const renderProperties = () => properties.map((item) => ({
    ...item,
    isVisible: searchMatch(search, [
      item.name,
      item.address ? item.address.city : null,
      item.address ? item.address.country : null,
      item.address ? item.address.streetAddress : null,
      item.address ? item.address.area : null,
      item.address ? item.address.formatted : null,
    ]),
  }));

  return (
    <div>
      {renderProperties().length > 0 ? (
        renderProperties().map((item) => (
          <>
            {item.isVisible && (
              <PropertyMini key={item.id} id={item.id} />
            )}
          </>
        ))
      ) : (
        <NoItemsMessage
          heading={t('property.noRecords1')}
          subheading={t('property.noRecords2')}
          id="no-properties"
        >
          <PropertiesIcon style={{ width: '78px', height: '78px' }} />
        </NoItemsMessage>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.properties.search,
});

const mapDispatchToProps = () => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PropertyList));
