import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import nukiImage from '../../assets/images/box-activation/nuki.png';
import smartlockKeypadImage from '../../assets/images/box-activation/smartlock-keypad.png';
import motorisedLockImage from '../../assets/images/box-activation/motorised-lock.jpg';
import electricStrikeImage from '../../assets/images/box-activation/electric-strike.png';
// import smartlock from '../../../../assets/images/box-activation/smartlock-keypad.png';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="container container--padding" style={{ maxWidth: '600px', marginTop: '50px' }}>
      <h1 className="center title">{t('boxActivation.locks.locksTitle')}</h1>
      <div className="row top-20">
        <h5 className="center" style={{ margin: 'auto' }}>{t('boxActivation.locks.locksSubtitle')}</h5>
        <div className="row center" style={{ margin: 'auto', marginTop: '50px' }}>
          <div className="six columns activation-card activation-card-responsive">
            <h6 className="activation-card-title" style={{ fontWeight: '600', minHeight: '0px', marginBottom: '20px' }}>
              {t('boxActivation.locks.nuki')}
            </h6>
            <img src={nukiImage} alt="motorised lock" className='lock-img' />
            <Link to="/box/activation/lock/nuki/pairing" onClick={() => window.localStorage.setItem('currentLock', 'nuki')}>
              <input
                className="button-primary btn btn--apply btn-card"
                type="submit"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                value={t('boxActivation.select')}
              />
            </Link>
          </div>
          <div className="six columns activation-card activation-card-responsive">
            <h6 className="activation-card-title" style={{ fontWeight: '600', minHeight: '0px', marginBottom: '20px' }}>
              {t('boxActivation.locks.cylinderKeypad')}
            </h6>
            <img src={smartlockKeypadImage} alt="motorised lock" className='lock-img' />
            <Link to="/box/activation/lock/smartlock-keypad/pairing" onClick={() => window.localStorage.setItem('currentLock', 'cylinderKeypad')}>
              <input
                className="button-primary btn btn--apply btn-card"
                type="submit"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                value={t('boxActivation.select')}
              />
            </Link>
          </div>
          {/* <div className="six columns activation-card activation-card-responsive">
            <h6 className="activation-card-title" style={{ fontWeight: '600', minHeight: '0px', marginBottom: '20px' }}>
              {t('boxActivation.locks.cylinder')}
            </h6>
            <img src={smartlockKeypadImage} alt="motorised lock" className='lock-img' />
            <Link to="/box/activation/lock/nuki/pairing">
              <input
                className="button-primary btn btn--apply btn-card"
                type="submit"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                value={t('boxActivation.select')}
              />
            </Link>
              </div> */}
          <div className="six columns activation-card activation-card-responsive">
            <h6 className="activation-card-title" style={{ fontWeight: '600', minHeight: '0px', marginBottom: '20px' }}>
              {t('boxActivation.locks.motorisedLock')}
            </h6>
            <img src={motorisedLockImage} alt="motorised lock" className='lock-img' />
            <Link to="/box/activation/lock/motorised/test" onClick={() => window.localStorage.setItem('currentLock', 'motorised')}>
              <input
                className="button-primary btn btn--apply btn-card"
                type="submit"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                value={t('boxActivation.select')}
              />
            </Link>
          </div>
          <div className="six columns activation-card activation-card-right activation-card-responsive">
            <h6 className="activation-card-title" style={{ fontWeight: '600', minHeight: '0px', marginBottom: '20px' }}>
              {t('boxActivation.locks.electricStrike')}
            </h6>
            <img
              src={electricStrikeImage}
              alt='electric strike'
              className='lock-img'
              style={{
                display: 'block', margin: 'auto', padding: '25px 0px', width: '100px',
              }}
            />
            <Link to="/box/activation/lock/electric-strike/test" onClick={() => window.localStorage.setItem('currentLock', 'electric-strike')}>
              <input
                className="button-primary btn btn--apply btn-card"
                type="submit"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                value={t('boxActivation.select')}
              />
            </Link>
          </div>
          <div className="six columns activation-card activation-card-right activation-card-responsive">
            <Link style={{ display: 'block', textAlign: 'right' }} to='/box/activation/code' className="btn-link" onClick={() => window.localStorage.setItem('currentLock', 'none')}>{t('boxActivation.door.skipSmartlock')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
