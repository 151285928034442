import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import queryString from 'query-string';
import axios from 'axios';

import { guestInvitation } from '../../modules/guestInvitations';
import TutorialViewTemplate from './TutorialViewTemplate';
import GuestDashboard from './GuestDashboard';
import FullPageSpinner from '../ReusableComponents/Spinner/FullPageSpinner';
import GuestInvitationNavbar from './GuestInvitationNavbar';
import NoItemsMessage from '../ReusableComponents/NoItemsMessage/NoItemsMessage';

import styles from './GuestDashboard.module.scss';

import keypadGif from '../../assets/gifs/keypadDemo.gif';
import vkeyGif from '../../assets/gifs/vkeyDemo.gif';
import { ReactComponent as FailureImage } from '../../assets/images/illustration/failure.svg';

const Content = () => {
  const {
    data: {
      doors,
      properties,
    },
  } = useSelector((state) => state.guestInvitation);
  let { stage } = useSelector((state) => state.guestInvitation);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (cookies.get('skipGuestTutorial')) {
    stage = 'dashboard';
    dispatch(guestInvitation.actions.setStage('dashboard'));
  }

  switch (stage) {
    case 'welcome': {
      return (
        <TutorialViewTemplate
          next={() => dispatch(
            guestInvitation.actions.setStage(doors.find((door) => door.hasKeypad) ? 'keypad' : 'vkey'),
          )}
          skip={() => dispatch(guestInvitation.actions.setStage('dashboard'))}
          inputLength={3}
          heading={t('guestInvitation.heading.welcome')}
          subheading={t('guestInvitation.subHeading.welcome')}
          selectedInput={0}
          propertyNames={properties.map((property) => property.name)}
          welcomeView
        />
      );
    }
    case 'keypad': {
      return (
        <TutorialViewTemplate
          next={() => dispatch(guestInvitation.actions.setStage('vkey'))}
          skip={() => dispatch(guestInvitation.actions.setStage('dashboard'))}
          inputLength={3}
          heading={t('guestInvitation.heading.keypad')}
          subheading={t('guestInvitation.subHeading.keypad')}
          selectedInput={1}
          gif={keypadGif}
        />
      );
    }
    case 'vkey': {
      return (
        <TutorialViewTemplate
          next={() => dispatch(guestInvitation.actions.setStage('dashboard'))}
          skip={() => dispatch(guestInvitation.actions.setStage('dashboard'))}
          inputLength={3}
          heading={t('guestInvitation.heading.vkey')}
          subheading={t('guestInvitation.subHeading.vkey')}
          selectedInput={2}
          gif={vkeyGif}
        />
      );
    }
    default: {
      return (
        <GuestDashboard
          viewTutorial={() => dispatch(guestInvitation.actions.setStage('welcome'))}
        />
      );
    }
  }
};

export default ({ location }) => {
  const dispatch = useDispatch();
  const [tokenStatus, setTokenStatus] = useState('none');
  const { t } = useTranslation();

  const getToken = () => {
    const { token } = queryString.parse(location.search);
    if (token) {
      axios.post('https://components.homeit.io/invitations/guest/validate', { token })
        .then(({ data: { docs: [data] } }) => {
          dispatch(guestInvitation.actions.setData(data));
          cookies.set('guestInvitationToken', token, { expires: 5647 });
          setTokenStatus('valid');
        })
        .catch(() => setTokenStatus('invalid'));
    } else setTokenStatus('missing');
  };

  useEffect(() => getToken(), []); // run only once

  const Body = () => {
    switch (tokenStatus) {
      case 'valid': {
        return <Content />;
      }
      default: {
        return (
          <NoItemsMessage
            heading={t('guestInvitation.tokenError.heading')}
            subheading={t('guestInvitation.tokenError.subHeading')}
            id="invalid-token"
          >
            <FailureImage style={{ width: '78px', height: '78px' }} />
          </NoItemsMessage>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.desktopNavbar} id="listContainer" style={{ paddingTop: '0px' }}>
        <GuestInvitationNavbar />
      </div>
      {tokenStatus !== 'none' ? (
        <div className="container" style={{ maxWidth: '373px' }}>
          <h1 className={`${styles.titlePaddingTop}`}>{t('guestInvitation.selfCheckin')}</h1>
          <Body />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </>
  );
};
