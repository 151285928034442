import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ICalListing from './iCalListing';
import PermissionModal from '../../ReusableComponents/PermissionModal/PermissionModal';
import { AlertConfirm } from '../../../utils/alerts';

import style from '../Main.module.scss';

export default ({ location }) => {
  const {
    synchronisations: { ical: { listings: icalListings } },
    billing: { customer: { services } },
  } = useSelector((state) => state);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  // const iCalName = location && location.pathname.split('/').pop();

  const calendarLength = icalListings.length;

  const canSyncIcal = () => services.includes('service-integrations-ical');

  const addIcal = () => {
    if (canSyncIcal()) {
      return AlertConfirm
        .fire({
          icon: 'info',
          text: t('synchronisation.genericIcalModal'),
        })
        .then(({ value: val }) => val && history.push({ pathname: '/synchronisations/ical/item', state: { name: 'iCal' } }));
    }
    return setShowBillingModal(true);
  };

  return (
    <div id="listContainer" className="container mb-4">
      {showBillingModal && <PermissionModal click={() => setShowBillingModal(false)} />}
      <div id={style.keysHeader} style={{ marginBottom: '32px', display: 'grid', gridTemplateColumns: 'auto 40px' }}>
        <div>
          <h1>iCal</h1>
          <p className={style.synchronisation__content} style={{ fontWeight: '100' }}>
            {t('synchronisation.calendarsSynced', { count: calendarLength })}
          </p>
        </div>
        <div
          onClick={() => addIcal()}
          style={{ maxWidth: '40px', cursor: 'pointer' }}
        >
          <div className={`${style.btnCreateIcal}`} />
        </div>
      </div>
      {icalListings.length > 0
          && icalListings.map((listing) => true
          && <ICalListing {...listing} key={listing.id} iCalDoors={listing.doors} iCalId={listing.id} iCalName="iCal" />)}
    </div>
  );
};
