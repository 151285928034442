import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import style from './Settings.module.scss';

import { updateSettings } from '../../modules/user';
import Card from '../ReusableComponents/Card/Card';

export class EditSettings extends React.Component {
  state = {
    name: this.props.user.name,
    email: {
      address: this.props.user.email.address,
    },
    phone: {
      number: this.props.user.phone.number,
    },
  };

  telephoneHandler = (value) => this.setState({ value });

  render() {
    const {
      user,
      updateSettings: update,
      history,
      t,
    } = this.props;
    const {
      name,
      email: { address },
      phone: { number },
    } = this.state;
    return (
      <div style={{ background: '#00B7A0', height: '94vh' }}>
        <div className="medium-container">
          <div id={style.container} className="container mb-5">
            <div className="col">
              <h1 className="mb-4" style={{ paddingTop: '32px' }}>
                {t('edit')}
                {' '}
                {t('settings')}
              </h1>
            </div>
            <Card>
              <div>
                <div className="form-group">
                  <label htmlFor="keyUsernameInput">{t('name')}</label>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        defaultValue={name}
                        onChange={(e) => this.setState({
                          name: e.target.value,
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="emailInput">{t('email')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailInput"
                    defaultValue={address}
                    onChange={(e) => this.setState({ email: { address: e.target.value } })}
                  />
                </div>
                {/* Phone Number */}
                <div className="form-group">
                <label htmlFor="phoneCountrySelect">{t('phoneNumber')}</label>
                  <input
                    type="text"
                    id="phoneInput"
                    className="form-control"
                    defaultValue={number}
                    onChange={(e) => this.setState({ phone: { number: e.target.value } })}
                  />
                  </div>
                <div className="row mt-5">
                  <div className="col-6" style={{ maxWidth: '190px' }}>
                    <button
                      className="btn btn-secondary btn-block"
                      type="button"
                      onClick={() => { window.location.href = 'https://auth.homeit.io/v2/recovery'; }}
                    >
                      {t('changePassword')}
                    </button>
                  </div>
                </div>
                <div className="row mt-5" style={{ maxWidth: '439px', margin: 'auto' }}>
                  <div className="col-6">
                    <button
                      className="btn btn-cancel btn-block"
                      id="cancel-button"
                      type="button"
                      onClick={() => history.push('/settings')}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-secondary btn-block"
                      id="update-settings"
                      type="button"
                      onClick={() => update(this.state, user.id)}
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  updateSettings: (settings, id) => dispatch(updateSettings(settings, id, props)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditSettings));
