import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {
  redirectToLogin,
  getTokenFromServer,
  // ensureAuth,
  getAuthToken,
  getRefreshToken,
} from '../utils/auth';

// Keys Components
import Keys from './Keys/Main';
import CreateEditKey from './Keys/CreateEditKey/CreateEditKey';
import Recover from './Keys/Recover/Recover';

// Properties Components
import Home from './Properties/Home';
import Property from './Properties/Property/Property';
import CreateEditProperty from './Properties/CreateEditProperty';
import Activities from './Activities/Main/index';

// Doors Components
import EditDoor from './Properties/Property/Door/EditDoor';

// Billing Components
import Billing from './Billing/Main';

// Settings Components
import Settings from './Settings/Settings';
import EditSettings from './Settings/EditSettings';

// User Management
import HostManagement from './HostManagement/HostList';
import CreateEditHost from './HostManagement/CreateEditHost';

// Synchronisations Components
import Synchronisations from './Synchronisations/Main';
import SynchronisationsItem from './Synchronisations/Item';
import iCalList from './Synchronisations/iCal/iCalList';
import iCalItem from './Synchronisations/iCal/iCalItem';

import FullPageSpinner from './ReusableComponents/Spinner/FullPageSpinner';

import Help from './Help/Help';

import BoxActivation from './BoxActivation/BoxActivation';

import Navbar from './Navbar/Navbar';
import NavbarDesktop from './NavbarDesktop/NavbarDesktop';

// Ducks Files
import { getUsers } from '../modules/user';
import { getCustomer } from '../modules/billing';
import { getProperties } from '../modules/properties';
import { getDoors } from '../modules/doors';
import { getBoxes } from '../modules/boxes';
import { getKeypads } from '../modules/keypads';
import { getLocks } from '../modules/locks';
import { getKeys } from '../modules/keys';
import { getActivities } from '../modules/activities';
import { getCohostsInvitations } from '../modules/hostInvitation';
import {
  getiCalListings,
  getCredentials,
  getListings,
} from '../modules/synchronisations';

export class HostApp extends React.Component {
  componentDidMount() {
    // Use code after login to get auth tokens
    if (window.location.search.indexOf('code') >= 0) {
      getTokenFromServer()
        .then(() => this.loadObjs());
    } else if (!getAuthToken() && getRefreshToken()) {
      axios.get('/refresh')
        .catch(() => redirectToLogin())
        .then(() => this.loadObjs());
    } else {
      this.loadObjs();
    }
  }

  isLoaded() {
    const {
      user,
      billing,
      properties,
      // doors,
      // boxes,
      // getLocks,
      // getKeypads,
      // keys,
      // activities,
      // synchronisations,
    } = this.props;
    // removing success checking on various items reduces perceived load time
    return (
      user.success
      && billing.success
      && properties.success
      // && doors.success
      // && boxes.success
      // && getLocks
      // && getKeypads
      // && keys.success
      // && activities.success
      // && synchronisations.success
    );
  }

  async loadObjs() {
    const {
      getUsers: users,
      getCustomer: customer,
      getProperties: properties,
      getDoors: doors,
      getBoxes: boxes,
      getLocks: locks,
      getKeypads: keypads,
      getKeys: keys,
      getActivities: activities,
      getCohostsInvitations: cohostsInvitations,
      getCredentials: credentials,
      getListings: listings,
      getiCalListings: iCalListings,
    } = this.props;

    // const [
    //   user,
    //   // properties,
    //   // doors,
    // ] =
    await Promise.all([
      users(),
      properties(),
      doors(),
    ]);

    // const [
    //   customer,
    //   // boxes,
    //   // locks,
    //   // keypads,
    //   // keys,
    //   // activities,
    //   // icalListings,
    //   // avantioListings,
    // ] =
    await Promise.all([
      customer(),
      boxes(),
      locks(),
      cohostsInvitations(),
      keypads(),
      keys(),
      activities(),
      iCalListings(),
      credentials('avantio'),
      listings('avantio'),
    ]);
  }

  render() {
    document.querySelector('#manifest-link').setAttribute('href', `${process.env.PUBLIC_URL}/manifest.json`);
    return (
      <Suspense fallback="">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          {this.isLoaded() ? (
            <>
              <NavbarDesktop />
              <Navbar />
              <Switch>
                <Route path="/keys" component={Keys} />
                <Route exact path="/property/create" component={CreateEditProperty} />
                <Route exact path="/property/:id" component={Property} />
                <Route exact path="/property/edit/:id" component={CreateEditProperty} />
                <Route exact path="/door/edit/:id" component={EditDoor} />
                <Route path="/settings" component={Settings} />
                <Route path="/edit-settings" component={EditSettings} />
                <Route exact path="/billing" component={Billing} />
                <Route path="/create-key" component={CreateEditKey} />
                <Route exact path="/edit-key/:id" component={CreateEditKey} />
                <Route exact path="/recover-key/:id" component={Recover} />
                <Route path="/activities" component={Activities} />
                <Route exact path="/synchronisations" component={Synchronisations} />
                <Route exact path="/synchronisations/ical/item" component={iCalItem} />
                <Route path="/synchronisations/ical" component={iCalList} />
                <Route exact path="/synchronisations/:item" component={SynchronisationsItem} />
                <Route exact path="/manage/host" component={HostManagement} />
                <Route exact path="/manage/host/item" component={CreateEditHost} />
                <Route path="/help" component={Help} />
                <Route path="/box/new" component={BoxActivation} />
                <Route path="/properties" component={Home} />
                <Route path="/" component={Home} />
              </Switch>
            </>
          ) : (
            <>
              <NavbarDesktop />
              <Navbar />
              <FullPageSpinner />
            </>
          )}
        </BrowserRouter>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  keys: state.keys,
  doors: state.doors,
  properties: state.properties,
  boxes: state.boxes,
  activities: state.activities,
  auth: state.auth,
  user: state.user,
  userId: state.user.user.id,
  synchronisations: state.synchronisations,
  billing: state.billing,
});

const mapDispatchToProps = (dispatch) => ({
  getProperties: () => dispatch(getProperties()),
  getDoors: () => dispatch(getDoors()),
  getKeys: () => dispatch(getKeys()),
  getUsers: () => dispatch(getUsers()),
  getCustomer: () => dispatch(getCustomer()),
  getBoxes: () => dispatch(getBoxes()),
  getActivities: () => dispatch(getActivities()),
  getKeypads: () => dispatch(getKeypads()),
  getLocks: () => dispatch(getLocks()),
  getCohostsInvitations: () => dispatch(getCohostsInvitations()),
  getiCalListings: () => dispatch(getiCalListings()),
  getListings: (source) => dispatch(getListings({ source })),
  getCredentials: (source) => dispatch(getCredentials({ source })),
});

export default connect(mapStateToProps, mapDispatchToProps)(HostApp);
