import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import style from './CreateEditKey.module.scss';

import { ReactComponent as DeleteIcon } from '../../../assets/images/lined/bin.svg';

import { getDoorsByProperty } from '../../../modules/doors';
import { getBoxById } from '../../../modules/boxes';

const PropertiesSelect = ({
  deleteColumn,
  deleteSelect,
  doorSelectHandler,
  getDoors,
  getBox,
  properties,
  propertyId,
  propertySelectHandler,
  selectedDoors,
  selectedProperties,
  t,
}) => (
  <>
    {/* Properties */}
    <div className={`row ${deleteSelect ? style.hasDelete : ''}`}>
      <div className="col">
        <select
          className="form-control"
          onChange={(e) => propertySelectHandler(Number(e.target.value), propertyId)}
          defaultValue={propertyId || '0'}
        >
          <option disabled value="0">{t('key.selectProperty')}</option>
          {properties.map((property) => (
            <option
              disabled={selectedProperties.includes(property.id)}
              key={property.id}
              value={property.id}
            >
              {property.name}
            </option>
          ))}
        </select>
      </div>
      {deleteColumn
        && (
        <div className="col-1">
            { deleteSelect && (
              <div role="button" onClick={deleteSelect} onKeyDown={deleteSelect} className={style.iconDeleteProperty} tabIndex="0">
                <DeleteIcon />
              </div>
            )}
        </div>
        )}
    </div>
    {/* Doors */}
    {/* TODO: the below way of disabling the door checkbox is a bit messy... */}
    {getDoors().length > 0 && (
      <div className={`row ${style.selectPropertyKey}`}>
        {getDoors().map((door) => {
          const rand = Math.floor(Math.random() * 1000000);
          return (
            <div key={door.id} className="checkbox-grid col-12 col-sm-6">
              <label className="checkbox-label" style={{ margin: '0px' }} htmlFor={`${door.id}${rand}`}>
                <input
                  defaultChecked={selectedDoors.includes(door.id) && (door.box && (getBox(door.box) || {}).isOnline)}
                  onChange={(e) => doorSelectHandler(e.target.checked, [door.id])}
                  type="checkbox"
                  id={`${door.id}${rand}`}
                  disabled={!(door.box && (getBox(door.box) || {}).isOnline)}
                />
                <span className="checkbox-custom" />
              </label>
              <p className="checkbox-text">{`${door.name}${!(door.box && (getBox(door.box) || {}).isOnline) ? ' (Offline)' : ''}`}</p>
            </div>
          );
        })}
      </div>
    )}
  </>
);

// const Doors = ({ doors, selectedDoors, doorSelectHandler }) => doors.map((door) => (
//   <div key={door.id} className="mt-4 checkbox-grid col-6">
//     <label className="checkbox-label" style={{ margin: '0px' }}>
//       <input
//         defaultChecked={selectedDoors.includes(door.id)}
//         onChange={(e) => doorSelectHandler(e.target.checked, [door.id])}
//         type="checkbox"
//         id={door.id}
//         disabled={()}
//       />
//       <span className="checkbox-custom" />
//     </label>
//     <p className="checkbox-text">{door.name}</p>
//   </div>
//   // <div key={door.id} className="col-6">
//   //   <div className="form-group">
//   //     <div className="form-check">
//   //       <input defaultChecked={selectedDoors.includes(door.id)} onChange={(e) => doorSelectHandler(e.target.checked, [door.id])} className="form-check-input" type="checkbox" id={door.id} />
//   //       <label className="form-check-label" style={{ marginLeft: 10, lineHeight: '32px', fontWeight: 'normal' }} htmlFor={door.id}>
//   //         {door.name}
//   //       </label>
//   //     </div>
//   //   </div>
//   // </div>
// ));

const mapStateToProps = (state, props) => ({
  doors: state.doors.doors,
  properties: state.properties.properties,
  getDoors: () => getDoorsByProperty(state, props.propertyId),
  getBox: (id) => getBoxById(state, id),
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PropertiesSelect));
