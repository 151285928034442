import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { redirectToLogin } from '../../utils/auth';

import { ReactComponent as SettingsIcon } from '../../assets/images/solid/settings.svg';
import { ReactComponent as People } from '../../assets/images/solid/people.svg';
import { ReactComponent as Exit } from '../../assets/images/solid/exit.svg';

import style from './NavbarDesktop.module.scss';

export class ProfileResponsive extends React.Component {
  logout = () => redirectToLogin();

  render() {
    const {
      user,
      t,
      closeParent,
    } = this.props;
    return (
      <div className={style.profileMenu}>
        <h2 className={style.paddingLeft24}>
          {t('hi')}
          ,
        </h2>
        <h2 id="name" className={style.paddingLeft24}>
          {user.name
          && (
            <>
              {(user.name || {}).first ? `${user.name.first} ${user.name.last}` : user.name}
            </>
          )}
        </h2>
        <div className={style.bottomSection}>
          <Link className={style.profileMenuIcons} id="settings-one" to="/settings" style={{ textDecoration: 'none' }} onClick={() => closeParent()}>
            <SettingsIcon
              className={style.usersIcon}
            />
            {t('settings')}
          </Link>
          <Link className={style.profileMenuIcons} id="settings-two" to="/manage/host" style={{ textDecoration: 'none' }} onClick={() => closeParent()}>
            <People
              className={style.usersIcon}
            />
            {t('users.manageUsers')}
          </Link>
          <div className={style.profileMenuLineBreak} />
          <div className={`${style.paddingLeft24} ${style.logoutText}`} id="logout" onClick={() => this.logout()}>
            <Exit
              className={style.logoutIcon}
            />
            {t('logout')}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfileResponsive));
