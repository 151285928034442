import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';
import { Lock } from '../box-activation/box-api';

import { ReactComponent as IconNukiBle } from '../../../assets/images/box-activation/icon-nuki-ble.svg';

export default () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState('0%');
  const history = useHistory();

  const setupSmartLock = () => {
    getBox()
      .then((box) => (localStorage.getItem('replaceDevice')
        ? box.door.get(parseInt(window.localStorage.getItem('currentDoor'), 10))
          .then((door) => {
            setProgress('50%');
            return new Promise((resolve, reject) => door.lock.addBLEPairing((res) => {
              if (res instanceof Lock) resolve(res);
            }, reject));
          })
        : box.door.add()
          .then((door) => {
            window.localStorage.setItem('currentDoor', door.idInt);
            setProgress('50%');
            return new Promise((resolve, reject) => door.lock.addBLEPairing((res) => {
              if (res instanceof Lock) resolve(res);
            }, reject));
          })

      ))
      .then((lock) => {
        window.localStorage.setItem('smartlockMac', lock.mac);
      })
      .then(() => new Promise((resolve) => setTimeout(resolve, 500))) // sanity delay
      // .then(() => history.push({ pathname: '/box/activation/lock/smartlock-keypad/pairing/calibrating' }))
      .then(() => history.push({ pathname: '/box/activation/lock/smartlock-keypad/test' }))
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/lock/smartlock-keypad/pairing',
            errorMessage: String(err),
          },
        });
      });
  };

  useEffect(() => setupSmartLock(), []); // run only once

  return (
    <div className="activation-section" id="door-pairing-wait">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-6 col-lg-4">
            <div className="wrapper-icon text-center"><IconNukiBle alt="smartlock setup" style={{ width: '144px' }} /></div>
            <div className="progress formName">
              <div
                className="progress-bar progress-bar-striped bg-homeit progress-bar-animated"
                role="progressbar"
                style={{
                  width: (progress), ariaValuenow: '0', ariaValuemin: '0', ariaValuemax: '100',
                }}
              >
                {progress}
              </div>
            </div>
            <p className="formDescriptionTxt">{t('boxActivation.door.waitDescription')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
