import React from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';

import { getConnection, getBox } from './box-activation/boxConnection';
import WaitingModal from './WaitingModal';

const popupModal = withReactContent(Swal);

class BoxActivationCode extends React.Component {
  constructor() {
    super();
    this.state = {
      codeIsGenerated: false,
      activationCode: '',
      // routeToActivation: '',
      // userID: '',
      // redirectToken: '',
    };
  }

  componentDidMount() {
    // const redirectToken = localStorage.getItem('redirectToken');
    this.setState(/* { redirectToken } */);
    this.endConfiguration();
  }

  endConfiguration = () => {
    // const { activationCode } = this.state;
    const { history } = this.props;

    getBox().then((/* box */) => this.setState(/* { boxMac: box.mac } */))
      .then(() => getConnection().finish())
      .then(({ code }) => {
        // console.log('code: ', code);
        this.setState({
          codeIsGenerated: true,
          activationCode: code,
        });

        Cookies.set('boxActivationToken', code, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN });

        // // eslint-disable-next-line no-undef
        // amplitude.getInstance().logEvent(
        //   'box-activation code',
        //   {
        //     'Box Activation Token': this.state.activationCode,
        //     'Box Mac Address': this.state.boxMac,
        //     'Redirect Token': this.state.redirectToken,
        //   },
        // );
      })
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/code',
            errorMessage: String(err),
          },
        });
      });
  };

  sendInstructionsEmail = (email) => {
    const { activationCode: token } = this.state;
    const { history } = this.props;

    // // eslint-disable-next-line no-undef
    // amplitude.getInstance().logEvent(
    //   'box-activation fitter-email-sent',
    //   {
    //     'Box Activation Token': this.state.activationCode,
    //     'Box Mac Address': this.state.boxMac,
    //     'Redirect Token': this.state.redirectToken,
    //   },
    // );

    return axios.post('/send-activation-token-email', { email, token })
      .then(() => history.push({
        pathname: '/box/activation/code/success',
        state: { email },
      }));
  };

  fitterNoEmail = () => {
    const { activationCode } = this.state;
    const { history } = this.props;

    // // eslint-disable-next-line no-undef
    // amplitude.getInstance().logEvent(
    //   'box-activation fitter-no-email',
    //   {
    //     'Box Activation Token': this.state.activationCode,
    //     'Box Mac Address': this.state.boxMac,
    //     'Redirect Token': this.state.redirectToken,
    //   },
    // );

    const boxActivationToken = activationCode;

    history.push({
      pathname: '/box/activation/code/success',
      state: { boxActivationToken },
    });
  };

  userIsOwner = () => {
    // // eslint-disable-next-line no-undef
    // amplitude.getInstance().logEvent(
    //   'box-activation user-is-owner',
    //   {
    //     'Box Activation Token': this.state.activationCode,
    //     'Box Mac Address': this.state.boxMac,
    //     'Redirect Token': this.state.redirectToken,
    //   },
    // );
  };

  render() {
    const { t } = this.props;
    const { codeIsGenerated } = this.state;
    return (
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.activationCodeTitle')}</h1>
            <h5>{t('boxActivation.code.title')}</h5>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6 formName" />
        </div>
        {!codeIsGenerated
                && (
                <>
                  <WaitingModal />
                </>
                )}
        <div className="container container--padding" style={{ maxWidth: '600px', marginTop: '50px' }}>
          <div className="row center">
            <div className="six columns activation-card">
              <h5 className="activation-card-main-title">{t('boxActivation.owner')}</h5>
              <h6 className="activation-card-title">{t('boxActivation.code.activationOwnerSignup')}</h6>
              <a
                href='/box/new'
                onClick={this.userIsOwner}
                rel="noopener noreferrer"
                target="_blank"
                className="button-primary btn btn--apply btn-card"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
              >
                {t('boxActivation.login')}
                {' '}
                /
                {' '}
                {t('boxActivation.signup')}
              </a>
            </div>
            <div className="six columns activation-card activation-card-margin">
              <h5 className="activation-card-main-title">{t('boxActivation.fitter')}</h5>
              <h6 className="activation-card-title">{t('boxActivation.code.activationFitterMessage')}</h6>
              <button
                className="button-primary btn btn--apply btn-card"
                type="button"
                style={{
                  marginTop: '20px', marginBottom: '0px', color: '#fff', backgroundColor: '#32beaa',
                }}
                onClick={() => {
                  popupModal.fire({
                    text: t('boxActivation.code.emailQuestion'),
                    confirmButtonText: t('boxActivation.yes'),
                    cancelButtonText: t('boxActivation.no'),
                    confirmButtonColor: '#32beaa',
                    showCancelButton: true,
                  })
                    .then((result) => {
                      if (result.value) {
                        popupModal.fire({
                          imageWidth: 60,
                          imageUrl: '/icon-email.png',
                          title: t('boxActivation.code.sendInstructions'),
                          text: t('boxActivation.code.subtitle'),
                          input: 'email',
                          inputPlaceholder: 'Email',
                          confirmButtonText: t('boxActivation.code.sendEmail'),
                          confirmButtonColor: '#32beaa',
                          showLoaderOnConfirm: true,
                          preConfirm: (email) => this.sendInstructionsEmail(email)
                            .catch((error) => {
                              Swal.showValidationMessage(
                                `${error}`,
                              );
                            }),
                        });
                      } else if (
                        result.dismiss === Swal.DismissReason.cancel
                      ) {
                        this.fitterNoEmail();
                      }
                    });
                }}
              >
                {t('boxActivation.code.sendInstructions')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BoxActivationCode);
