import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';

export default () => {
  const [keypadCode, setKeypadCode] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const startKeypadTest = () => {
    const keys = [];
    const doorId = parseInt(localStorage.getItem('currentDoor'), 10);
    const mac = '00:00:00:00:00:01';

    getBox()
      .then((b) => b.door.get(doorId))
      .then((door) => {
        if (!door) throw new Error('Door ID is incorrect or does not exist.');
        return door.keypad.add(mac);
      })
      .then((keypad) => new Promise((resolve, reject) => keypad.startTest((evt) => {
        keys.push(evt.key);
        if (keys.slice(Math.max(keys.length - 5, 0)).join('') === `${15907}`) resolve(keypad);
      })
        .catch(reject)
        .then(() => setKeypadCode(`*${15907}`))))
      .then((keypad) => { setKeypadCode('validating...'); return keypad; })
      .then((keypad) => keypad.stopTest())
      .then(() => history.push('/box/activation/keypad/success'))
      .catch((err) => console.log(err));
  };

  useEffect(() => startKeypadTest(), []); // run only once

  return (
    <div className="activation-section" id="door-keypad-test">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.door.padTestTitle')}</h1>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <span><strong>{t('boxActivation.door.padTestCodeTxt')}</strong></span>
              {keypadCode === ''
                ? <div className="alert alert-homeit text-center formName" role="alert" id="keypad-code">{t('boxActivation.door.generatingCode')}</div>
                : <div className="alert alert-homeit text-center formName" role="alert" id="keypad-code">{keypadCode}</div>}
              <span className="formDescriptionTxt">{t('boxActivation.door.padTestDescription')}</span>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4">
              <p className="formDescriptionTxt">
                {t('boxActivation.door.padTestError') }
                <Link to="/box/activation/keypad/wired/error">
                  {' '}
                  {t('boxActivation.door.padWiredError')}
                </Link>
              </p>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <Link style={{ display: 'block', textAlign: 'left' }} to='/box/activation/keypad' className="btn btn-link col col-md-6 col-lg-4">{t('boxActivation.activationBack')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
