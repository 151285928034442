import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ensureAuth, getAuthToken } from '../utils/auth';

const initialState = {
  pending: false,
  success: false,
  ical: {
    listings: [],
  },
  avantio: {
    credentials: {},
    listings: [],
  },
};

const synchronisations = createSlice({
  name: 'synchronisations',
  initialState,
  reducers: {
    apiPending: (state) => { state.pending = !state.pending; },
    apiSuccess: (state) => { state.success = !state.success; },
    addCredentials: (state, action) => {
      const { payload: { source, credentials } } = action;
      state[source].credentials = credentials;
    },
    addListings: (state, action) => {
      const { payload: { source, listings } } = action;
      state[source].listings = [...state[source].listings, ...listings];
    },
    addListing: (state, action) => {
      const { payload: { source, listing } } = action;
      state[source].listings = [...state[source].listings, listing];
    },
    updateListing: (state, action) => {
      const { payload: { source, listing } } = action;
      state[source].listings = state[source].listings
        .map((l) => ((l.id === listing.id) ? listing : l));
    },
    removeListing: (state, action) => {
      const { payload: { source, listing } } = action;
      state[source].listings = state[source].listings
        .filter((l) => (l.id !== listing.id));
    },
  },
});

const integrationsApi = axios.create({
  baseURL: 'https://components.homeit.io/integrations/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

// ---- PMSs

export const getCredentials = ({ source }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.get(`${source}/credentials`))
  .then((res) => {
    dispatch(synchronisations.actions
      .addCredentials({ source, credentials: res.data.docs[0] || {} }));
    return res.data.docs[0];
  })
  .catch(() => {});

export const postCredentials = ({ source, account, secret }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.post(`${source}/credentials`, { account, secret }))
  .then((res) => {
    dispatch(synchronisations.actions.addCredentials({ source, credentials: res.data.docs[0] }));
    return res.data.docs[0];
  })
  .catch(() => {});

// export const getListings = ({ source }) => async (dispatch) => {
//   const authed = await authHandler();
//   if (authed) {
//     try {
//       const res = await integrationsApi.get(`${source}/listings`);
//       console.log('res: ', res);
//       dispatch(synchronisations.actions.addListings({ source, listings: res.data.docs }));
//       return res.data.docs;
//     } catch (err) {
//       return console.log(err);
//     }
//   }
// };

export const getListings = ({ source }) => async (dispatch) => ensureAuth()
  .then(async () => {
    let allListings = [];
    const getAllListings = async (s = 0) => integrationsApi
      .get(`${source}/listings?skip=${s}`)
      .then(({
        data: {
          docs, skip, limit, total,
        },
      }) => {
        allListings = [...allListings, ...docs];
        if (skip + limit <= total) return getAllListings(skip + limit);
        return allListings;
      });
    const listings = await getAllListings();
    dispatch(synchronisations.actions.addListings({ source, listings }));
    return listings;
  });

export const linkListing = ({ source, id, doors }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.put(`${source}/listings/${id}`, { doors }))
  .then((res) => {
    dispatch(synchronisations.actions.updateListing({ source, listing: res.data.docs[0] }));
    return res.data.docs[0];
  })
  .catch(() => {});

// ICAL

export const getiCalListings = () => (dispatch) => ensureAuth()
  .then(() => integrationsApi.get('ical/listings'))
  .then((res) => {
    dispatch(synchronisations.actions.addListings({ source: 'ical', listings: res.data.docs }));
    dispatch(synchronisations.actions.apiSuccess());
    return res;
  })
  .catch(() => {});

export const linkiCalListing = ({ link: url, doors }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.post('ical/listings', { url, doors }))
  .then((res) => {
    dispatch(synchronisations.actions.addListing({ source: 'ical', listing: res.data.docs[0] }));
  })
  .catch(() => {});

export const editiCalListing = ({ id, link: url, doors }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.put(`ical/listings/${id}`, { url, doors }))
  .then((res) => {
    dispatch(synchronisations.actions.updateListing({ source: 'ical', listing: res.data.docs[0] }));
  })
  .catch(() => {});

export const deleteiCalListing = ({ iCalId: id }) => (dispatch) => ensureAuth()
  .then(() => integrationsApi.delete(`ical/listings/${id}`))
  .then((res) => {
    dispatch(synchronisations.actions.removeListing({ source: 'ical', listing: res.data.docs[0] }));
    return res;
  })
  .catch(() => {});

export { synchronisations };
