import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const replaceDevice = JSON.parse(window.localStorage.getItem('replaceDevice'));

  return (
    <div className="activation-section" id="door-keypad">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            {replaceDevice
              ? <h1>{t('keypadReplacement')}</h1>
              : <h1>{t('boxActivation.intercom.keypadTitle')}</h1>}
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center" id="selector">
            <div className="col col-md-3 col-lg-2" />
            <div className="col col-md-3 col-lg-2 text-center">
              {replaceDevice
                ? <Link to='/box/activation/keypad/wired/test' className="btn btn-block btn-primary">{t('wired')}</Link>
                : <Link to='/box/activation/door/flat/save' className="btn btn-block btn-primary">{t('boxActivation.buttonNo')}</Link>}
            </div>
            <div className="col col-md-3 col-lg-2 text-center">
              {replaceDevice
                ? <Link to='/box/activation/keypad/setup' className="btn btn-block btn-primary">{t('wireless')}</Link>
                : <Link to='/box/activation/keypad/setup' className="btn btn-block btn-primary">{t('boxActivation.buttonYes')}</Link>}
            </div>
          </div>
          <div className="jumbotron" />
          {replaceDevice
            ? null
            : <Link style={{ display: 'block', textAlign: 'right' }} to='/box/activation/keypad/wired/test' className="btn btn-link">{t('boxActivation.door.padWired')}</Link>}
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              {/* <Link to='/box/activation/lock/nuki/test' className="btn btn-link">{t("boxActivation.activationBack")}</Link> */}
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
