import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { deleteiCalListing } from '../../../modules/synchronisations';
import { AlertConfirm, AlertConfirmCancel } from '../../../utils/alerts';
import Card from '../../ReusableComponents/Card/Card';
import PermissionModal from '../../ReusableComponents/PermissionModal/PermissionModal';

import styles from './iCalListing.module.scss';

export default ({
  url: listingUrl, source, iCalId, iCalName, iCalDoors,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    properties: { properties },
    doors: { doors },
    billing: { customer: { services } },
  } = useSelector((state) => state);

  const [isUserPermissionModalDisplayed, setIsUserPermissionModalDisplayed] = useState(false);

  // -------

  const getPropertyById = (id) => properties.find((property) => property.id === parseInt(id, 0));
  const getDoorById = (id) => doors.find((door) => door.id === parseInt(id, 0));

  const icalProperties = Array.from(new Set(iCalDoors
    .map((door) => getDoorById(door).property)))
    .map((id) => getPropertyById(id));
  const { address, name } = icalProperties[0];

  // -------

  const canSyncIcal = () => services.includes('service-integrations-ical');

  const icalDelete = async () => {
    const { value } = await AlertConfirmCancel.fire({
      icon: 'warning',
      // title: 'Delete Key?',
      text: t('synchronisation.warnDelete.warning'),
      confirmButtonText: t('synchronisation.warnDelete.buttonYes'),
      cancelButtonText: t('synchronisation.warnDelete.buttonNo'),
    });

    if (value) {
      await dispatch(deleteiCalListing({ iCalId, source }))
        .then(() => history.push('/synchronisations'))
        .then(() => AlertConfirm.fire({
          icon: 'success',
          text: t('synchronisation.warnDelete.success'),
        }));
    }
  };

  const icalEdit = async () => {
    if (canSyncIcal()) {
      return history.push({
        pathname: '/synchronisations/ical/item',
        state: {
          listingUrl,
          doors: iCalDoors,
          name: iCalName,
          id: iCalId,
        },
      });
    }
    setIsUserPermissionModalDisplayed(true);
    return undefined;
  };

  // -------

  return (
    <div style={{ marginTop: '16px' }}>
      {isUserPermissionModalDisplayed
        && <PermissionModal click={() => setIsUserPermissionModalDisplayed(false)} />}
      <Card>
        <div className={`${styles.header} row`} style={{ margin: '0px' }}>
          <div
            className="property-header col-8 col-md-6"
            style={{ padding: '0px', textDecoration: 'none', color: '#0A1428' }}
            id="property-link"
          >
            <h5 id="property-name" style={{ fontWeight: '700' }}>{ name }</h5>
            {address
                    && (
                      <>
                        <div className="row" style={{ margin: '0px', fontSize: '13px' }}>
                          <div id="street-address">
                            {`${[
                              (address.street || address.streetAddress),
                              (address.area || address.city),
                              address.country,
                            ].filter((val) => val != null && val.search(/^$|^\s+$/) === -1).join(', ')}`}
                          </div>
                        </div>
                          {iCalDoors && iCalDoors.map((doorId, index) => {
                            if (index === iCalDoors.length - 1) {
                              return (
                                <p
                                  key={doorId}
                                  style={{
                                    fontSize: '13px', display: 'inline-block', color: '#6C727E', fontWeight: '100',
                                  }}
                                >
                                  {(getDoorById(doorId) || {}).name}
                                </p>
                              );
                            }
                            return (
                              <p
                                key={doorId}
                                style={{
                                  fontSize: '13px', display: 'inline-block', marginRight: '6px', color: '#6C727E', fontWeight: '100',
                                }}
                              >
                                {(getDoorById(doorId) || {}).name}
                                ,
                              </p>
                            );
                          })}

                      </>
                    )}
          </div>
          <div className={`col-4 ${styles.linksFlex} order-md-2 col-md-4`} style={{ padding: '0px', maxWidth: '110px', marginLeft: '0px' }}>
            <>
              <div
                onClick={() => icalEdit()}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              >
                <div className={`${styles.btnEditIcal}`} />
              </div>
            </>
            <>
              <div
                onClick={() => icalDelete()}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              >
                <div className={`${styles.btnDeleteIcal}`} />
              </div>
            </>
          </div>
          <div
            className={`${styles.iconContainer} col col-md-4`}
            style={{ paddingLeft: '0px' }}
            id="property-link-two"
            // onClick={() => history.push({ pathname: `/property/${id}`, params: { id } })}
          >
            {/* <div className={`${styles.iconContainerMargin} row`} /> */}
            <p>{t('synchronisation.automaticKeys')}</p>
            <p>{t('active')}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
