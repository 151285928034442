import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import axios from 'axios';
import PlacesAutocomplete from 'react-places-autocomplete';
import ReactDependentScript from 'react-dependent-script';

import countryList from '../../data/countries.json';

import Button from '../ReusableComponents/Button';
import HourInputs from './HourInputs/HourInputs';
import DoorMini from './Property/Door/DoorMini';
import {
  getPropertyById,
  createProperty,
  editProperty,
} from '../../modules/properties';
import {
  getDoorsByProperty,
} from '../../modules/doors';

import styles from './Main.module.scss';

export class CreateEditProperty extends Component {
  state = {
    address: {
      country: '',
      area: '',
      street: '',
    },
    name: '',
    description: '',
  };

  // eslint-disable-next-line complexity
  componentDidMount() {
    const {
      getProperty,
      boxActivationAddProperty,
    } = this.props;

    if (!boxActivationAddProperty) {
      const {
        match: { params: { id } },
      } = this.props;
      if (id) {
        const property = getProperty();
        let country = '';
        if (countryList.length && property.address && property.address.country) {
          if (countryList.find((item) => item.value === property.address.country)) {
            country = property.address.country;
          } else {
            countryList.forEach((item) => {
              if (item.label === property.address.country || Object.keys(item.translations).find((key) => item.translations[key] === property.address.country)) {
                country = item.value;
              }
            });
          }
        }
        this.setState({
          ...property,
          address: {
            area: (property.address ? (property.address.city || property.address.area) : ''),
            street: (property.address ? (property.address.streetAddress || property.address.street) : ''),
            country,
          },
        });
      }
    }
  }

  selectCountry = () => {
    const { value } = document.getElementById('country-property');
    const { address } = this.state;

    this.setState({
      address: {
        area: address.area,
        street: address.street,
        country: value,
      },
    });
  };

  propertySave = async (e) => {
    const {
      id,
      name,
      address,
      defaultCheckIn = (14 * 60),
      defaultCheckOut = (11 * 60),
      description,
    } = this.state;

    const {
      createProperty: create,
      editProperty: edit,
    } = this.props;

    let dataToSend = {
      name,
      address,
      defaultCheckIn,
      defaultCheckOut,
      description,
    };

    e.preventDefault();
    if (address.area) {
      if (id) {
        await edit(dataToSend, id);
      } else {
        dataToSend = {
          description,
          defaultCheckIn,
          defaultCheckOut,
          name,
          address,
        };
        const createdProperty = await create(dataToSend);
        await this.addNewBoxToProperty(createdProperty.id);
      }
      window.location = '/';
    }
  };

  addNewBoxToProperty = (property) => {
    const { history } = this.props;
    const token = Cookies.get('boxActivationToken');
    return axios({
      url: 'https://components.homeit.io/box-configuration/activation/process',
      method: 'POST',
      data: { token, property },
    })
      .then(() => {
        Cookies.remove('boxActivationToken', { domain: '.homeit.io' });
        window.location = '/';
      })
      .catch((err) => {
        history.push({
          pathname: '/host/box/activation/error',
          state: {
            redirectUrl: '/host/box/new/new-property',
            errorMessage: String(err),
          },
        });
      });
  };

  handleChange = (value) => {
    const { address } = this.state;
    this.setState({
      address: {
        area: value,
        street: address.street,
        country: address.country,
      },
    });
  };

  handleSelect = (city, placeId, suggestion) => {
    const { address } = this.state;
    this.setState({
      address: {
        area: suggestion.formattedSuggestion.mainText,
        street: address.street,
        country: address.country,
      },
    });
  };

  render() {
    const {
      id,
      name,
      address,
      defaultCheckIn,
      defaultCheckOut,
      description,
    } = this.state;
    const {
      t,
      history,
      boxActivationAddProperty,
      getDoors,
    } = this.props;
    const language = localStorage.getItem('i18nextLng').substring(0, 2) || 'en';
    const searchOptions = {
      types: ['(cities)'],
      language: localStorage.getItem('i18nextLng'),
      componentRestrictions: {
        country: address.country,
      },
    };
    const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
      <div className={styles.autocompleteRoot}>
        <input className="form-control" {...getInputProps()} />
        <div className={styles.autocompleteRootDropdown}>
          {suggestions.map((suggestion) => (
            <div key={suggestion.formattedSuggestion.mainText} {...getSuggestionItemProps(suggestion)} className={styles.autocompleteRootDropdownItem}>
              <span>{suggestion.formattedSuggestion.mainText}</span>
              {' '}
              <span>{suggestion.formattedSuggestion.secondaryText}</span>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <ReactDependentScript
        scripts={[`https://maps.googleapis.com/maps/api/js?libraries=places&language=${language}&key=${process.env.REACT_APP_GOOGLE}`]}
      >
        <div className="fullPageBackground">
          <div className={`${styles.titlePaddingTop} container medium-container pb-5`}>
            {id
              ? (
                <h1 id="name" className="mb-4">
                  {t('edit')}
                  {' '}
                  {name}
                </h1>
              )
              : <h1 className="mb-4">{t('property.createProperty')}</h1>}
            <form className={`${styles.card}`} onSubmit={this.propertySave}>
              <div>
                <label className="mb-2 mt-1" htmlFor="name-input">
                  {t('property.propertyName')}
                  {' '}
                  <span className={styles.required}>*</span>
                </label>
                <input
                  id="name-input"
                  className="col form-control"
                  type="text"
                  value={name}
                  required
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div style={{ display: 'none' }}>
                <label className="mb-2 mt-1" htmlFor>
                  {t('property.doors')}
                  {' '}
                </label>
                <div className="row">
                  {getDoors(id).length > 0
                    ? getDoors(id).map((door) => (
                      <div className="col-md-6" key={door.id}>
                        <DoorMini
                          key={door.id}
                          content={door.name && door.name.length > 20 ? `${door.name.slice(0, 20)}...` : door.name}
                          {...door}
                        />
                      </div>
                    ))
                    : <div style={{ paddingLeft: '16px' }}>{t('property.noDoors')}</div>}
                </div>
              </div>
              <div>
                <label className="mb-2 mt-3" htmlFor="street-input">
                  {t('property.streetAndNumber')}
                  <span className={styles.required}>*</span>
                </label>
                <input
                  id="street-input"
                  className="col form-control"
                  type="text"
                  value={address ? address.street : undefined}
                  onChange={(e) => this.setState({
                    address: {
                      country: address.country,
                      area: address.area,
                      street: e.target.value,
                    },
                  })}
                  required
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="mb-2 mt-3" htmlFor="country-property">
                    {t('property.country')}
                    <span className={styles.required}>*</span>
                  </label>
                  <select
                    className="col form-control"
                    id="country-property"
                    value={address ? address.country : undefined}
                    onChange={this.selectCountry}
                    required
                  >
                    <option value="" disabled />
                    {countryList.map((item) => (
                      <option value={item.value} key={item.value}>{(language === 'en' || language === 'pl') ? item.label : item.translations[language]}</option>))}
                  </select>
                </div>
                <div className="col-6">
                  <label className="mb-2 mt-3" htmlFor="place-autocomplete">
                    {t('property.city')}
                    <span className={styles.required}>*</span>
                  </label>
                  <PlacesAutocomplete
                    id="place-autocomplete"
                    value={address.area}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                    required
                  >
                    {renderInput}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="row">
                <HourInputs
                  className="form-control"
                  required
                  checkInHourChangeHandler={(value) => this.setState({ defaultCheckIn: value })}
                  checkOutHourChangeHandler={(value) => this.setState({ defaultCheckOut: value })}
                  checkInHour={defaultCheckIn}
                  checkOutHour={defaultCheckOut}
                />
              </div>
              {/* description area */}
              <div>
                <label className="mb-2 mt-3" htmlFor="description-input">
                  {t('property.guestInstructions')}
                  {' '}
                  <span className={styles.required}>*</span>
                </label>
                <textarea
                  id="description-input"
                  className="form-control"
                  placeholder={t('property.writeInstructions')}
                  type="text"
                  style={{ minHeight: '130px' }}
                  value={description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </div>
              <p className="mt-4 mb-5">
                <span className={styles.required}>*</span>
                {' '}
                {t('property.requiredFields')}
              </p>
              <div className="row mt-4" style={{ maxWidth: '439px', margin: 'auto' }}>
                <div className="col-6">
                  {boxActivationAddProperty
                    ? (
                      <button
                        id="cancel-button"
                        onClick={() => { window.location = '/host/box/new'; }}
                        className="btn btn-cancel btn-block"
                        type="button"
                      >
                        {t('cancel')}
                      </button>
                    )
                    : (
                      <button
                        id="cancel-button"
                        onClick={() => history.push(`/property/${id}`)}
                        className="btn btn-cancel btn-block"
                        type="button"
                      >
                        {t('cancel')}
                      </button>
                    )}
                </div>
                <div className="col-6">
                  <Button
                    className="btn btn-secondary btn-block"
                    type="submit"
                    value={t('save')}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </ReactDependentScript>
    );
  }
}

const mapStateToProps = (state, props) => ({
  properties: state.properties.properties,
  getProperty: () => getPropertyById(state, parseInt(props.match.params.id, 10)),
  getDoors: (id) => getDoorsByProperty(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  createProperty: (data) => dispatch(createProperty(data)),
  editProperty: (data, id) => dispatch(editProperty(data, id)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateEditProperty));
