import axios from 'axios';
import qs from 'qs';

import { redirectToLogin, getAuthToken, ensureAuth } from './auth';

const defaultOptions = () => ({
  headers: { Authorization: `Bearer ${getAuthToken()}` },
  baseURL: `${process.env.REACT_APP_API_URL}`,
  paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default {
  get: (endpoint: string, params: any) => ensureAuth()
    .then(() => axios.get(endpoint, { ...defaultOptions(), params }))
    .then((response) => response.data)
    .catch((e) => Promise.reject(new Error(e.response.data.message))),
  create: (endpoint: string, params: any, data: any) => ensureAuth()
    .then(() => axios.post(endpoint, data, { ...defaultOptions(), params }))
    .then((response) => response.data)
    .catch((e) => {
      redirectToLogin();
      return Promise.reject(new Error(e.response.data.message));
    }),
  update: (endpoint: string, params: any, data: any) => ensureAuth()
    .then(() => axios.put(endpoint, data, { ...defaultOptions(), params }))
    .then((response) => response.data)
    .catch((e) => {
      redirectToLogin();
      return Promise.reject(new Error(e.response.data.message));
    }),
  remove: (endpoint: string, params: any) => ensureAuth()
    .then(() => axios.delete(endpoint, { ...defaultOptions(), params }))
    .then((response) => response.data)
    .catch((e) => {
      redirectToLogin();
      return Promise.reject(new Error(e.response.data.message));
    }),
};

// import Promise from 'bluebird';
// import axios, { AxiosRequestConfig } from 'axios';
// import qs from 'qs';

// import config from '../config';
// import { redirectToLogin, getAuthToken, ensureAuth } from './auth';

// export type Identifier = string | number;
// export type Record = { id: Identifier, [key: string]: any };
// export type Pagination = { page?: number, perPage?: number };
// export type Sort = { field?: string, order?: string };

// export type SingleResult<RecordType = Record> = { data: RecordType };
// export type MultipleResult<RecordType = Record> = { data: RecordType[], total: number };

// export type GetAllParams = { pagination?: Pagination, sort?: Sort, filter?: any };
// export type GetAllResult<RecordType = Record> = MultipleResult<RecordType>;

// export type GetParams = { id: Identifier };
// export type GetResult<RecordType = Record> = SingleResult<RecordType>;

// export type UpdateParams<T = any> = { id: Identifier, data: T, previousData: Record };
// export type UpdateResult<RecordType = Record> = SingleResult<RecordType>;

// export type CreateParams<T = any> = { data: T };
// export type CreateResult<RecordType = Record> = SingleResult<RecordType>;

// export type RemoveParams = { id: Identifier, previousData: Record };
// export type RemoveResult<RecordType = Record> = SingleResult<RecordType>;

// const isPlainObject = (v: any): v is object => Object
//   .prototype.toString.call(v) === '[object Object]';

// const merge = (a: any, b: any, mergeArrays?: boolean): any => ({
//   ...a,
//   ...(Object.keys(b).reduce((acc, v) => ({
//     ...acc,
//     // eslint-disable-next-line no-nested-ternary
//     [v]: isPlainObject(a[v]) && isPlainObject(b[v])
//       ? merge(a[v], b[v])
//       : (mergeArrays === true && Array.isArray(a[v]) && Array.isArray(b[v])
//         ? [...a[v], ...b[v]]
//         : b[v]),
//   }), {})),
// });

// const flattenObject = (v: any, path: string = ''): any => Object.entries(v)
//   .reduce((acc, [field, value]) => ({
//     ...acc,
//     ...(isPlainObject(value) ? flattenObject(value, path ? `${path}.${field}` : field)
//       : { [path ? `${path}.${field}` : field]: value }),
//   }), {});

// const unflattenObject = (v: any): any => Object.entries(v)
//   .map(([key, value]) => key.split('.').reverse()
//     .reduce((acc, k) => (Object.keys(acc).length === 0 ? { [k]: value } : { [k]: acc }), {}))
//   .reduce((acc, o) => merge(acc, o), {} as any);

// const contained = (a: any[], b: any[]): boolean => a.every((v) => b.includes(v));

// const equalArray = (a: any[], b: any[]): boolean => (a.length === b.length)
//   && contained(a, b);

// const objectDifference = (a: object, b: object): object => {
//   const flat = flattenObject(b);
//   return unflattenObject(Object.entries(flattenObject(a))
//     .filter(([key, value]) => {
//       if (Array.isArray(value)) return !equalArray(value, flat[key]);
//       if (value instanceof Date && flat[key] instanceof Date) {
//         return value.toISOString() !== flat[key].toISOString();
//       }
//       return value !== flat[key];
//     })
//     .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
// };

// // eslint-disable-next-line complexity
// export const baseRequest = (url: string, method: AxiosRequestConfig['method'],
//   params?: any) => Promise.resolve(axios({
//   baseURL: config.REACT_APP_API_URL,
//   url: `${url}${(params || {}).id ? `/${params.id}` : ''}`,
//   method,
//   data: (params || {}).previousData
//     ? objectDifference((params || {}).data || {}, params.previousData)
//     : (params || {}).data,
//   headers: {
//     Authorization: `Bearer ${getAuthToken()}`,
//   },
//   params: {
//     id: (params || {}).ids,
//     ...((params || {}).filter || {}),
//     ...((params || {}).sort ? {
//       sortBy: ((params || {}).sort || {}).field || 'dateCreated',
//       order: ((params || {}).sort || {}).order === 'DESC' ? 'desc' : 'asc',
//     } : {}),
//     ...((params || {}).pagination ? {
//       limit: Math.max(Math.min(((params || {}).pagination || {}).perPage || 100, 100), 1),
//       skip: Math.max((((params || {}).pagination || {}).page || 1) - 1, 1)
//         * Math.max(Math.min(((params || {}).pagination || {}).perPage || 100, 100), 1),
//     } : {}),
//   },
//   paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
// }));

// export const request = (url: string, method: AxiosRequestConfig['method'],
//   params?: any) => baseRequest(url, method, params)
//   .then((response) => ({
//     data: ((response.data || {}).docs || [])
//       .map((d: any, i: number) => ((params || {}).index !== false ? { ...d, index: i + 1 } : d)),
//     total: (response.data || {}).total || 0,
//   }))
//   .tapCatch((e) => (e.response.status === 401 ? redirectToLogin() : undefined));

// export const requestSingle = (url: string, method: AxiosRequestConfig['method'],
//   params?: any) => request(url, method, params)
//   .then(({ data }) => ({ data: data[0] }));

// export default {
//   getAll: <RecordType>(endpoint: string, params: GetAllParams) => ensureAuth()
//     .then<GetAllResult<RecordType>>(() => request(endpoint, 'GET', params)),
//   get: <RecordType>(endpoint: string, params: GetParams) => ensureAuth()
//     .then<GetResult<RecordType>>(() => requestSingle(endpoint, 'GET', params)),
//   create: <RecordType>(endpoint: string, params: CreateParams) => ensureAuth()
//     .then<CreateResult<RecordType>>(() => requestSingle(endpoint, 'POST', params)),
//   update: <RecordType>(endpoint: string, params: UpdateParams) => ensureAuth()
//     .then<UpdateResult<RecordType>>(() => requestSingle(endpoint, 'PUT', params)),
//   remove: <RecordType>(endpoint: string, params: RemoveParams) => ensureAuth()
//     .then<RemoveResult<RecordType>>(() => requestSingle(endpoint, 'DELETE', params)),
// };
