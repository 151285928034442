/* eslint-disable max-classes-per-file */
/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint no-return-assign: "error" */
/* eslint no-param-reassign: ["error", { "props": true,
  "ignorePropertyModificationsFor": ["socket"] }] */

const wsProxyUrl = 'wss://components.homeit.io/box-configuration-ws-proxy/ws';
const globalTimeout = 20000;
// const log = (msg, o) => console.log(msg, o);
const log = () => {};
const retryPromise = (promise, retries = 0) => (retries >= 0
  ? promise().catch(() => retryPromise(promise, retries - 1)) : Promise.reject());

class ConfigWS {
  constructor(wsUri, token, cbError) {
    const statusCallbacks = {};
    const callbacks = {
      status_ok: (args) => {
        log('status.ok', args.msg);
        (statusCallbacks[args.msg] || (() => {}))(undefined);
      },
      status_error: (args) => {
        log('status.error', args.msg);
        (statusCallbacks[args.msg] || (() => {}))(args.error || new Error('System malfunction.'));
      },
    };
    const ws = new Promise((resolve, reject) => {
      const socket = new WebSocket(wsUri || wsProxyUrl, token);
      socket.onopen = (ev) => {
        log('ws.open', ev);
        resolve(socket);
      };
      socket.onclose = (ev) => {
        log('ws.close', ev);
        if (typeof cbError === 'function') cbError();
        reject(ev);
      };
      socket.onerror = (err) => {
        log('ws.error', err);
        if (typeof cbError === 'function') cbError();
        reject(err);
      };
    });
    this.isConnected = () => ws.then((socket) => new Promise((resolve,
      reject) => (socket.readyState === 1 ? resolve() : reject())));
    this.close = () => ws.then((socket) => socket.close());
    ws.then((socket) => {
      socket.onmessage = (data) => {
        log('ws.incoming', data);
        try {
          const parsedData = JSON.parse(data.data);
          log('ws.incoming.unpacked', parsedData);
          (callbacks[parsedData.method] || (() => {}))(parsedData.args);
        } catch (e) {
          log('ws.incoming.unpacked', 'Error unpacking message');
        }
      };
    });
    const send = (method, args) => {
      log('ws.outgoing', method, args || {});
      const packedData = JSON.stringify({ method, ...(args ? { args } : {}) });
      log('ws.outgoing.packed', packedData);
      return ws.then((sock) => {
        log('ws.outgoing.sent', packedData, sock.readyState === 1);
        return sock.send(packedData);
      });
    };
    const registerCallback = (method, cb) => {
      log('register.callback', method);
      callbacks[method] = cb;
    };
    const registerStatus = (method, cb) => {
      log('register.status', method);
      statusCallbacks[method] = cb;
    };
    const methodSync = (method, status, args) => {
      registerStatus(method, status);
      return send(method, args);
    };
    const methodAsync = (method, status, cb, args) => {
      registerCallback(`${method}_reply`, cb);
      return methodSync(method, status, args);
    };
    this.config = {
      ping: (status) => methodSync('config_ping', status),
      stop: (status, cb) => methodAsync('config_end', status, cb),
      info: (status, cb) => methodAsync('config_info', status, cb),
      redirect: (status, cb) => methodAsync('config_redirect', status, cb),
    };
    this.wifi = {
      scan: (status, cb) => methodAsync('wifi_scan', status, cb),
      connect: (ssid, bssid, pass, status, cb) => {
        methodAsync('wifi_scan', status, cb, {
          ...(ssid ? { ssid } : { bssid }),
          ...(pass ? { pass } : {}),
        });
      },
      closeAP: (status) => methodSync('wifi_close_ap', status),
    };
    this.door = {
      create: (status, cb) => methodAsync('door_create', status, cb),
      save: (id, name, status, cb) => methodAsync('door_save', status, cb, { int_id: id, name }),
      info: (status, cb) => methodAsync('door_info', status, cb),
      delete: (id, status) => methodSync('door_del', status, { int_id: id }),
    };
    this.lock = {
      addBLEPairing: (id, status, cb) => methodAsync('lock_add_ble_pairing', status, cb, { int_id: id }),
      add: (id, mac, status, cb) => methodAsync('lock_add', status, cb, { int_id: id, mac }),
      open: (mac, status, cb) => methodAsync('lock_open', status, cb, { mac }),
      close: (mac, status, cb) => methodAsync('lock_close', status, cb, { mac }),
      calibrate: (mac, status, cb) => methodAsync('lock_calibrate', status, cb, { mac }),
      config: (mac, time, status) => methodSync('lock_config', status, { mac, time }),
      info: (id, status, cb) => methodAsync('lock_info', status, cb, { ...(id ? { int_id: id } : {}) }),
      delete: (id, mac, status, cb) => methodAsync('lock_del', status, cb, { ...(id ? { int_id: id } : {}), mac }),
    };
    this.keypad = {
      addBLEPairing: (id, status, cb) => methodAsync('keypad_add_ble_pairing', status, cb, { int_id: id }),
      add: (id, mac, status, cb) => methodAsync('keypad_add', status, cb, { int_id: id, mac }),
      startTest: (mac, status, cb) => methodAsync('keypad_test', status, cb, { mac }),
      stopTest: (mac, status) => methodSync('keypad_test_end', status, { mac }),
      info: (id, status, cb) => methodAsync('keypad_info', status, cb, { ...(id ? { int_id: id } : {}) }),
      delete: (id, mac, status, cb) => methodAsync('keypad_del', status, cb, { ...(id ? { int_id: id } : {}), mac }),
    };
  }
}

const promiseTimeout = (name, promise, timeout = globalTimeout) => Promise.race([promise,
  new Promise((resolve, reject) => setTimeout(() => reject(new Error(`Request timed out: ${name}`)), timeout))]);
const methodMultipleReplies = (name, func, args, cb, cbError, timeout) => promiseTimeout(name,
  new Promise((resolve, reject) => func(...args,
    (err) => {
      log('status', err ? 'success' : err);
      if (err) {
        if (cbError) cbError(err);
        reject(new Error(err));
      } else resolve();
    }, cb)), timeout);
const methodNoReply = (name, func, args = [], timeout) => promiseTimeout(name,
  new Promise((resolve, reject) => func(...args,
    (err) => (err ? reject(new Error(err)) : resolve()))), timeout);
const methodSingleReply = (name, func, args = [], timeout) => promiseTimeout(name,
  new Promise((resolve, reject) => func(...args,
    (err) => (err ? reject(new Error(err)) : undefined), resolve)), timeout);

class Lock {
  constructor(ws, idIn, macIn) {
    const doorId = idIn;
    this.doorId = doorId;
    const mac = macIn;
    this.mac = mac;
    this.open = (cb, cbError) => methodMultipleReplies('Lock open', ws.lock.open, [mac], cb, cbError);
    this.close = (cb, cbError) => methodMultipleReplies('Lock close', ws.lock.close, [mac], cb, cbError);
    this.calibrate = (cb, cbError) => methodMultipleReplies('Lock calibrate', ws.lock.calibrate, [mac], cb, cbError);
    this.config = (time) => methodNoReply('Lock config', ws.lock.config, [mac, time]);
    this.delete = () => methodSingleReply('Lock delete', ws.lock.delete, [doorId, mac]);
    this.deleteAll = () => methodSingleReply('Lock delete from all doors', ws.lock.delete, [null, mac]);
  }
}

class Keypad {
  constructor(ws, idIn, macIn) {
    const doorId = idIn;
    this.doorId = doorId;
    const mac = macIn;
    this.mac = mac;
    this.startTest = (cb, cbError) => methodMultipleReplies('Keypad start test', ws.keypad.startTest, [mac], cb, cbError);
    this.stopTest = () => methodNoReply('Keypad stop test', ws.keypad.stopTest, [mac]);
    this.delete = () => methodSingleReply('Keypad delete', ws.keypad.delete, [doorId, mac]);
    this.deleteAll = () => methodSingleReply('Keypad delete from all doors', ws.keypad.delete, [null, mac]);
  }
}

class Door {
  constructor(ws, idIniIn, idExtIn) {
    const idInt = idIniIn;
    this.idInt = idInt;
    let idExt = idExtIn;
    this.idExt = idExt;
    let locks = [];
    let keypads = [];
    this.save = (name) => methodSingleReply('Door save', ws.door.save, [idInt, name])
      .then((r) => {
        idExt = r.ext_id;
        this.idExt = idExt;
        return { int_id: r.int_id, ext_id: r.ext_id };
      });
    this.delete = () => methodNoReply('Door delete', ws.door.delete, [idInt]);
    this.lock = {
      add: (mac) => methodSingleReply('Lock add', ws.lock.add, [idInt, mac])
        .then((r) => locks[locks.push(new Lock(ws, idInt, r.mac)) - 1]),
      addBLEPairing: (cb, cbError) => methodMultipleReplies('Lock add BLE', ws.lock.addBLEPairing,
        [idInt], (r) => (r.progress === 100
          ? cb(locks[locks.push(new Lock(ws, idInt, r.mac)) - 1]) : cb(r)), cbError, 45000),
      get: (mac) => (mac
        ? Promise.resolve(locks.find((l) => l.mac === mac))
          .then((r) => r || methodSingleReply('Lock get', ws.lock.info, [idInt])
            .then((l) => l.find((d) => d.mac === mac))
            .then((d) => (d ? locks[locks.push(new Lock(ws, idInt, mac)) - 1] : undefined)))
        : methodSingleReply('Lock get', ws.lock.info, [null])
          .then((l) => (locks = l.filter((r) => r.int_id.includes(idInt))
            .map((r) => new Lock(ws, idInt, r.mac))))),
      info: () => methodSingleReply('Lock info', ws.lock.info, [idInt]),
    };
    this.keypad = {
      add: (mac) => methodSingleReply('Keypad add', ws.keypad.add, [idInt, mac])
        .then((r) => keypads[keypads.push(new Keypad(ws, idInt, r.mac)) - 1]),
      addBLEPairing: (cb, cbError) => methodMultipleReplies('Keypad add BLE', ws.keypad.addBLEPairing,
        [idInt], (r) => (r.progress === 100
          ? cb(keypads[keypads.push(new Keypad(ws, idInt, r.mac)) - 1]) : cb(r)), cbError),
      get: (mac) => (mac
        ? Promise.resolve(keypads.find((l) => l.mac === mac))
          .then((r) => r || methodSingleReply('Keypad get', ws.keypad.info, [idInt])
            .then((l) => l.find((d) => d.mac === mac))
            .then((d) => (d ? keypads[keypads.push(new Keypad(ws, idInt, mac)) - 1] : undefined)))
        : methodSingleReply('Keypad get', ws.keypad.info, [null])
          .then((l) => (keypads = l.filter((r) => r.int_id.includes(idInt))
            .map((r) => new Keypad(ws, idInt, r.mac))))),
      info: () => methodSingleReply('Keypad info', ws.keypad.info, [idInt]),
    };
  }
}

class Box {
  constructor(ws, macIn, fwIn, hwIn, ipIn) {
    const fwVersion = fwIn;
    this.fwVersion = fwVersion;
    const hwVersion = hwIn;
    this.hwVersion = hwVersion;
    const ip = ipIn;
    this.ip = ip;
    const mac = macIn;
    this.mac = mac;
    let doors = [];
    this.door = {
      info: () => methodSingleReply('Door info', ws.door.info),
      add: () => methodSingleReply('Door add', ws.door.create)
        .then((r) => doors[doors.push(new Door(ws, r.int_id, r.ext_id)) - 1]),
      get: (id) => (id
        ? Promise.resolve(doors.find((d) => d.idInt === id))
          .then((r) => r || methodSingleReply('Door get', ws.door.info)
            .then((l) => l.find((d) => d.int_id === id))
            .then((d) => (d ? doors[doors.push(new Door(ws, d.int_id, d.ext_id)) - 1] : undefined)))
        : methodSingleReply('Door get', ws.door.info)
          .then((l) => (doors = l.map((d) => new Door(ws, d.int_id, d.ext_id))))),
    };
    this.wifi = {
      scan: () => methodSingleReply('Wifi scan', ws.wifi.scan),
      connect: (ssid, bssid, pass) => methodSingleReply('Wifi connect', ws.wifi.connect, [ssid, bssid, pass]),
      closeAP: () => methodNoReply('Wifi close AP', ws.wifi.closeAP),
    };
    this.info = () => methodSingleReply('Box info', ws.config.info).then((r) => {
      this.fwVersion = r.fw;
      this.hwVersion = r.hw;
      this.ip = r.ip;
      this.mac = r.mac;
      return r;
    });
    this.lock = {
      get: (idInt, lockMac) => methodSingleReply('Lock get', ws.lock.info, [idInt])
        .then((l) => l.find((d) => d.mac === lockMac))
        .then((d) => (d ? new Lock(ws, idInt, lockMac) : undefined)),
      info: () => methodSingleReply('Lock info', ws.lock.info, [undefined]),
    };
    this.keypad = {
      get: (idInt, keypadMac) => methodSingleReply('Keypad get', ws.keypad.info, [idInt])
        .then((l) => l.find((d) => d.mac === keypadMac))
        .then((d) => (d ? new Keypad(ws, idInt, keypadMac) : undefined)),
      info: () => methodSingleReply('Keypad info', ws.keypad.info, [undefined]),
    };
  }
}

class Configuration {
  constructor(wsUri, token, retry) {
    const ws = new ConfigWS(wsUri, token, retry);
    this.ws = () => ws;
    this.box = () => retryPromise(() => methodSingleReply('Config start', ws.config.info, [], 10000), 6)
      .then((r) => new Box(ws, r.mac, r.fw, r.hw, r.ip));
    this.isConnected = () => ws.isConnected();
    this.close = () => ws.close();
    this.finish = () => methodSingleReply('Config finish', ws.config.stop);
    this.redirect = () => methodSingleReply('Config redirect', ws.config.redirect);
    this.ping = () => methodNoReply('Config ping', ws.config.ping);
  }
}

export {
  Configuration,
  Box,
  Door,
  Lock,
  Keypad,
};
