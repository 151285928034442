import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { linkiCalListing, editiCalListing } from '../../../modules/synchronisations';
import PropertyListing from '../../Keys/CreateEditKey/PropertyListing';
import Button from '../../ReusableComponents/Button';
import Card from '../../ReusableComponents/Card/Card';

import style from './iCalListing.module.scss';

export default () => {
  const {
    user: { user: { id: userId } },
  } = useSelector((state) => state);

  const {
    state: {
      doors: doorInit = [],
      propertyId: propId = 0,
      id = 0,
      listingUrl = '',
      name: iCalName,
    },
  } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [resetSelectInputs, setResetSelectInputs] = useState(false);
  const [propertyId, setPropertyId] = useState(propId);
  const [doors, setDoors] = useState(doorInit);
  const [link, setLink] = useState(listingUrl);
  // const [isToggleChecked, setIsToggleChecked] = useState(true);
  // const [checkIn, setCheckIn] = useState(moment());
  // const [checkOut, setCheckOut] = useState(moment().add(2, 'days'));

  const doorSelectHandler = (addDoor, doorIds) => {
    if (addDoor) setDoors([...doors, ...doorIds]);
    else setDoors(doors.filter((door) => !doorIds.includes(door)));
  };

  const iCalSave = async (value) => {
    const source = iCalName.toLowerCase();
    if (value === 'create') {
      await dispatch(linkiCalListing({
        propertyId, doors, source, link, userId,
      }));
    }

    if (value === 'edit') {
      await dispatch(editiCalListing({
        propertyId, doors, source, link, userId, id,
      }));
    }

    return history.push({
      pathname: `/synchronisations/ical/${iCalName}`,
    });
  };

  return (
    <div id="listContainer" className="container mb-4">
      <div style={{ marginBottom: '32px' }}>
        <h1>
          {t('synchronisation.sync')}
          {' '}
          { iCalName }
        </h1>
      </div>
      <Card>
        <div className="bg-white" id={style.wrapper}>
          <div className="form-group">
            <label>{t('synchronisation.linkIcal')}</label>
            <input type="text" className="form-control" defaultValue={link} onChange={(e) => setLink(e.target.value)} placeholder="" />
          </div>
          <h4 style={{
            fontSize: '13px', color: '#6C727E', marginBottom: '32px', fontWeight: '100',
          }}
          >
            {t('synchronisation.enterIcalLink')}
          </h4>
          {/* Property */}
          <PropertyListing
            keyType="guest"
            selectedDoors={doors || []}
            doorSelectHandler={doorSelectHandler}
            resetSelectInputs={resetSelectInputs}
            resetSelectInputsHandler={(value) => setResetSelectInputs(value)}
            deleteColumn={false}
            // setDefaultTime={(newCheckIn, newCheckOut) => { setCheckIn(newCheckIn); setCheckOut(newCheckOut); }}
            setDefaultTime={() => {}}
            propertyIdHandler={(newPropertyId) => setPropertyId(newPropertyId)}
          />
          {/* <hr />
                  <div style={{ display: 'grid', gridTemplateColumns: '160px 1fr', marginTop: '32px' }}>
                    <p style={{ lineHeight: '35px', fontSize: '16px', fontWeight: '100' }}>{t('synchronisation.automaticKeys')}</p>
                    <Toggle
                      isToggleChecked={this.state.isToggleChecked}
                    />
                  </div> */}
          <div className="row mt-5" style={{ maxWidth: '438px', margin: 'auto' }}>
            <div className="col-6">
              <button
                className="btn btn-cancel btn-block"
                type="button"
                onClick={() => history.goBack()}
              >
                {t('cancel')}
              </button>
            </div>
            {listingUrl
              ? (
                <div className="col-6">
                  <Button
                    className="btn btn-secondary btn-block"
                    type="button"
                    onClick={() => iCalSave('edit')}
                    value={t('save')}
                  />
                </div>
              )
              : (
                <div className="col-6">
                  <Button
                    className="btn btn-secondary btn-block"
                    type="button"
                    onClick={() => iCalSave('create')}
                    value={t('save')}
                  />
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};
