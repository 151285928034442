import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import style from '../Main.module.scss';

import { editKey } from '../../../modules/keys';
import CalendarView from '../../Calendar/Calendar';
import Card from '../../ReusableComponents/Card/Card';

export class Recover extends React.Component {
  state = {
    date: [moment().toDate(), moment().add(1, 'days').toDate()],
    checkInHour: moment().format('HH'),
    checkOutHour: moment().add(3, 'hours').format('HH'),
    id: this.props.match ? this.props.match.params.id : null,
  };

  keySubmitHandler = async () => {
    const {
      date, checkInHour, checkOutHour, id,
    } = this.state;

    date[0].setHours(checkInHour);
    date[1].setHours(checkOutHour);

    date[0] = moment(date[0]).startOf('hour');
    date[1] = moment(date[1]).startOf('hour');

    await this.props.editKey({
      checkIn: date[0].toISOString(), checkOut: date[1].toISOString(),
    }, parseInt(id, 0));
    return this.props.history.push({
      pathname: '/keys',
      search: `?keyId=${id}`,
    });
  };

  render() {
    const { checkInHour, checkOutHour } = this.state;
    const { t } = this.props;
    return (
      <div className="fullPageBackground">
        <div className="container pb-5">
          <div className="medium-container">
            <h1 className={style.titleTopPadding} style={{ paddingLeft: '5px' }}>{t('key.recoverKey')}</h1>
            <div className={`${style.cardMarginTop}`}>
              <Card>
                <div className="">
                  <CalendarView
                    dateChangeHandler={(date) => this.setState({ date })}
                    date={this.state.date}
                    checkInHourChangeHandler={(hour) => this.setState({ checkInHour: hour })}
                    checkInHour={checkInHour}
                    checkOutHourChangeHandler={(hour) => this.setState({ checkOutHour: hour })}
                    checkOutHour={checkOutHour}
                  />
                  <div className="row mt-5 button-container">
                    <div className="col-6">
                      <button onClick={() => this.props.history.push('/keys')} className="btn btn-cancel btn-block">{t('cancel')}</button>
                    </div>
                    <div className="col-6">
                      <button
                        id="key-submit"
                        className="btn btn-secondary btn-block"
                        onClick={() => this.keySubmitHandler()}
                      >
                        {t('key.recoverKey')}
                      </button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  editKey: (data, id) => dispatch(editKey(data, id)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Recover));
