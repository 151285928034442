// REACT
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';

// STORE
import { store } from './store';

// APP
import App from './components/App';

// STYLING
import './global_style.scss';

import * as serviceWorker from './serviceWorker';

// TRANSLATIONS
import './utils/i18n';

// FONTS
WebFont.load({
  google: {
    families: ['Quicksand:300,400,500,700', 'Hind Madurai:300,400,500,600,700'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
