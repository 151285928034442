import styled from 'styled-components';
import { colors } from '../../../variables';

export const MainHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    > div {
        color: ${colors.$white};
    }
`;

export const BtnRefresh = styled.button`
    background-color: ${colors.$secondary};
    box-shadow: 0 7px 8px -2px rgba(255,0,95,0.38);
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    outline: none!important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const FormSearch = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    input {
        border: 1px solid ${colors.$white};

        &::placeholder { 
            color: ${colors.$greyLight};
            opacity: 1; 
        }
        
        &:-ms-input-placeholder {
            color: ${colors.$greyLight};
        }
        
        &::-ms-input-placeholder { 
            color: ${colors.$greyLight};
        }
    }
`;

export const BtnFilters = styled.div`
    button:first-child {
        display: block;
    }
    button:last-child {
        display: none;
    }
    @media only screen and (max-width: 767px) {
        button:first-child {
            display: none;
        }
        button:last-child {
            display: block;
            box-shadow: none;
            margin: 0;
            padding: 0;
            float: right;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
`;

export const FiltersHeader = styled.ul`
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 10px;

    &:before {
        content: '';
        height: 1px;
        width: 115%;
        position: absolute;
        background-color:  ${colors.$greyLight};
        bottom: 1.5px;    
        left: -35px;
    }

    li { 
        padding: 10px;
        font-weight: 500;
        z-index: 1;
        cursor: pointer;
        @media only screen and (min-width: 867px) { 
            &:first-child {
                margin-left: 10%;
                margin-right: 10%;
            }
            &:last-child {
                margin-left: 10%;
                margin-right: 10%;
            }
        }

        &.active {
            border-bottom: 3px solid ${colors.$secondary};
        }
    }
`;

export const FiltersApplied = styled.ul`
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
        height: auto;
        border-radius: 32px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        padding: 5px 6px 5px 14px;


        @media only screen and (max-width: 767px) {
            margin: 5px 10px;
        }


        button {
            color: ${colors.$white};
            background-color: ${colors.$secondary};
            padding: 1px 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: 10px;
            outline: none;
            border: none;
        }
    }
`;
