import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';

import { ReactComponent as IconNukiSetup } from '../../../assets/images/box-activation/icon-nuki-setup.svg';

export default () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState('50%');
  const history = useHistory();

  const doorCalibrating = () => {
    getBox().then((b) => b.lock
      .get(window.localStorage.getItem('currentDoor'), window.localStorage.getItem('smartlockMac')))
      .then((lock) => {
        setProgress('50%');
        return new Promise((resolve, reject) => lock.calibrate((res) => {
          if (res.progress === 7) setProgress('75%');
          if (res.progress === 100) {
            setProgress('100%');
            setTimeout(() => resolve(lock), 500);
          }
        }, reject).catch(reject));
      })
      .then((lock) => lock.config(15 * 60))
      .then(() => history.push({ pathname: '/box/activation/lock/nuki/test' }))
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/lock/nuki/pairing/calibrating',
            errorMessage: String(err),
          },
        });
      });
  };

  useEffect(() => doorCalibrating(), []); // run only once

  return (
    <div className="activation-section" id="door-calibrating">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-6 col-lg-4">
            <div className="wrapper-icon text-center"><IconNukiSetup alt="smartlock setup" style={{ width: '144px' }} /></div>
            <div className="progress formName">
              <div className="progress-bar progress-bar-striped bg-homeit progress-bar-animated" role="progressbar" style={{ width: progress }} aria-valuenow="50" aria-valuemin="50" aria-valuemax="100">{progress}</div>
            </div>
            <p className="formDescriptionTxt">{t('boxActivation.door.waitDescription')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
