import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import API from '../utils/api';
import { ensureAuth, redirectToLogin } from '../utils/auth';

const initialState = {
  pending: false,
  success: false,
  user: {},
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    apiPending: (state) => { state.pending = !state.pending; },
    apiSuccess: (state) => { state.success = !state.success; },
    GET: (state, { payload }) => {
      const { sub } = jwtDecode(Cookies.get('authToken'));
      state.user = { ...state.user, ...payload.find((u) => u.id === sub) };
      state.users = [...payload];
    },
    PUT: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
});

export const getUsers = () => async (dispatch) => {
  try {
    const allUsers = [];
    const getAllUsers = async (skip = 0) => API.get(`${process.env.REACT_APP_AUTH_BASE_URL}/users?skip=${skip}`).then((res) => {
      allUsers.push(...res.docs);
      if (res.total > (res.limit + res.skip)) {
        return getAllUsers(res.skip + res.limit);
      }
      return allUsers;
    });
    const docs = await getAllUsers().then((result) => result);

    dispatch(user.actions.apiPending(true));
    dispatch(user.actions.GET(docs));
    dispatch(user.actions.apiPending(false));
    dispatch(user.actions.apiSuccess(true));
    return docs;
  } catch (err) {
    return null;
  }
};

export const updateSettings = (settings, userId, props) => async (dispatch) => ensureAuth()
  .then(() => API.update(`${process.env.REACT_APP_AUTH_BASE_URL}/users/${userId}`, {}, settings))
  .then(({ docs: [userData] }) => {
    dispatch(user.actions.PUT(userData));
    return props.history.push('/settings');
  })
  .catch(() => redirectToLogin());

export { user };
