import { createSlice } from '@reduxjs/toolkit';

import API from '../utils/api';

const lowLevelThreshold = (obj) => {
  const minimumBatteryLevel = 30;
  const { type, batteryLevel } = obj;
  obj.lowBattery = type === 'ble' && batteryLevel <= minimumBatteryLevel && batteryLevel > 0;
  return obj;
};

const initialState = {
  pending: false,
  success: false,
  keypads: [],
};

const keypads = createSlice({
  name: 'keypads',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    GET: (state, action) => {
      const newKeypads = action.payload.map((keypad) => lowLevelThreshold(keypad));
      state.keypads.push(...newKeypads);
    },
  },
});

const getAllKeypads = async (dispatch, skipValue = 0) => {
  try {
    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = await API.get('keypads', { limit: 100, skip: skipValue });
    dispatch(keypads.actions.GET(docs));
    if (next) await getAllKeypads(dispatch, s + l);
    return docs;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getKeypads = () => async (dispatch) => {
  try {
    dispatch(keypads.actions.apiPending());
    const res = await getAllKeypads(dispatch);
    dispatch(keypads.actions.apiPending());
    dispatch(keypads.actions.apiSuccess());
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getKeypadById = (state, id) => state.keypads.keypads
  .find((keypad) => keypad.id === id);

export { keypads };
