import { createSlice } from '@reduxjs/toolkit';
import API from '../utils/api';

const initialState = {
  pending: false,
  success: false,
  doors: [],
};

const doors = createSlice({
  name: 'doors',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    GET: (state, action) => { state.doors.push(...action.payload); },
  },
});

export const getAllDoors = async (dispatch, skip = 0) => {
  try {
    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = await API.get('doors', { limit: 100, skip });
    dispatch(doors.actions.GET(docs));
    if (next) return getAllDoors(dispatch, s + l);
    return docs;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getDoors = () => async (dispatch) => {
  try {
    dispatch(doors.actions.apiPending(true));
    const res = await getAllDoors(dispatch);
    dispatch(doors.actions.apiPending(false));
    dispatch(doors.actions.apiSuccess(true));
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const editDoor = (data, id) => async (dispatch) => {
  try {
    const res = await API.update(`doors/${id}`, {}, data);
    dispatch(doors.actions.PUT(res.docs[0]));
    return res.docs[0];
  } catch (err) {
    return console.log(err);
  }
};

// export const getDoorsByProperty = (state, props) => state.doors.doors
//   .filter((door) => door.property === props.propertyId);

export const getDoorsByProperty = (state, id) => state.doors.doors
  .filter((door) => door.property === id)
  .sort((a, b) => a.name.localeCompare(b.name));

export const getDoorById = (state, id) => state.doors.doors
  .find((door) => door.id === id);

export { doors };
