import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconConnectionError } from '../../assets/images/box-activation/icon-connection-error.svg';

export default () => {
  const location = useLocation();

  const { t } = useTranslation();
  return (
    <div className="activation-section" id="intercom-connection-error">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <div className="wrapper-icon text-center description-txt"><IconConnectionError alt="connection error" style={{ width: '190px' }} /></div>
            <div className="description-txt">
              <h1>{t('boxActivation.intercom.errorTitle')}</h1>
              {location.state && location.state.errorMessage
                ? <h4>{location.state.errorMessage}</h4>
                : <h4>{t('boxActivation.door.errorPairingSubtitle')}</h4>}
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center text-center description-txt">
          <div className="col col-md-6 col-lg-4 formName">
            {location.state && location.state.redirectUrl
              ? <Link className="btn btn-primary" to={location.state.redirectUrl}>{t('boxActivation.intercom.buttonTry')}</Link>
              : <Link className="btn btn-primary" to="/box/activation/start">{t('boxActivation.intercom.buttonTry')}</Link>}
            <br />
            <br />
            {location.state.errorLink
              ? <a style={{ display: 'block', textAlign: 'right' }} target="_blank" rel="noopener noreferrer" href={location.state.errorLink} className="btn btn-link">{t('boxActivation.door.skipSmartlock')}</a>
              : null}
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-8 col-lg-6">
              <p className="formDescriptionTxt">
                <a href="https://homeit.freshdesk.com/support/tickets/new"> </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
