import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import styles from '../Property/Property.module.scss';

import { ReactComponent as DoorsIcon } from '../../../assets/images/filled/doors.svg';
import { ReactComponent as KeysIcon } from '../../../assets/images/filled/keys.svg';
import { ReactComponent as BookingsIcon } from '../../../assets/images/filled/bookings.svg';
import { ReactComponent as BoxOfflineIcon } from '../../../assets/images/solid/offenergy.svg';

import { getDoorsByProperty } from '../../../modules/doors';
import { getKeysByProperty } from '../../../modules/keys';
import { getPropertyById } from '../../../modules/properties';
import { getBoxById } from '../../../modules/boxes';

export const PropertyMiniDesktop = ({
  id,
  getDoors,
  getKeys,
  getProperty,
  getBox,
  history,
  t,
}) => {
  const { name, address } = getProperty();

  return (
    <div className={`${styles.header}`}>
      <div
        className="property-header col-8 col-md-4"
        // removing the pointer cursor also removes it from the main properties page
        // because this component is used in both pages
        style={{
          padding: '0 15px 0 0', textDecoration: 'none', color: '#0A1428', cursor: 'pointer',
        }}
        onClick={() => history.push({ pathname: `/property/${id}`, params: { id } })}
        id="property-link"
      >
        {!(getDoors()
          .map((door) => (getBox(door.box) || {}).isOnline)
          .reduce((acc, val) => acc && val, true))
          && (
            <div className="" id="box-offline-message" style={{ marginBottom: '6px' }}>
              <BoxOfflineIcon className={styles.boxOfflineIcon} />
              <span className={styles.lowBatteryText}>{t('property.boxOffline')}</span>
            </div>
          )}
        <h5 id="property-name" style={{ fontWeight: '700' }}>{ name }</h5>
        {address
          && (
          <div className="row" style={{ margin: '0px', fontSize: '13px' }}>
            <div id="street-address">
              {`${[
                (address.street || address.streetAddress),
                (address.area || address.city),
                address.country,
              ].filter((val) => val != null && val.search(/^$|^\s+$/) === -1).join(', ')}`}
            </div>
          </div>
          )}
      </div>
      <div className={`col-4 ${styles.linksFlex} order-md-2 col-md-2`} style={{ padding: '0px', marginLeft: '0px' }}>
        <>
          <Link
            to={{
              pathname: '/create-key',
              state: {
                doors: getDoors()
                  .filter(({ box }) => (getBox(box) || {}).isOnline)
                  .map((door) => door.id),
              },
            }}
            className={`${styles.btnCreateKey}`}
          />
        </>
        <>
          <Link
            to={{ pathname: `/property/edit/${id}`, params: { id } }}
          >
            <div className={`${styles.btnEditProperty}`} />
          </Link>
        </>
      </div>
      <div
        className={`${styles.iconContainer} col col-md-6 col-sm-12`}
        style={{ paddingLeft: '0px', cursor: 'pointer' }}
        id="property-link-two"
        onClick={() => history.push({ pathname: `/property/${id}`, params: { id } })}
      >
        <div className={`${styles.iconContainerMargin} ${styles.propertyDetailsRow}`}>
          <div style={{ marginRight: '20px' }}>
            <p className={styles.iconText} id="door-length">
              <DoorsIcon className={styles.icon} />
              {getDoors().length}
              <span className={styles.iconName}>{getDoors().length !== 1 ? t('doors') : t('singleDoor')}</span>
            </p>
          </div>
          <div style={{ marginRight: '20px' }}>
            <p className={styles.iconText} id="current-key-length">
              <KeysIcon className={styles.icon} />
              {getKeys(['current', 'permanent']).length}
              <span className={styles.iconName}>{ getKeys(['current', 'permanent']).length !== 1 ? t('keys') : t('singleKey') }</span>
            </p>
          </div>
          <div>
            <p className={styles.iconText} id="future-key-length">
              <BookingsIcon className={styles.icon} />
              {getKeys(['future']).length}
              <span className={styles.iconName}>{getKeys(['future']).length !== 1 ? t('bookings') : t('singleBookings')}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  getDoors: () => getDoorsByProperty(state, props.id),
  getKeys: (durationTypeArray) => getKeysByProperty(state, props.id, durationTypeArray),
  getProperty: () => getPropertyById(state, props.id),
  getBox: (id) => getBoxById(state, id),
});

const mapDispatchToProps = () => ({
});

export default withRouter(withTranslation()(connect(mapStateToProps,
  mapDispatchToProps)(PropertyMiniDesktop)));
