import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as luxon from 'luxon';

import { createKey, editKey, deleteKey } from '../../../modules/keys';
import CalendarView from '../../Calendar/Calendar';
import PropertyListing from './PropertyListing';
import Button from '../../ReusableComponents/Button';
import Card from '../../ReusableComponents/Card/Card';
import KeyLimitModal from '../../ReusableComponents/KeyLimitModal/KeyLimitModal';
import { AlertConfirm, AlertConfirmCancel } from '../../../utils/alerts';

import style from './CreateEditKey.module.scss';

import { ReactComponent as DeleteIcon } from '../../../assets/images/solid/bin.svg';

// import 'intl-tel-input/build/css/intlTelInput.css';

const KeyExpiringSelector = ({ isExpiring, handler, t }) => (
  <fieldset className={style.KeyExpiringSelector}>
    <div className="row">
      <div className="col-6 selection">
        <label className="radio-container" htmlFor="radio" style={{ fontSize: '13px', margin: '0' }}>
          <input
            type="radio"
            name="radio"
            checked={isExpiring}
            onChange={() => handler(true)}
          />
          <span className="radio-checkmark" style={{ top: '-3px' }} />
          {t('key.temporaryKey')}
        </label>
      </div>
      <div className="col-6 selection">
        <label className="radio-container" htmlFor="radio" style={{ fontSize: '13px', margin: '0' }}>
          <input
            type="radio"
            name="radio"
            checked={!isExpiring}
            onChange={() => handler(false)}
          />
          <span className="radio-checkmark" style={{ top: '-3px' }} />
          {t('key.permanentKey')}
        </label>
      </div>
    </div>
  </fieldset>
);

class CreateEditKey extends React.Component {
  state = {
    keyType: 'guest',
    resetSelectInputs: false,
    timezone: null,
    // TODO: lazy way of setting default times (when no property is selected.)
    checkIn: moment().hour(14).minute(0)
      .second(0)
      .millisecond(0),
    checkOut: moment().add(2, 'days').hour(11).minute(0)
      .second(0)
      .millisecond(0),
    isExpiring: true,
    doors: (this.props.location.state || {}).doors ? [...this.props.location.state.doors] : [],
    canUse: ['keypad', 'sms', 'app'],
    keyDataChanged: false,
    name: '',
    // showPermissionsModal: false,
    showKeyLimitModal: false,
    hasUpdated: false,
    ...this.props.getKey(this.props.match.params.id),
  };

  componentDidMount() {
    const {
      doors,
      timezone,
      checkIn,
      checkOut,
    } = this.state;
    const { getDoorById, getPropertyById } = this.props;
    window.scrollTo(0, 0);
    if (doors[0]) {
      const cloneDoors = [];
      doors.forEach((item) => {
        if (getDoorById(item)) cloneDoors.push(item);
      });
      this.setState({
        doors: cloneDoors,
        hasUpdated: true,
      });
      if (!timezone) {
        const property = getPropertyById(getDoorById(cloneDoors).property);
        this.setState({
          checkIn: moment(luxon.DateTime.fromISO(moment(checkIn).toISOString(), { zone: property.timezone }).toJSDate()),
          checkOut: moment(luxon.DateTime.fromISO(moment(checkOut).toISOString(), { zone: property.timezone }).toJSDate()),
          timezone: property.timezone || 'Etc/UTC',
        });
      }
    } else {
      this.setState({
        hasUpdated: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.keyDataChanged) this.setState({ keyDataChanged: true });
  }

  doorSelectHandler = (addDoor, doorIds) => {
    const {
      doors,
      timezone,
      checkIn,
      checkOut,
    } = this.state;
    const {
      getDoorById,
      getPropertyById,
    } = this.props;
    if (addDoor) {
      if (doorIds.length && !timezone) {
        const property = getPropertyById(getDoorById([...doors, ...doorIds][0]).property);
        this.setState({
          doors: [...doors, ...doorIds],
          checkIn: moment(luxon.DateTime.fromISO(moment(checkIn).toISOString(), { zone: property.timezone }).toJSDate()),
          checkOut: moment(luxon.DateTime.fromISO(moment(checkOut).toISOString(), { zone: property.timezone }).toJSDate()),
          timezone: property.timezone,
        });
      } else {
        this.setState({
          doors: [...doors, ...doorIds],
        });
      }
    } else {
      this.setState({
        doors: doors.filter((door) => !doorIds.includes(door)),
        timezone: null,
      });
    }
  };

  isKeySavePermitted = () => {
    const {
      keys,
      keysSuccess,
      canCreateMultipleKeys,
    } = this.props;
    if (canCreateMultipleKeys()) {
      this.keySave();
    } else if (keys
      .filter((key: any) => new Date(key.checkOut).getTime() > Date.now()).length >= 12) {
      this.setState({ showKeyLimitModal: true });
    } else if (keysSuccess) this.keySave();
  };

  // eslint-disable-next-line complexity
  keySave = async () => {
    const {
      keyType,
      checkIn,
      checkOut,
      isExpiring,
      doors,
      canUse,
      name,
      timezone,
      tags,
    } = this.state;
    let { id } = this.state;
    const {
      createKey: create,
      editKey: edit,
      history,
      t,
    } = this.props;

    if (doors.length === 0) {
      return AlertConfirm.fire({ text: t('key.warnSelectDoor'), icon: 'warning' });
    }
    if (moment(checkIn).isSameOrAfter(checkOut)) {
      return AlertConfirm.fire({ text: t('key.warnCheckinAfterCheckout'), icon: 'warning' });
    }
    if (moment().isSameOrAfter(checkOut)) {
      return AlertConfirm.fire({ text: t('key.warnCheckoutBeforeNow'), icon: 'warning' });
    }

    let proceed = true;
    if (name === '') {
      const { value } = await AlertConfirmCancel.fire({
        icon: 'question',
        text: t('key.warnKeyNoName'),
        confirmButtonText: t('key.buttonYes'),
        cancelButtonText: t('key.buttonNo'),
      });
      proceed = value;
    }

    if (proceed) {
      const dataToSend = {
        name,
        checkIn: isExpiring ? luxon.DateTime.fromJSDate(moment(checkIn).toDate(), { zone: timezone }).toISO() : undefined,
        checkOut: isExpiring ? luxon.DateTime.fromJSDate(moment(checkOut).toDate(), { zone: timezone }).toISO() : undefined,
        isExpiring,
        doors,
        canUse,
        tags: id ? tags : [keyType],
      };

      try {
        if (id) {
          await edit(dataToSend, id);
        } else {
          const createdKey = await create(dataToSend);
          id = createdKey.id;
        }
        return history.push({
          pathname: '/keys',
          search: `?keyId=${id}`,
        });
      } catch (err) {
        return AlertConfirm.fire({ text: 'An error has ocurred', icon: 'error' });
      }
    }
    return undefined;
  };

  keyTypeHandler = (keyType) => {
    if (keyType === 'guest') {
      this.setState({
        keyType,
        isExpiring: true,
        // doors: [],
        // resetSelectInputs: true,
      });
    } else {
      this.setState({
        keyType,
        isExpiring: false,
      });
    }
  };

  keyDelete = async () => {
    const {
      getDoorById,
      getBoxById,
      deleteKey: del,
      t,
    } = this.props;
    const { doors } = this.state;

    const boxes = [...new Set(doors.map((id) => (getDoorById(id) || {}).box))];
    const boxObjs = boxes.map((id) => getBoxById(id) || {});
    const allBoxesOnline = boxObjs
      .map(({ isOnline }) => isOnline)
      .reduce((acc, cur) => acc && cur, true);
    // console.log('anyBoxOffline: ', allBoxesOnline);

    const { value } = allBoxesOnline
      ? (await AlertConfirmCancel.fire({
        icon: 'warning',
        // title: 'Delete Key?',
        text: t('key.warnDeleteKey'),
        confirmButtonText: t('key.buttonYes'),
        cancelButtonText: t('key.buttonNo'),
      }))
      : (await AlertConfirm.fire({ icon: 'error', text: t('key.errorDeleteKey') }));

    if (value && allBoxesOnline) {
      await del(parseInt(this.state.id, 0))
        .then(() => this.props.history.push('/keys'))
        .then(() => AlertConfirm.fire({
          icon: 'success',
          text: t('key.successDeleteKey'),
        }));
    }
  };

  dateChangeHandlerFunc = (date) => {
    this.setState({
      checkIn: moment(this.state.checkIn).set({
        year: moment(date[0]).get('year'),
        month: moment(date[0]).get('month'),
        date: moment(date[0]).get('date'),
      }),
      checkOut: moment(this.state.checkOut).set({
        year: moment(date[1]).get('year'),
        month: moment(date[1]).get('month'),
        date: moment(date[1]).get('date'),
      }),
    });
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      hasUpdated,
      keyType,
      id,
      checkOut,
      checkIn,
      name,
      isExpiring,
      doors,
      tags,
    } = this.state;
    const { keys, t } = this.props;
    return (
      <>
        {hasUpdated && (
        <div className="fullPageBackground">
          {this.state.showKeyLimitModal
            && (
            <KeyLimitModal
              click={() => {
                this.setState({ showKeyLimitModal: false });
                if (keys.length < 10) this.keySave();
              }}
            />
            )}
          <div className={`${style.titleTopPadding} container medium-container`} id={style.container}>
            {id
              ? (
                <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
                  <h1
                    className="mb-4 col-8 col-md-10"
                  >
                    {t('key.editKey')}
                  </h1>
                  <div
                    className="col-4 col-md-2 pr-0"
                    style={{ cursor: 'pointer' }}
                    onClick={this.keyDelete}
                  >
                    <DeleteIcon className={style.iconDelete} />
                    <span className="text-secondary" style={{ fontWeight: '700' }}>{t('delete')}</span>
                  </div>
                </div>
              )
              : <h1 className="mb-4">{t('key.createKey')}</h1>}
            <div className="pb-5">
              <Card>
                {/* key type section */}
                <div className="form-group">
                  <label htmlFor="keyTypeSelect">{t('key.keyType')}</label>
                  <select
                    disabled={!!id}
                    value={tags ? tags.find((element) => element === 'guest'
                      || element === 'services'
                      || element === 'manager'
                      || (id && element === 'integration')) : keyType}
                    className="form-control"
                    id="keyTypeSelect"
                    onChange={(e) => this.keyTypeHandler(e.target.value)}
                  >
                    <option value="guest">{t('guest')}</option>
                    <option value="services">{t('services')}</option>
                    <option value="manager">{t('manager')}</option>
                    {id && <option value="integration">{t('guest')}</option>}
                  </select>
                </div>
                {/* Username */}
                <div className="form-group">
                  <label htmlFor="keyUsernameInput">
                    {t('key.name')}
                  </label>
                  <input type="text" className="form-control" id="keyUsernameInput" defaultValue={name} onChange={(e) => this.setState({ name: e.target.value })} placeholder="" />
                </div>
                {/* Property */}
                <PropertyListing
                  keyType={keyType}
                  selectedDoors={doors}
                  doorSelectHandler={this.doorSelectHandler}
                  resetSelectInputs={this.state.resetSelectInputs}
                  resetSelectInputsHandler={(value) => this.setState({ resetSelectInputs: value })}
                  deleteColumn
                  setDefaultTime={(checkInHour, checkOutHour) => (id ? null : this.setState({
                    checkIn: moment(checkIn).set('hour', (checkInHour / 60) + 1),
                    checkOut: moment(checkOut).set('hour', (checkOutHour / 60) + 1),
                  }))}
                />

                {/* <div className="form-group">
                  <label htmlFor="allowSelect">{t('key.allow')}</label>
                  <div className={`row ${style.selectPropertyKey}`}>
                    <div key={'checkbox-allow-keypad'} className="checkbox-grid col-12 col-sm-4">
                      <label className="checkbox-label" style={{ margin: '0px' }} htmlFor={'checkbox-allow-keypad'}>
                        <input
                          defaultChecked={true}
                          onChange={(e) => allowSelectHandler(e.target.checked, 'keypad')}
                          type="checkbox"
                          id={'checkbox-allow-keypad'}
                        />
                        <span className="checkbox-custom" />
                      </label>
                      <p className="checkbox-text">{'Keypad'}</p>
                    </div>
                    <div key={'checkbox-allow-app'} className="checkbox-grid col-12 col-sm-4">
                      <label className="checkbox-label" style={{ margin: '0px' }} htmlFor={'checkbox-allow-app'}>
                        <input
                          defaultChecked={true}
                          onChange={(e) => allowSelectHandler(e.target.checked, 'app')}
                          type="checkbox"
                          id={'checkbox-allow-app'}
                        />
                        <span className="checkbox-custom" />
                      </label>
                      <p className="checkbox-text">{'App'}</p>
                    </div>
                    <div key={'checkbox-allow-sms'} className="checkbox-grid col-12 col-sm-4">
                      <label className="checkbox-label" style={{ margin: '0px' }} htmlFor={'checkbox-allow-sms'}>
                        <input
                          defaultChecked={true}
                          onChange={(e) => allowSelectHandler(e.target.checked, 'sms')}
                          type="checkbox"
                          id={'checkbox-allow-sms'}
                        />
                        <span className="checkbox-custom" />
                      </label>
                      <p className="checkbox-text">{'SMS'}</p>
                    </div>
                  </div>
                </div> */}

                <KeyExpiringSelector
                  handler={(value) => this.setState({ isExpiring: value })}
                  isExpiring={isExpiring}
                  t={t}
                />

                {isExpiring
                  && (
                  <CalendarView
                    t={t}
                    id={id}
                    dateChangeHandler={(date) => this.dateChangeHandlerFunc(date)}
                    date={[new Date(this.state.checkIn), new Date(this.state.checkOut)]}
                    checkInHourChangeHandler={(hour) => this.setState({ checkIn: moment(checkIn).set('hour', hour) })}
                    checkInHour={moment(this.state.checkIn).format('HH')}
                    checkOutHourChangeHandler={(hour) => this.setState({ checkOut: moment(checkOut).set('hour', hour) })}
                    checkOutHour={moment(this.state.checkOut).format('HH')}
                  />
                  )}
                <div className="row mt-5 button-container">
                  <div className="col-6">
                    <button onClick={() => this.props.history.goBack()} className="btn btn-cancel btn-block">{t('cancel')}</button>
                  </div>
                  <div className="col-6">
                    {id
                      ? (
                        <Button
                          disabled={!this.state.keyDataChanged}
                          className="btn btn-secondary btn-block"
                          onClick={() => this.keySave()}
                          value={t('key.save')}
                        />
                      )
                      : (
                        <Button
                          disabled={!this.state.keyDataChanged}
                          className="btn btn-secondary btn-block"
                          onClick={() => this.isKeySavePermitted()}
                          value={t('key.createKey')}
                        />
                      )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  keys: state.keys.keys,
  keysSuccess: state.keys.success,
  services: state.user.user,
  getDoorById: (id) => state.doors.doors.find((door) => door.id === parseInt(id, 0)),
  getPropertyById: (id) => state.properties.properties
    .find((property) => property.id === parseInt(id, 0)),
  getBoxById: (id) => state.boxes.boxes.find((box) => box.id === parseInt(id, 0)),
  getKey: (id) => state.keys.keys.find((key) => key.id === parseInt(id, 0)),
  canCreateMultipleKeys: () => state.billing.customer.services.includes('service-keys-unlimited'),
  // canCreateMultipleKeys: () => true,
});

const mapDispatchToProps = (dispatch) => ({
  createKey: (data) => dispatch(createKey(data)),
  editKey: (data, id) => dispatch(editKey(data, id)),
  deleteKey: (id) => dispatch(deleteKey(id)),
});

export default withTranslation()(withRouter(connect(mapStateToProps,
  mapDispatchToProps)(CreateEditKey)));
