import styled from 'styled-components';
import { colors } from '../../../variables';

export const ActivitySection = styled.div`
    display: ${(props: any) => (props.visible ? 'flex' : 'none')};
    margin: auto;
    max-width: 710px;
    margin-bottom: 14px;
    jusfity-content: space-between;
    position: relative;
`;

export const TimeLine = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    font-size: 13px;
    flex: 0 0 100px;
    height: 100%;
    max-width: 59px;

    @media only screen and (min-width: 900px) { 
        max-width: 100%;
    }
    
    > div:first-child {
        position: relative;
        p {
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
            color:  ${colors.$primaryDark};
        }
        p:first-child {
            font-weight: bold;
            color:  ${colors.$primary};
        }

        &:after {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            background: ${colors.$primary};
            border-radius: 50%;
            box-shadow: 0px 0px 4px 0px ${colors.$primary};
            z-index: 2;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    } 
`;

export const VerticalLine = styled.div`
    position: absolute;
    width: 2px;
    height: 120%;
    background-color: #DFE0E3;
    z-index: 1;
    left: -19px;
    top: 0;

    @media only screen and (min-width: 900px) { 
        left: -49px;
    }
`;

export const Card = styled.div`
    position: relative;
    padding: 12px;
    background: ${colors.$light};
    border-radius: 16px;
    border: #dcdcdc 1px solid;
    margin-left: 10px;
    max-width: 600px;
    width: 100%;

    > div:last-child {
        display: flex;
    }

    @media only screen and (min-width: 900px) { 
        margin-left: 40px;
    }

    .icon-div {
        margin-right: 8px;

        svg {
            height: 30px;
            width:39px
        }
    }
    h2 {
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        color: ${colors.$primaryDark};
        margin: 5px 0 0;
    }

    p {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        color: ${colors.$primaryDark};
        margin: 0;
    }
    .door-name {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 16px;
        color: ${colors.$greyDark};
    }
`;
