import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBox } from '../box-activation/boxConnection';
import WaitingModal from '../WaitingModal';

export default () => {
  const [doorName, setDoorName] = useState('Flat Door');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const saveSmartlock = () => {
    setModalOpen(true);
    setSaveButtonDisabled(true);
    getBox().then((b) => b.door.get(parseInt(window.localStorage.getItem('currentDoor'), 10)))
      .then((door) => door.save(doorName))
      .then(() => setModalOpen(false))
      .then(() => history.push({ pathname: '/box/activation/code' }))
      .catch((err) => {
        history.push({
          pathname: '/box/activation/error',
          state: {
            redirectUrl: '/box/activation/door/flat/save',
            errorMessage: String(err),
          },
        });
      });
  };

  return (
    <div className="activation-section" id="door-name">
      {modalOpen
        ? <WaitingModal />
        : null}
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.intercom.nameTitle')}</h1>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <form>
              <div className="row formName">
                <div className="col col-md-8 col-lg-8">
                  <label htmlFor="doorName"><strong>{t('boxActivation.intercom.formTitle')}</strong></label>
                  <input className="form-control" onChange={(e) => setDoorName(e.target.value)} id="doorSmartlockName" type="text" placeholder="Flat Door" defaultValue="Flat Door" />
                  <p className="formDescriptionTxt">{t('boxActivation.door.flatFormDescription')}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center">
            <div className="col col-md-6 col-lg-4" />
            <div className="col col-md-3 col-lg-2 text-center">
              <button type='submit' className="btn btn-block btn-outline-primary" disabled={saveButtonDisabled} id="smartlock-save" onClick={saveSmartlock}>{t('boxActivation.buttonNext')}</button>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              {/* <Link to='/box/activation/keypad' className="btn btn-link">
                {t("boxActivation.activationBack")}
              </Link> */}
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
