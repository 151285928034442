import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { guestInvitation } from '../../modules/guestInvitations';

import Button from '../ReusableComponents/Button';

import styles from './GuestDashboard.module.scss';

import { ReactComponent as Logo } from '../../assets/images/filled/logo.svg';

const languages = [
  'EN',
  'PT',
  'ES',
  'FR',
  'IT',
  'DE',
];

export default () => {
  const { stage } = useSelector((state) => state.guestInvitation);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = localStorage.getItem('i18nextLng') || 'en';

  return (
    <div className="row" style={{ height: '66px' }}>
      <div className="col-4">
        <Logo className={styles.logo} onClick={() => window.open('https://homeit.io')} />
      </div>
      {stage === 'dashboard' ? (
        <div className="ml-auto" style={{ marginRight: '4%' }}>
          <Button
            className="btn btn-secondary"
            value={t('guestInvitation.tutorial')}
            onClick={() => {
              cookies.remove('skipGuestTutorial');
              dispatch(guestInvitation.actions.setStage('welcome'));
            }}
          />
        </div>
      ) : (
        <div className="ml-auto" style={{ marginRight: '4%' }} />
      )}
      <div
        style={{ marginRight: '4%' }}
      >
        <select
          className={`form-control ${styles.languageSelect}`}
          defaultValue={language}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          {languages.map((l) => <option value={l.toLocaleLowerCase()}>{l}</option>)}
        </select>
      </div>
    </div>
  );
};
