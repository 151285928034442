import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from '../ReusableComponents/Card/Card';
import { updateSettings } from '../../modules/user';

import style from './Settings.module.scss';

import { ReactComponent as EditSettings } from '../../assets/images/lined/new.svg';

export class Settings extends React.Component {
  componentDidMount() {
    const { user } = this.props;
    if (user.languages && user.languages[0]) {
      this.changeLanguage(user.languages[0]);
    }
  }

  updateLanguage = (lng) => {
    const { user: { id }, updateSettings: update } = this.props;
    this.changeLanguage(lng);
    update({ languages: [lng] }, id);
  };

  changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  render() {
    const {
      user,
      t,
    } = this.props;

    return (
      <div className="medium-container">
        <div id="listContainer" className="container mb-5">
          <div className="row mb-5" id={style.keysHeader}>
            <div className="col">
              <h1>{t('settings')}</h1>
            </div>
          </div>
          <Card className={` ${style.header}`}>
            <h4 id="admin" className="font-weight-bold">
              {(user.name || {}).first ? `${user.name.first} ${user.name.last}` : user.name}
            </h4>
            {/* <div>{t('users.admin')}</div> */}
            {/* <div>
              {(user.organizationRole === 'admin' || !user.organizationRole) ? t('users.adminRole') : t('users.memberRole')}
            </div> */}
          </Card>

          <div className="mt-4">
            <Card className={`${style.profile} ${style.card}`}>
              <div className="row">
                <div className="col">
                  <label style={{ color: '#9DA1A9' }}>{t('name')}</label>
                  <div id="name">
                    {(user.name || {}).first ? `${user.name.first} ${user.name.last}` : user.name}
                  </div>
                </div>
                <div className="col-3 text-right">
                  <Link to="/edit-settings">
                    <EditSettings className={style.settingsEditIcon} />
                  </Link>
                </div>
              </div>
              <label className="mt-4" style={{ color: '#9DA1A9' }}>Email</label>
              <div id="email">{user.email.address}</div>
              <label className="mt-4" style={{ color: '#9DA1A9' }}>{t('phoneNumber')}</label>
              {/* <div>{countryToShort(user.phone.country)} {user.phone.number}</div> */}
              <div id="phone-number">{user.phone.number}</div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <h6 className="font-weight-bold mb-4">{t('setting.configurations')}</h6>
              <form>
                <div className="form-group">
                  <label>{t('setting.language')}</label>
                  <select
                    className="form-control"
                    defaultValue={user.languages ? user.languages[0] : localStorage.getItem('i18nextLng')}
                    onChange={(e) => this.updateLanguage(e.target.value)}
                  >
                    <option value="pt">Português</option>
                    <option value="en">English</option>
                    <option value="es">Español</option>
                    <option value="fr">Français</option>
                    <option value="it">Italiano</option>
                    <option value="de">Deutsch</option>
                  </select>
                </div>
                {/*
                <div className="row mt-4">
                  <div className="col">
                    <h6>{t('notifications')}</h6>
                  </div>
                  <div className="col" style={{ textAlign: 'right' }}>
                    <ToggleView
                      isToggleChecked={this.state.notifications}
                      toggleHandler={() => this.setState({ notifications: !this.state.notifications })}
                    />
                  </div>
                </div>
                */}
                {/*
                <div className="onesignal-customlink-container" />
                */}
              </form>
            </Card>
          </div>

        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  updateSettings: (data, id) => dispatch(updateSettings(data, id, props)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Settings));
