import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <div className="activation-section" id="intercom-result">
      <div className="container wrapper--page">
        <div className="row justify-content-md-center">
          <div className="col col-md-8 col-lg-6">
            <h1>{t('boxActivation.intercom.resultTitle')}</h1>
            <p className="description-txt">{t('boxActivation.intercom.resultDescription')}</p>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row justify-content-md-center" id="selector">
            <div className="col col-md-3 col-lg-2" />
            <div className="col col-md-3 col-lg-2 text-center">
              <Link to='/box/activation/lock/motorised/test' className="btn btn-block btn-primary">{ t('boxActivation.buttonNo')}</Link>
            </div>
            <div className="col col-md-3 col-lg-2 text-center">
              <Link to='/box/activation/lock/motorised/success' className="btn btn-block btn-primary">{t('boxActivation.buttonYes')}</Link>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col col-md-3 col-lg-2 text-left">
              <Link to='/box/activation/lock/motorised/test' className="btn btn-link" />
            </div>
            <div className="col col-md-6 col-lg-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
