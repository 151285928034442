import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { ensureAuth, getAuthToken } from '../utils/auth';

const initialState = {
  pending: false,
  success: false,
  invitationTokenData: null,
  cohosts: [],
};

export const hostInvitation = createSlice({
  name: 'hostInvitation',
  initialState,
  reducers: {
    apiPending: (state) => { state.pending = !state.pending; },
    apiSuccess: (state) => { state.success = !state.success; },
    POST: (state, action) => { state.cohosts = [...state.cohosts, action.payload]; },
    GET: (state, action) => { state.cohosts = action.payload; },
    PUT: (state, action) => {
      state.cohosts = state.cohosts.map((cohost) => {
        if (cohost.token === action.payload.token) cohost = action.payload;
        return cohost;
      });
    },
    DELETE: (state, action) => {
      state.cohosts = state.cohosts.filter((cohost) => (cohost.token !== action.payload.token));
    },
    setInvitationTokenData: (state, action) => { state.invitationTokenData = action.payload; },
  },
});

const cohostInvitationApi = axios.create({
  baseURL: 'https://components.homeit.io/invitations/cohost/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

// INBOUND COHOST INVITATION

export const validateInvitationToken = (token) => (dispatch) => ensureAuth()
  .then(() => cohostInvitationApi.post('validate', { token }))
  .then(({ data: { docs: [invitation] } }) => {
    dispatch(hostInvitation.actions.setInvitationTokenData(invitation));
    return invitation;
  });

export const processInvitationToken = (token) => async () => ensureAuth()
  .then(() => cohostInvitationApi.post('process', { token }));

// GET COHOSTS INVITATIONS
export const getCohostsInvitations = () => (dispatch) => ensureAuth()
  .then(() => {
    dispatch(hostInvitation.actions.apiPending(true));
    return axios.get('https://components.homeit.io/invitations/cohost/find', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  })
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    dispatch(hostInvitation.actions.GET(res.data.docs));
    return res.data.docs;
  });

// CREATE COHOST INVITATION
export const createCohostInvitation = (data) => (dispatch) => ensureAuth()
  .then(() => axios.post('https://components.homeit.io/invitations/cohost/create', {
    name: data.name,
    email: data.email,
    properties: data.enabledProperties,
    organizationRole: data.role,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }))
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    dispatch(hostInvitation.actions.POST(res.data.docs[0]));
    return res;
  });

// EDIT COHOST
export const editCohost = (data) => (dispatch) => ensureAuth()
  .then(() => axios.post(`https://components.homeit.io/invitations/cohost/edit?user=${data.id}`, {
    properties: data.enabledProperties,
    organizationRole: data.role,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }))
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    return res;
  });

export const updateCohostInvitation = (data) => (dispatch) => ensureAuth()
  .then(() => axios.post('https://components.homeit.io/invitations/cohost/update', {
    name: data.name,
    properties: data.enabledProperties,
    organizationRole: data.role,
    token: data.tokenInvitation,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }))
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    dispatch(hostInvitation.actions.PUT(res.data.docs[0]));
    return res;
  });

export const deleteCohost = (id) => (dispatch) => ensureAuth()
  .then(() => axios.delete(`https://components.homeit.io/invitations/cohost/edit?user=${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }))
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    return res;
  });

export const deleteInvitation = (token) => (dispatch) => ensureAuth()
  .then(() => axios.post('https://components.homeit.io/invitations/cohost/remove', { token }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }))
  .then((res) => {
    dispatch(hostInvitation.actions.apiPending(false));
    dispatch(hostInvitation.actions.apiSuccess(true));
    dispatch(hostInvitation.actions.DELETE(res.data.docs[0]));
    return res;
  });
