// colors
export const colors = {
  // Primary Colors
  $primaryDark: '#0A1428',
  $primary: '#00B7A0',
  $primaryLight: '#D8F1EC',

  // Secondary Colors
  $secondary: '#FF005F',
  $secondaryLight: '#FFD4DF',

  // Button Colors
  $success: '#007E6E',
  $info: '#007583',
  $error: '#DB0000',
  $warning: '#FFB733',

  // Neutral Colors
  $greyDark: '#6C727E',
  $grey: '#9DA1A9',
  $greyLight: '#CED0D4',

  $light: '#f7f7f7',
  $white: '#FFF',

  $bodyBg: '#f7f7f7',
};

// fonts
export const fonts = {
  $fontFamilyBase: 'Hind Madurai',
};
