import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../ReusableComponents/Button';
import Card from '../ReusableComponents/Card/Card';

import styles from './TutorialViewTemplate.module.scss';

class NavDots extends React.Component {
  render() {
    const { inputLength, selectedInput } = this.props;
    const carousel = [];
    for (let i = 0; i < inputLength; i += 1) {
      if (i === selectedInput) {
        carousel.push(<div key={i} className={`${styles.gridItem} ${styles.gridItemSelected}`} />);
      } else {
        carousel.push(<div key={i} className={`${styles.gridItem}`} />);
      }
    }
    return carousel;
  }
}

const TutorialViewTemplate = ({
  next,
  skip,
  gif,
  heading,
  subheading,
  selectedInput,
  propertyNames,
  inputLength,
  t,
}) => (
  <div className="container">
    <Card style={{
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      boxSizing: 'content-box',
      marginTop: '18px',
    }}
    >
      {/* CARDS */}
      <div id="instructionsText" style={{ marginTop: '20px' }}>
        <h2 className="text-center">{heading}</h2>
        <div className="text-center">{subheading}</div>
      </div>
      {gif && (
        <div style={{ alignSelf: 'center', margin: '18px' }}>
          <img style={{ maxWidth: '100%', maxHeight: '40vh' }} src={gif} alt="loading..." />
        </div>
      )}
      {propertyNames
        && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '20px',
        }}
        >
          {propertyNames.map((propertyName) => <h2 className="text-primary" key={propertyName}>{propertyName}</h2>)}
        </div>
        )}
    </Card>
    <div
      id="controlsSection"
    >
      <div>
        <div className={`${styles.grid}`}>
          <NavDots selectedInput={selectedInput} inputLength={inputLength} />
        </div>
      </div>
      <div style={{ position: 'relative', width: '120px', margin: 'auto' }}>
        <Button
          value={t('guestInvitation.next')}
          id="next-button"
          className={`${styles.nextButton} btn btn-secondary`}
          onClick={() => next()}
        />
      </div>
      <div style={{ position: 'relative', width: '120px', margin: 'auto' }}>
        <Button
          value={t('guestInvitation.skip')}
          id="skip-button"
          className={`${styles.skipButton}`}
          onClick={() => skip()}
        />
      </div>
    </div>
  </div>
);

export default withTranslation()(TutorialViewTemplate);
