import Swal from 'sweetalert2';

export const AlertConfirmCancel = Swal.mixin({
  showClass: {
    popup: '',
  },
  hideClass: {
    popup: '',
  },
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-light btn-w20',
    cancelButton: 'btn btn-cancel btn-w20',
  },
  showConfirmButton: true,
  showCancelButton: true,
  focusCancel: true,
});

export const AlertConfirm = Swal.mixin({
  showClass: {
    popup: '',
  },
  hideClass: {
    popup: '',
  },
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-light btn-w20',
  },
  showConfirmButton: true,
  // showCloseButton: true,
});

export const AlertLinkOptions = Swal.mixin({
  showClass: {
    popup: '',
  },
  hideClass: {
    popup: '',
  },
  buttonsStyling: false,
  reverseButtons: true,
  customClass: {
    confirmButton: 'btn btn-secondary btn-w20 btn-mr20',
    cancelButton: 'btn btn-cancel btn-w20',
  },
  showCancelButton: true,
  showCloseButton: true,
  focusCancel: true,
});
