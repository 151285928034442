import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  disabled, className, onClick, value,
}) => (
  <button
    disabled={disabled}
    className={className}
    onClick={onClick}
    id="btn-test"
  >
    {value}
  </button>
);

export default Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};
