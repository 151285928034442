import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../ReusableComponents/Button';
import { AlertConfirm } from '../../utils/alerts';
import PropertySelect from '../Keys/CreateEditKey/PropertySelect';

import style from './Main.module.scss';
// import { ReactComponent as DeleteIcon } from '../../assets/images/solid/bin.svg';

import {
  postCredentials,
  getListings,
  linkListing,
} from '../../modules/synchronisations';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    doors: { doors },
    synchronisations: {
      avantio: {
        credentials,
        listings,
      },
    },
  } = useSelector((state) => state);

  const [account, setAccount] = useState('');
  const [secret, setSecret] = useState('');

  const augmentListings = (listings) => [...(listings.map((l) => ({
    selectedProperties: [...new Set(l.doors
      .map((door) => (doors.find((d) => d.id === door) || {}).property)
      .filter((property) => !!property))],
    selectedDoors: [...l.doors],
    ...l,
  })))];

  const [augmentedListings, setAugmentedListings] = useState(augmentListings(listings));

  const hasCredentials = () => Object.keys(credentials).length > 0;

  const submitCredentials = async () => {
    try {
      const creds = await dispatch(postCredentials({ source: 'avantio', account, secret }));
      if (creds.id) {
        const newListings = await dispatch(getListings({ source: 'avantio' }));
        setAugmentedListings(augmentListings(newListings));
      }
    } catch (err) {
      return AlertConfirm.fire({
        icon: 'error',
        text: t('synchronisation.avantioCredentialsError'),
      });
    }
  };

  const propertySelectHandler = (newProperty, prevProperty, selectInput) => {
    const cloneListings = [...augmentedListings];
    const selectedListing = cloneListings.find((listing) => listing.id === selectInput);
    selectedListing.selectedProperties = [newProperty];
    selectedListing.selectedDoors = [];

    setAugmentedListings([
      ...cloneListings,
    ]);
  };

  const doorSelectHandler = async (addDoor, doorIds, selectInput) => {
    const cloneListings = [...augmentedListings];
    const selectedListing = cloneListings.find((listing) => listing.id === selectInput);
    selectedListing.selectedDoors = (addDoor
      ? [...selectedListing.selectedDoors, ...doorIds]
      : selectedListing.selectedDoors.filter((door) => !doorIds.includes(door)));

    await dispatch(linkListing({ source: 'avantio', id: selectedListing.id, doors: selectedListing.selectedDoors }));

    setAugmentedListings([
      ...cloneListings,
    ]);
  };

  return (
    <div id="listContainer" className="container mb-4">
      <div id={style.keysHeader}>
        <div>
          <h1>Avantio</h1>
          {listings.length > 0
            ? (
              <p className={style.synchronisation__content} style={{ fontWeight: '100' }}>
                {listings.length}
                {' '}
                {t('synchronisation.listingsToBeAssociated')}
              </p>
            ) : (
              <p className={style.synchronisation__content} style={{ fontWeight: '100' }}>
                {t('synchronisation.avantioCredentials')}
              </p>
            )}
        </div>
      </div>

      {/* USERNAME & PASSWORD */}
      {!hasCredentials() ? (
        <div className={`${style.card} mt-5`} style={{ padding: '24px' }}>
          <div className={`${style.formSection}`}>
            <div className="form-group">
              <label className={style.inputsLabel}>{t('username')}</label>
              <input type="text" className="form-control" defaultValue={account} onChange={(e) => setAccount(e.target.value)} />
            </div>
            <div className="form-group">
              <label className={style.inputsLabel}>{t('password')}</label>
              <input type="password" className="form-control" defaultValue={secret} onChange={(e) => setSecret(e.target.value)} />
            </div>
          </div>
          <div className={`${style.doubleButtonContainer}`}>
            <div className="row mt-5">
              <div className="col-6">
                <button type="button" onClick={() => history.push('/synchronisations')} className="btn btn-cancel btn-block">{t('cancel')}</button>
              </div>
              <div className="col-6">
                <Button
                  disabled={!account || !secret}
                  className="btn btn-secondary btn-block"
                  onClick={() => submitCredentials()}
                  value={t('synchronisation.next')}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {augmentedListings.map((listing) => (
            <>
              <div className={`${style.card} mb-3`} style={{ padding: '24px' }}>
                <div className="form-group">
                  <label className={style.inputsLabel}>
                    {t('synchronisation.listing')}
                    {' '}
                    (Avantio)
                  </label>
                  <input type="text" disabled className="form-control" defaultValue={listing.name} />
                </div>
                <div className="form-group mb-0" id={listing.id}>
                  <label className={style.inputsLabel}>
                    {t('synchronisation.property')}
                    {' '}
                    (Homeit)
                  </label>
                  <PropertySelect
                    propertyId={listing.selectedProperties[0]}
                    selectedProperties={listing.selectedProperties}
                    selectedDoors={listing.selectedDoors}
                    doorSelectHandler={(addDoor, doorIds) => doorSelectHandler(addDoor, doorIds, listing.id)}
                    propertySelectHandler={(newProperty, prevProperty) => propertySelectHandler(newProperty, prevProperty, listing.id)}
                  />
                </div>
              </div>
            </>
          ))}
        </>
      )}
    </div>
  );
};
