import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as luxon from 'luxon';

import { searchMatch } from '../../../utils/global';
import { ReactComponent as Icon } from '../../../assets/images/filled/guest2.svg';
import { ReactComponent as ServiceIcon } from '../../../assets/images/filled/manager.svg';
import { ReactComponent as ManagerIcon } from '../../../assets/images/filled/services.svg';
import {
  ActivitySection,
  TimeLine,
  VerticalLine,
  Card,
} from './styles';

// eslint-disable-next-line complexity
export const Activity = ({
  doors,
  properties,
  keys,
  door,
  source,
  guest,
  activityKey,
  lastChild,
  timestamp,
  search,
  users,
  t,
}: any) => {
  const getProperty = (id: any) => properties.find((prop: any) => prop.id === id);

  const getDoor = (id: any) => doors.find((dr: any) => dr.id === id);

  const getKey = (id: any) => keys.find((key: any) => key.id === id);

  const activityIconHandler = (tag: string[]) => {
    if (tag.includes('manager')) return (<ManagerIcon />);
    if (tag.includes('services')) return (<ServiceIcon />);
    return (<Icon />);
  };

  const propertyObj = getProperty(getDoor(door).property) || {};
  const DateWithTimezone = luxon.DateTime.fromISO(timestamp, { zone: propertyObj.timezone });
  const keyTags = (getKey(activityKey) || {}).tags || [];

  let userName = '';
  if (source === 'owner') {
    const owner = users.find((u: any) => u.id === guest);
    // eslint-disable-next-line no-nested-ternary
    userName = owner ? ((owner.name || {}).first ? `${owner.name.first} ${owner.name.last}` : owner.name) : 'Owner';
  } else userName = (getKey(activityKey) || {}).name || '-';

  const doorName = (getDoor(door) || {}).name || '-';
  const propertyName = propertyObj.name || getProperty(getDoor(door).property).name || '-';

  const visible = searchMatch(search, [
    userName,
    doorName,
    propertyName,
  ]);

  return (
    <ActivitySection
      visible={visible}
      className={visible ? 'isVisible' : ''}
    >
      <TimeLine>
        <div>
          <p>{DateWithTimezone.toFormat('HH:mm')}</p>
          <p>{DateWithTimezone.toFormat('DD z')}</p>
        </div>
      </TimeLine>
      <Card>
        <VerticalLine lastChild={lastChild} />
        <div>
          <div
            className="icon-div"
          >
            { activityIconHandler(keyTags) }
          </div>
          <div>
            <h2>
              {userName}
            </h2>
            <p id="property-name">{propertyName}</p>
            <p id="door-name" className="door-name">
              {doorName}
              {' '}
              {t('with')}
              {' '}
              {source === 'owner' ? 'app' : source}
            </p>
          </div>
        </div>
      </Card>
    </ActivitySection>
  );
};

const mapStateToProps = (state: any) => ({
  properties: state.properties.properties,
  doors: state.doors.doors,
  keys: state.keys.keys,
  users: state.user.users,
  search: state.activities.search,
});

export default withTranslation()(connect(mapStateToProps)(Activity));
