import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { editKey } from '../../modules/keys';
import Card from '../ReusableComponents/Card/Card';

import styles from './Booking.module.scss';

class Booking extends React.Component {
  state = {};

  componentDidMount() {
    document.body.style.backgroundColor = '#f7f7f7';
    this.getPropertyInfo();
    // this.getPropertyName();
  }

  getPropertyName = () => {
    const properties = [];
    if (this.props.doors) {
      this.props.doors.forEach((doorId) => {
        const doorInfo = this.props.getDoor(doorId);
        if (doorInfo && doorInfo.property) {
          if (!properties.includes(doorInfo.property)) {
            properties.push(doorInfo.property);
          }
        }
      });
      this.setState({ propertyName: this.props.getProperty(properties[0]).name });
    }
  };

  getPropertyInfo = () => {
    const propertyData = this.props.getProperty(this.props.property);
    this.setState({ property: propertyData });
  };

  render() {
    const {
      source,
      checkIn,
      checkOut,
      name,
    } = this.props;
    const { propertyName } = this.state;
    return (
      <div className={styles.card}>
        <Card>
          <div className={styles.header}>
            <div className="row">
              <div className="col-9">
                <div className="font-size-21 mb-1" style={{ fontWeight: '700' }}>{name && name.length > 14 ? `${name.slice(0, 14)}...` : name}</div>
                {propertyName && (
                <div>
                  {propertyName}
                </div>
                )}
                {' '}
                <div className="mb-1">
                  <span>
                    {' '}
                    {moment(checkIn).format('DD MMM')}
                  </span>
                  <span> - </span>
                  <span>
                    {' '}
                    {moment(checkOut).format('DD MMM')}
                  </span>
                </div>
                <div style={{ color: '#32beaa' }}>{source || 'iCal'}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getProperty: (id) => state.properties.properties.find((property) => property.id === id),
  getDoor: (id) => state.doors.doors.find((door) => door.id === id),
});

const mapDispatchToProps = (dispatch) => ({
  editKey: (keyData) => dispatch(editKey(keyData)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Booking));
