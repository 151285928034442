import { nanoid } from 'nanoid';
import Cookies from 'js-cookie';
import axios from 'axios';

const authTokenCookie = 'authToken';
const refreshTokenCookie = 'authTokenRefresh';

export const isUserAuthenticated = () => !!Cookies.get(authTokenCookie);

export const getRefreshToken = () => Cookies.get(refreshTokenCookie);

export const getAuthToken = () => Cookies.get(authTokenCookie);

export const redirectToLogin = () => {
  Cookies.remove(authTokenCookie);
  Cookies.remove(refreshTokenCookie);
  const url = new URL(`${process.env.REACT_APP_AUTH_BASE_URL}/oauth/login`);
  const query = new URLSearchParams();
  query.append('redirect_uri', window.location.href);
  query.append('client_id', process.env.REACT_APP_AUTH_CLIENT_ID || '');
  query.append('response_type', 'code');
  query.append('scope', 'host');
  query.append('state', nanoid(6));
  url.search = query.toString();
  window.location.href = url.href;
};

export const ensureAuth = () => {
  if (isUserAuthenticated()) return Promise.resolve();
  if (getRefreshToken()) {
    return axios.get('/refresh')
      .then(() => new Promise<void>((resolve) => setTimeout(() => resolve(), 500)));
  }
  return Promise.resolve(redirectToLogin());
};

export const getTokenFromServer = () => axios
  .get(`${process.env.PUBLIC_URL}/login${window.location.search}`)
  .then(() => {
    const url = new URL(window.location.href);
    url.search = '';
    window.location.href = url.href;
  })
  .catch(() => redirectToLogin());
