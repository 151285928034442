import { createSlice } from '@reduxjs/toolkit';

import API from '../utils/api';

const lowLevelThreshold = (obj) => {
  const minimumBatteryLevel = 30;
  const { type, batteryLevel } = obj;
  obj.lowBattery = type === 'ble' && batteryLevel <= minimumBatteryLevel && batteryLevel > 0;
  return obj;
};

const initialState = {
  pending: false,
  success: false,
  locks: [],
};

const locks = createSlice({
  name: 'locks',
  initialState,
  reducers: {
    apiPending: (state, action) => { state.pending = action.payload; },
    apiSuccess: (state, action) => { state.success = action.payload; },
    GET: (state, action) => {
      const newLocks = action.payload.map((lock) => lowLevelThreshold(lock));
      state.locks.push(...newLocks);
    },
  },
});

const getAllLocks = async (dispatch, skipValue = 0) => {
  try {
    const {
      docs,
      _links: { next },
      skip: s,
      limit: l,
    } = await API.get('locks', { limit: 100, skip: skipValue });
    dispatch(locks.actions.GET(docs));
    if (next) await getAllLocks(dispatch, s + l);
    return docs;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getLocks = () => async (dispatch) => {
  try {
    dispatch(locks.actions.apiPending());
    const res = await getAllLocks(dispatch);
    dispatch(locks.actions.apiPending());
    dispatch(locks.actions.apiSuccess());
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getLockById = (state, id) => state.locks.locks
  .find((lock) => lock.id === id);

export { locks };
