import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { Dispatch } from 'react';

import { ensureAuth, getAuthToken } from '../utils/auth';

// const services = [
//   'service-keys-unlimited',
//   'service-keys-multiple-properties',
//   'service-keys-recover-expired',
//   'service-keyevents-unlimited',
//   'service-integrations-ical',
//   'service-organization-add-users',
//   'service-api-access',
// ];

const initialState = {
  success: false,
  customer: {
    customer: {},
    services: [],
  },
};

export const billing = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setStatus: (state, action) => { state.success = action.payload; },
    setCustomer: (state, action) => { state.customer = action.payload; },
  },
});

export const getCustomer = () => (dispatch: Dispatch<any>) => ensureAuth()
  .then(() => axios.get('customer', {
    baseURL: 'https://components.homeit.io/billing/',
    headers: { Authorization: `Bearer ${getAuthToken()}` },
  }))
  .then((response) => response.data)
  .then((data) => {
    dispatch(billing.actions.setCustomer(data));
    dispatch(billing.actions.setStatus(true));
    return data;
  })
  .catch((e) => {
    dispatch(billing.actions.setStatus(false));
    return Promise.reject(e);
  });
