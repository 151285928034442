import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import KeyList from './List/List';
import { filterKeys } from '../../modules/keys';
import { getDoorById } from '../../modules/doors';
import { getPropertyById } from '../../modules/properties';

import style from './Main.module.scss';

import { ReactComponent as SearchIcon } from '../../assets/images/lined/search.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/lined/filter.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/lined/calendar.svg';

// eslint-disable-next-line complexity
export const Keys = ({
  resetSearchKeys, t, searchKeys, keys, getDoor, getProperty,
}) => {
  const defaultStateFilters = {
    dates: [],
    checkinBefore: null,
    checkinAfter: null,
    checkoutBefore: null,
    checkoutAfter: null,
    properties: [],
    keysTags: [],
    keysTypes: [],
  };
  const [keysToShow, setKeysToShow] = useState([...keys]);
  const [openFilters, setOpenFilters] = useState(false);
  const [activeTab, setActiveTab] = useState('properties');
  const [filtersState, setFiltersState] = useState(defaultStateFilters);
  const [filtersUsed, setFiltersUsed] = useState(defaultStateFilters);

  const keysTags = () => {
    const existingKeys = [...new Set(keys.map((item) => (item.tags ? item.tags[0] : 'guest')))];
    const options = [];
    existingKeys.forEach((value) => {
      switch (value) {
        case 'services':
          return options.push({
            label: t('key.services'),
            value: 'services',
          });
        case 'manager':
          return options.push({
            label: t('key.manager'),
            value: 'manager',
          });
        case 'guest':
          return options.push({
            label: t('key.guest'),
            value: 'guest',
          });
        case 'future':
          return options.push({
            label: t('key.future'),
            value: 'future',
          });
        default:
          return null;
      }
    });
    return options;
  };
  const keysTypes = () => {
    const existingKeys = [...new Set(keys.map((item) => item.durationType))];
    const options = [];
    existingKeys.forEach((value) => {
      switch (value) {
        case 'current':
          return options.push({
            label: t('key.current'),
            value: 'current',
          });
        case 'expired':
          return options.push({
            label: t('key.expired'),
            value: 'expired',
          });
        case 'permanent':
          return options.push({
            label: t('key.permanent'),
            value: 'permanent',
          });
        case 'future':
          return options.push({
            label: t('key.future'),
            value: 'future',
          });
        default:
          return null;
      }
    });
    return options;
  };
  const getProperties = () => {
    const options = [];
    keys.forEach((item) => {
      options.push({
        label: (getProperty((getDoor(item.doors[0]) || {}).property) || {}).name,
        value: (getProperty((getDoor(item.doors[0]) || {}).property) || {}).id,
      });
    });
    return [...new Map(options.map((item) => [item.value, item])).values()];
  };

  const keysTypesHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        keysTypes: filtersState.keysTypes.length > 0 ? [...filtersState.keysTypes, value] : [value],
      });
    } else {
      const keysTypesState = filtersState.keysTypes.filter((item) => item !== value);
      setFiltersState({
        ...filtersState,
        keysTypes: keysTypesState,
      });
    }
  };
  const keysTagsHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        keysTags: filtersState.keysTags.length > 0 ? [...filtersState.keysTags, value] : [value],
      });
    } else {
      const keysTagsState = filtersState.keysTags.filter((item) => item !== value);
      setFiltersState({
        ...filtersState,
        keysTags: keysTagsState,
      });
    }
  };
  const propertiesHandler = (add, value) => {
    if (add) {
      setFiltersState({
        ...filtersState,
        properties: filtersState.properties.length > 0 ? [...filtersState.properties, parseInt(value, 10)] : [parseInt(value, 10)],
      });
    } else {
      const propertiesState = filtersState.properties.filter((item) => item !== parseInt(value, 10));
      setFiltersState({
        ...filtersState,
        properties: propertiesState,
      });
    }
  };

  // eslint-disable-next-line complexity
  const datesHandler = (add, value) => {
    if (add) {
      switch (value) {
        case 'checkinBefore':
          return setFiltersState({
            ...filtersState,
            dates: filtersState.dates.length > 0 ? [...filtersState.dates, value] : [value],
            checkinBefore: moment(),
          });
        case 'checkinAfter':
          return setFiltersState({
            ...filtersState,
            dates: filtersState.dates.length > 0 ? [...filtersState.dates, value] : [value],
            checkinAfter: moment(),
          });
        case 'checkoutBefore':
          return setFiltersState({
            ...filtersState,
            dates: filtersState.dates.length > 0 ? [...filtersState.dates, value] : [value],
            checkoutBefore: moment(),
          });
        case 'checkoutAfter':
          return setFiltersState({
            ...filtersState,
            dates: filtersState.dates.length > 0 ? [...filtersState.dates, value] : [value],
            checkoutAfter: moment(),
          });
        default:
          return setFiltersState({
            ...filtersState,
          });
      }
    } else {
      const datesState = filtersState.dates.filter((item) => item !== value);
      switch (value) {
        case 'checkinBefore':
          return setFiltersState({
            ...filtersState,
            dates: datesState,
            checkinBefore: null,
          });
        case 'checkinAfter':
          return setFiltersState({
            ...filtersState,
            dates: datesState,
            checkinAfter: null,
          });
        case 'checkoutBefore':
          return setFiltersState({
            ...filtersState,
            dates: datesState,
            checkoutBefore: null,
          });
        case 'checkoutAfter':
          return setFiltersState({
            ...filtersState,
            dates: datesState,
            checkoutAfter: null,
          });
        default:
          return setFiltersState({
            ...filtersState,
          });
      }
    }
  };

  const openModalFilters = () => {
    setFiltersState({
      ...filtersUsed,
    });
    setOpenFilters(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    document.body.style.overflow = 'initial';
    setOpenFilters(false);
    setFiltersState({
      ...filtersUsed,
    });
  };

  const resetFilters = () => {
    setFiltersState({
      ...defaultStateFilters,
    });
    setFiltersUsed({
      ...defaultStateFilters,
    });
    setKeysToShow([...keys]);
    closeModal();
  };

  // eslint-disable-next-line complexity
  const applyFilters = (changeState) => {
    const filtersStateClone = changeState || filtersState;
    let result = [];

    if (changeState) {
      setFiltersState(changeState);
    }

    // if no date don't change = ignore date
    if (filtersStateClone.dates.length === 0) {
      // if keysTypes
      if (filtersStateClone.keysTypes.length > 0) {
        filtersStateClone.keysTypes.forEach((type) => {
          // if keysTags
          if (filtersStateClone.keysTags.length > 0) {
            filtersStateClone.keysTags.forEach((tag) => {
              result.push(...keys.filter((item) => item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest')));
            });
          } else {
            // only date
            result.push(...keys.filter((item) => item.durationType === type));
          }
        });
      } else if (filtersStateClone.keysTags.length > 0 && filtersStateClone.keysTypes.length === 0) {
        // if keysTags and keysTypes is empty
        filtersStateClone.keysTags.forEach((tag) => {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest')));
        });
      } else {
        // only date
        result.push(...keys);
      }
      // if keysTypes
    } else if (filtersStateClone.keysTypes.length > 0) {
      filtersStateClone.keysTypes.forEach((type) => {
        // if keysTags
        if (filtersStateClone.keysTags.length > 0) {
          filtersStateClone.keysTags.forEach((tag) => {
            if (filtersStateClone.checkinBefore !== null) {
              result.push(...keys.filter((item) => (item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest') && moment(item.checkIn).isSameOrBefore(filtersStateClone.checkinBefore))));
            }
            if (filtersStateClone.checkoutAfter !== null) {
              result.push(...keys.filter((item) => (item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest') && moment(item.checkOut).isSameOrAfter(filtersStateClone.checkoutAfter))));
            }
            if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore === null) {
              result.push(...keys.filter((item) => (item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest') && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
            }
            if (filtersStateClone.checkinAfter === null && filtersStateClone.checkoutBefore !== null) {
              result.push(...keys.filter((item) => (item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest') && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore))));
            }
            if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore !== null) {
              result.push(...keys.filter((item) => (item.durationType === type && (item.tags ? item.tags.includes(tag) : tag === 'guest') && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore) && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
            }
          });
        } else {
          // only date
          if (filtersStateClone.checkinBefore !== null) {
            result.push(...keys.filter((item) => (item.durationType === type && moment(item.checkIn).isSameOrBefore(filtersStateClone.checkinBefore))));
          }
          if (filtersStateClone.checkoutAfter !== null) {
            result.push(...keys.filter((item) => (item.durationType === type && moment(item.checkOut).isSameOrAfter(filtersStateClone.checkoutAfter))));
          }
          if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore === null) {
            result.push(...keys.filter((item) => (item.durationType === type && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
          }
          if (filtersStateClone.checkinAfter === null && filtersStateClone.checkoutBefore !== null) {
            result.push(...keys.filter((item) => (item.durationType === type && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore))));
          }
          if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore !== null) {
            result.push(...keys.filter((item) => (item.durationType === type && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore) && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
          }
        }
      });
    } else if (filtersStateClone.keysTags.length > 0 && filtersStateClone.keysTypes.length === 0) {
      // if keysTags and keysTypes is empty
      filtersStateClone.keysTags.forEach((tag) => {
        if (filtersStateClone.checkinBefore !== null) {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest' && moment(item.checkIn).isSameOrBefore(filtersStateClone.checkinBefore))));
        }
        if (filtersStateClone.checkoutAfter !== null) {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest' && moment(item.checkOut).isSameOrAfter(filtersStateClone.checkoutAfter))));
        }
        if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore === null) {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest' && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
        }
        if (filtersStateClone.checkinAfter === null && filtersStateClone.checkoutBefore !== null) {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest' && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore))));
        }
        if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore !== null) {
          result.push(...keys.filter((item) => (item.tags ? item.tags.includes(tag) : tag === 'guest' && moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore) && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter))));
        }
      });
    } else {
      // only date
      if (filtersStateClone.checkinBefore !== null) {
        result.push(...keys.filter((item) => moment(item.checkIn).isSameOrBefore(filtersStateClone.checkinBefore)));
      }
      if (filtersStateClone.checkoutAfter !== null) {
        result.push(...keys.filter((item) => moment(item.checkOut).isSameOrAfter(filtersStateClone.checkoutAfter)));
      }
      if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore === null) {
        result.push(...keys.filter((item) => moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter)));
      }
      if (filtersStateClone.checkinAfter === null && filtersStateClone.checkoutBefore !== null) {
        result.push(...keys.filter((item) => moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore)));
      }
      if (filtersStateClone.checkinAfter !== null && filtersStateClone.checkoutBefore !== null) {
        result.push(...keys.filter((item) => moment(item.checkOut).isSameOrBefore(filtersStateClone.checkoutBefore) && moment(item.checkIn).isSameOrAfter(filtersStateClone.checkinAfter)));
      }
    }

    // verify if property filter is appliedd
    if (filtersStateClone.properties.length > 0) {
      const resultProperties = [];
      result.forEach((item) => {
        if (filtersStateClone.properties.includes(getProperty(getDoor(item.doors[0]).property).id)) {
          resultProperties.push(item);
        }
      });
      result = resultProperties;
    }

    // apply filters to keys
    setKeysToShow(result);

    // closepopup
    setOpenFilters(false);

    // save filters userd
    setFiltersUsed(changeState || {
      ...filtersState,
    });
  };

  const removeFilter = (type, value) => {
    switch (type) {
      case 'keysTypes':
        return applyFilters({
          ...filtersUsed,
          keysTypes: filtersUsed.keysTypes.filter((item) => item !== value),
        });
      case 'keysTags':
        return applyFilters({
          ...filtersUsed,
          keysTags: filtersUsed.keysTags.filter((item) => item !== value),
        });
      case 'properties':
        return applyFilters({
          ...filtersUsed,
          properties: filtersUsed.properties.filter((item) => item !== value),
        });
      case 'checkinBefore':
        return applyFilters({
          ...filtersUsed,
          dates: filtersUsed.dates.filter((item) => item !== type),
          checkinBefore: null,
        });
      case 'checkinAfter':
        return applyFilters({
          ...filtersUsed,
          dates: filtersUsed.dates.filter((item) => item !== type),
          checkinAfter: null,
        });
      case 'checkoutBefore':
        return applyFilters({
          ...filtersUsed,
          dates: filtersUsed.dates.filter((item) => item !== type),
          checkoutBefore: null,
        });
      case 'checkoutAfter':
        return applyFilters({
          ...filtersUsed,
          dates: filtersUsed.dates.filter((item) => item !== type),
          checkoutAfter: null,
        });
      default:
        return null;
    }
  };

  const changeDateSelection = (type, date) => {
    switch (type) {
      case 'checkinBefore':
        return setFiltersState({
          ...filtersState,
          checkinBefore: date,
        });
      case 'checkinAfter':
        return setFiltersState({
          ...filtersState,
          checkinAfter: date,
        });
      case 'checkoutBefore':
        return setFiltersState({
          ...filtersState,
          checkoutBefore: date,
        });
      case 'checkoutAfter':
        return setFiltersState({
          ...filtersState,
          checkoutAfter: date,
        });
      default:
        return setFiltersState({
          ...filtersState,
        });
    }
  };

  useEffect(() => {
    resetSearchKeys();
    setFiltersState({
      ...filtersUsed,
    });
  }, []); // run only once

  return (
    <div id="listContainerBig" className="container">
      <div className="row" id={style.keysHeader}>
        <div className="col">
          <h1>{t('keys')}</h1>
          <p className="headerDescription">
            {(keys || []).length}
            {' '}
            {t('keys').toLowerCase()}
          </p>
        </div>

        <div className="col-3 align-self-center">
          <Link to="/create-key" className={style.btnCreateKey} />
        </div>
      </div>
      <form className="row d-flex justify-content-between mt-3">
        <div className="form-group has-feedback has-feedback-left col-10 col-md-6 col-sm-11">
          <SearchIcon className="search-icon" />
          <input
            type="text"
            className="form-control form-control-search input-lg"
            placeholder={t('search')}
            onChange={(e) => searchKeys(e.target.value)}
          />
        </div>
        <div className={`${style.btnFilters} col-2 col-md-2 col-sm-1`}>
          <button type="button" className="btn btn-light w-100" onClick={() => openModalFilters()}>{t('filter.filters')}</button>
          <button type="button" className="btn" onClick={() => openModalFilters()}>
            <FilterIcon />
          </button>
        </div>
      </form>
      {(filtersUsed.keysTags.length > 0
        || filtersUsed.keysTypes.length > 0
        || filtersUsed.properties.length > 0
        || filtersUsed.dates.length > 0) && (
        <div className="row">
          <ul className={`col-md-12 ${style.filtersApplied}`}>
            <>
              {filtersUsed.dates.map((item) => (
                <li className="btn btn-light" key={item}>
                  {t(item)}
                  :
                  {' '}
                  {moment(filtersUsed[item]).format('DD-MM-YYYY')}
                  <button type="button" onClick={() => removeFilter(item)}>&times;</button>
                </li>
              ))}
            </>
            {getProperties().map((item) => (
              <>
                {filtersUsed.properties.includes(item.value) && (
                  <li className="btn btn-light" key={item.value}>
                    {item.label}
                    <button type="button" onClick={() => removeFilter('properties', item.value)}>&times;</button>
                  </li>
                )}
              </>
            ))}
            {keysTags().map((item) => (
              <>
                {filtersUsed.keysTags.includes(item.value) && (
                  <li className="btn btn-light" key={item.value}>
                    {item.label}
                    <button type="button" onClick={() => removeFilter('keysTags', item.value)}>&times;</button>
                  </li>
                )}
              </>
            ))}
            {keysTypes().map((item) => (
              <>
                {filtersUsed.keysTypes.includes(item.value) && (
                  <li className="btn btn-light" key={item.value}>
                    {item.label}
                    <button type="button" onClick={() => removeFilter('keysTypes', item.value)}>&times;</button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      )}
      <Popup open={openFilters} closeOnDocumentClick modal nested onClose={() => closeModal()} className="no-overflow">
        <div>
          <header>
            <h3>{t('filter.filters')}</h3>
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}
              onKeyDown={() => closeModal()}
            >
              &times;
            </button>
          </header>
          <div>
            <ul className={style.filtersHeader}>
              <li
                className={activeTab === 'properties' ? style.filtersHeaderListActive : ''}
                onClick={() => setActiveTab('properties')}
                id="properties"
                onKeyDown={() => setActiveTab('properties')}
              >
                {t('filter.properties')}
              </li>
              <li
                className={activeTab === 'date' ? style.filtersHeaderListActive : ''}
                onClick={() => setActiveTab('date')}
                id="date"
                onKeyDown={() => setActiveTab('date')}
              >
                {t('filter.date')}
              </li>
              <li
                className={activeTab === 'key' ? style.filtersHeaderListActive : ''}
                onClick={() => setActiveTab('key')}
                onKeyDown={() => setActiveTab('key')}
                id="keys"
              >
                {t('filter.key')}
              </li>
            </ul>
            {activeTab === 'properties' && (
            <div className="selection-container">
              {getProperties().map((item) => (
                <div key={item.value} className="mt-4 checkbox-grid">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      value={item.value}
                      checked={filtersState.properties.includes(item.value)}
                      onChange={(e) => propertiesHandler(e.target.checked, e.target.value)}
                    />
                    <span className="checkbox-custom" />
                  </label>
                  <p className="checkbox-text">{item.label}</p>
                </div>
              ))}
            </div>
            )}
            {activeTab === 'date' && (
              <div className="selection-container data-selection">
                <div className="align-items-center mt-4 d-flex flex-wrap">
                  <div className="checkbox-grid col-lg-4 col-md-6 col-sm-12 no-padding">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value='checkinBefore'
                        checked={filtersState.dates.includes('checkinBefore')}
                        onChange={(e) => datesHandler(e.target.checked, 'checkinBefore')}
                        disabled={filtersState.dates.includes('checkinAfter') || filtersState.dates.includes('checkoutAfter') || filtersState.dates.includes('checkoutBefore')}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{t('checkinBefore')}</p>
                  </div>
                  {filtersState.dates.includes('checkinBefore') && (
                    <DatePicker
                      className="col-lg-4 col-md-6 col-sm-12"
                      onChange={(date) => changeDateSelection('checkinBefore', date)}
                      value={filtersState.checkinBefore ? [new Date(filtersState.checkinBefore)] : null}
                      minDate={new Date(2016, 0, 1)}
                      clearIcon={null}
                      format="dd-MM-yyyy"
                      calendarIcon={<CalendarIcon />}
                    />
                  )}
                </div>
                <div className="align-items-center mt-4 d-flex flex-wrap">
                  <div className="checkbox-grid col-lg-4 col-md-6 col-sm-12 no-padding">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value='checkinAfter'
                        checked={filtersState.dates.includes('checkinAfter')}
                        onChange={(e) => datesHandler(e.target.checked, 'checkinAfter')}
                        disabled={filtersState.dates.includes('checkinBefore') || filtersState.dates.includes('checkoutAfter')}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{t('checkinAfter')}</p>
                  </div>
                  {filtersState.dates.includes('checkinAfter') && (
                    <DatePicker
                      className="col-lg-4 col-md-6 col-sm-12"
                      onChange={(date) => changeDateSelection('checkinAfter', date)}
                      value={filtersState.checkinAfter ? [new Date(filtersState.checkinAfter)] : null}
                      minDate={new Date(2016, 0, 1)}
                      clearIcon={null}
                      calendarIcon={<CalendarIcon />}
                      format="dd-MM-yyyy"
                    />
                  )}
                </div>
                <div className="align-items-center mt-4 d-flex flex-wrap">
                  <div className="checkbox-grid col-lg-4 col-md-6 col-sm-12 no-padding">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value='checkoutBefore'
                        checked={filtersState.dates.includes('checkoutBefore')}
                        onChange={(e) => datesHandler(e.target.checked, 'checkoutBefore')}
                        disabled={filtersState.dates.includes('checkoutAfter') || filtersState.dates.includes('checkinBefore')}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{t('checkoutBefore')}</p>
                  </div>
                  {filtersState.dates.includes('checkoutBefore') && (
                    <DatePicker
                      className="col-lg-4 col-md-6 col-sm-12"
                      onChange={(date) => changeDateSelection('checkoutBefore', date)}
                      value={filtersState.checkoutBefore ? [new Date(filtersState.checkoutBefore)] : null}
                      minDate={new Date(2016, 0, 1)}
                      clearIcon={null}
                      calendarIcon={<CalendarIcon />}
                      format="dd-MM-yyyy"
                    />
                  )}
                </div>
                <div className="align-items-center mt-4 d-flex flex-wrap">
                  <div className="checkbox-grid col-lg-4 col-md-6 col-sm-12 no-padding">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value='checkoutAfter'
                        checked={filtersState.dates.includes('checkoutAfter')}
                        onChange={(e) => datesHandler(e.target.checked, 'checkoutAfter')}
                        disabled={filtersState.dates.includes('checkoutBefore') || filtersState.dates.includes('checkinBefore') || filtersState.dates.includes('checkinAfter')}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{t('checkoutAfter')}</p>
                  </div>
                  {filtersState.dates.includes('checkoutAfter') && (
                    <DatePicker
                      className="col-lg-4 col-md-6 col-sm-12"
                      onChange={(date) => changeDateSelection('checkoutAfter', date)}
                      value={filtersState.checkoutAfter ? [new Date(filtersState.checkoutAfter)] : null}
                      minDate={new Date(2016, 0, 1)}
                      clearIcon={null}
                      calendarIcon={<CalendarIcon />}
                      format="dd-MM-yyyy"
                    />
                  )}
                </div>
              </div>
            )}
            {activeTab === 'key' && (
              <div className="selection-container">
                {keysTags().length > 0 && (
                  <p style={{ color: '#FF005F', fontSize: '14px' }}>{t('key.keyType')}</p>
                )}
                {keysTags().map((item) => (
                  <div key={item.value} className="mt-4 checkbox-grid">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value={item.value}
                        checked={filtersState.keysTags.includes(item.value)}
                        onChange={(e) => keysTagsHandler(e.target.checked, e.target.value)}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{item.label}</p>
                  </div>
                ))}
                <br />
                {keysTypes().length > 0 && (
                  <p style={{ color: '#FF005F', fontSize: '14px' }}>{t('key.duration')}</p>
                )}
                {keysTypes().map((item) => (
                  <div key={item.value} className="mt-4 checkbox-grid">
                    <label className="checkbox-label">
                      <input
                        value={item.value}
                        type="checkbox"
                        checked={filtersState.keysTypes.includes(item.value)}
                        onChange={(e) => keysTypesHandler(e.target.checked, e.target.value)}
                      />
                      <span className="checkbox-custom" />
                    </label>
                    <p className="checkbox-text">{item.label}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex align-items-center justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-cancel btn-block bw-40 mr-2"
                onClick={() => resetFilters()}
              >
                {t('filter.clean')}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-block bw-40 mt-0"
                onClick={() => applyFilters()}
              >
                {t('filter.filter')}
              </button>
            </div>
          </div>
        </div>
      </Popup>
      <KeyList keys={keysToShow} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  keys: state.keys.keys,
  getDoor: (id) => getDoorById(state, id),
  getProperty: (id) => getPropertyById(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  resetSearchKeys: () => dispatch(filterKeys('')),
  searchKeys: (filter) => dispatch(filterKeys(filter)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Keys));
