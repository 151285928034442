import React from 'react';
import { Route } from 'react-router-dom';

import BoxNew from './BoxNew';
import BoxPropertyStart from './BoxPropertyStart';
import BoxPropertyAssociate from './BoxPropertyAssociate';
import ConnectionError from './ConnectionError';
import CreateEditProperty from '../Properties/CreateEditProperty';

const BoxActivation = () => (
  <>
    <Route exact path="/box/new" component={BoxNew} />
    <Route exact path="/box/new/property" component={BoxPropertyStart} />
    <Route exact path="/box/new/associate-property" component={BoxPropertyAssociate} />
    <Route
      exact
      path="/box/new/new-property"
      render={() => (
        <CreateEditProperty
          boxActivationAddProperty
        />
      )}
    />
    <Route exact path="/box/new/activation/error" component={ConnectionError} />
  </>
);

export default BoxActivation;
