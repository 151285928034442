import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { keys } from './modules/keys';
import { doors } from './modules/doors';
import { properties } from './modules/properties';
import { user } from './modules/user';
import { billing } from './modules/billing';
import { boxes } from './modules/boxes';
import { keypads } from './modules/keypads';
import { locks } from './modules/locks';
import { synchronisations } from './modules/synchronisations';
import { guestInvitation } from './modules/guestInvitations';
import { hostInvitation } from './modules/hostInvitation';
import { activities } from './modules/activities';

const preloadedState = {};

const reducer = combineReducers({
  keys: keys.reducer,
  synchronisations: synchronisations.reducer,
  doors: doors.reducer,
  properties: properties.reducer,
  activities: activities.reducer,
  user: user.reducer,
  billing: billing.reducer,
  boxes: boxes.reducer,
  keypads: keypads.reducer,
  locks: locks.reducer,
  guestInvitation: guestInvitation.reducer,
  hostInvitation: hostInvitation.reducer,
});

const middleware = [...getDefaultMiddleware()];

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});
