import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import HelpResponsive from './HelpResponsive';
import ProfileResponive from './ProfileResponsive';
import Button from '../ReusableComponents/Button';

import style from './NavbarDesktop.module.scss';

import { ReactComponent as Logo } from '../../assets/images/filled/logo.svg';
import { ReactComponent as HelpLined } from '../../assets/images/lined/help.svg';
import { ReactComponent as HelpSolid } from '../../assets/images/solid/help.svg';
// import { ReactComponent as Bell } from '../../assets/images/lined/bell.svg';
import { ReactComponent as User } from '../../assets/images/lined/user.svg';

export class NavbarDesktop extends React.Component {
  state = {
    isHelpMenuDisplayed: false,
    isProfileMenuDisplayed: false,
  };

  //---------------------------------------------------------

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isHelpMenuDisplayed: false,
        isProfileMenuDisplayed: false,
      });
    }
  }

  //---------------------------------------------------------

  render() {
    const { t } = this.props;
    return (
      <div ref={this.setWrapperRef}>
        <div className={style.desktopNavbar} id="listContainer" style={{ paddingTop: '0px' }}>
          <div className="row" style={{ height: '66px' }}>
            <div className="col-8">
              <span id="home-link" onClick={() => this.props.history.push('/')}>
                <Logo className={style.logo} />
              </span>
              <div style={{ display: 'inline-block' }}>
                <NavLink activeClassName="selected-link" className={style.link} id="properties" to="/properties">{t('properties')}</NavLink>
                <NavLink activeClassName="selected-link" className={style.link} id="keys" to="/keys">{t('keys')}</NavLink>
                <NavLink activeClassName="selected-link" className={style.link} id="activities" to="/activities">{t('activities')}</NavLink>
                {/* <NavLink className={style.link} id='bookings' to="/bookings">{t('bookings')}</NavLink> */}
                <NavLink activeClassName="selected-link" className={style.link} id="synchronisations" to="/synchronisations">{t('synchronisations')}</NavLink>
              </div>
            </div>
            <div className="col-4 d-flex flex-row-reverse">
              {this.state.isProfileMenuDisplayed
                ? (
                  <div style={{ position: 'relative' }}>
                    <User
                      id="profile-menu-opened"
                      className={style.smallIcon}
                      onClick={() => this.setState({
                        isProfileMenuDisplayed: false,
                      })}
                    />
                    <ProfileResponive
                      closeParent={() => this.setState({
                        isProfileMenuDisplayed: false,
                      })}
                    />
                  </div>
                )
                : (
                  <User
                    id="profile-menu-closed"
                    className={style.smallIcon}
                    onClick={() => this.setState({
                      isProfileMenuDisplayed: true,
                      isHelpMenuDisplayed: false,
                    })}
                  />
                )}
              {/*
              <Bell className={style.smallIcon} />
              */}
              {this.state.isHelpMenuDisplayed
                ? (
                  <div style={{ position: 'relative' }} id="help-menu">
                    <HelpSolid id="help-solid" className={style.smallIcon} onClick={() => this.setState({ isHelpMenuDisplayed: false })} />
                    <HelpResponsive />
                  </div>
                )
                : (
                  <HelpLined
                    id="help-lined"
                    className={style.smallIcon}
                    style={{ height: '25px', marginTop: '21px' }}
                    onClick={() => this.setState({
                      isHelpMenuDisplayed: true,
                      isProfileMenuDisplayed: false,
                    })}
                  />
                )}
              <Link className={`${style.desktopNavbarButton}`} id="billing" to="/billing">
                <Button className="btn btn-light" value={t('updatePlan')} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(NavbarDesktop));
