import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import styles from '../Property.module.scss';

import { getDoorById } from '../../../../modules/doors';

export const DoorMini = ({
  id,
  history,
  getDoor,
}) => {
  const { name } = getDoor();
  return (
    <>
      <div
        // className={`${styles.cardMini} row`}
        className="row"
        style={{ marginLeft: '0px', marginRight: '0px' }}
        id="property-link"
        onClick={() => history.push(`/door/edit/${id}`)}
      >
        <div className="property-header col-10" style={{ paddingRight: '0px' }}>
          <h6 style={{ fontWeight: '700' }} id="property-name">{ name }</h6>
        </div>
        <div className="col-2">
          <Link
            style={{ height: '100%', marginRight: '0px', marginLeft: '0px' }}
            to={{ pathname: `/door/edit/${id}`, params: { id } }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  getDoor: () => getDoorById(state, props.id),
});

const mapDispatchToProps = () => ({
});

export default withTranslation()(withRouter(connect(mapStateToProps,
  mapDispatchToProps)(DoorMini)));
