import React from 'react';
import { withTranslation } from 'react-i18next';

import { ReactComponent as EmailHelp } from '../../assets/images/filled/mail.svg';
// import { ReactComponent as PhoneHelp } from '../../assets/images/filled/phone.svg';

import Card from '../ReusableComponents/Card/Card';

export const Help = ({ t }) => (
  <div id="listContainer" className="container">
    <div className="row">
      <div className="col">
        <h1>{t('help.help')}</h1>
        <h6 style={{ marginBottom: '30px', color: '#fff', lineHeight: '24px' }}>{t('help.anyQuestions')}</h6>
      </div>
    </div>
    <Card>
      <div className="row">
        <EmailHelp style={{ height: '46px' }} className="col" alt="email help" />
        <div className="col-9" style={{ paddingLeft: '0px', paddingTop: '10px' }}>
          <p style={{
            color: 'black', margin: '2px', fontSize: '24px', fontWeight: '600', paddingBottom: '8px',
          }}
          >
            support@homeit.io
          </p>
          <p style={{ color: '#9DA1A9', fontSize: '15px' }}>{t('help.workingHours')}</p>
        </div>
      </div>
      {/* <div className="row">
        <PhoneHelp style={{ height: '46px' }}  className="col" alt="phone help" />
        <div className="col-9" style={{ fontWeight: '600', fontSize: '24px', paddingLeft: '0px' }}>
            <p className="black" style={{ lineHeight: '50px', fontSize: '24px' }}>+351 234 567 891</p>
        </div>
      </div> */}
    </Card>
    {/* <h5 className={`${style.bold} ${style.help__subtitle}`}>{t('help.checkKnowledgeBase')}</h5>
    <h6 style={{ color: '#9DA1A9', lineHeight: '24px' }}>{t('help.faqPage')}</h6>
    <div className={`row ${style.marginTop30}`}>
      <div className="col-12">
        <button
          className="btn btn-primary btn-light"
        >
          {t('help.goToHomeitSupport')}
        </button>
      </div>
    </div> */}
  </div>
);

export default withTranslation()(Help);
